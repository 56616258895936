<template>
  <v-app>
    <div id="app">
      <NavBarTop v-if="permissoesLoaded && !hideMenu" />
      <NavBarLeft v-if="permissoesLoaded && !hideMenu" />
      <LoadingPage v-if="isLoadingPage" />
      <Content :hide-menu="hideMenu" />
    </div>
    <toast-container :toastConfig="toastConfig"></toast-container>
  </v-app>
</template>

<script>
import { MENU_LOAD_ITEMS } from './store/actions/menu'
import { injectScript } from '../src/utils/injectSript'
import LoadingPage from '@/components/LoadingPage'
import NavBarTop from '@/components/NavBarTop'
import NavBarLeft from '@/components/NavBarLeft'
import Content from '@/components/Content'
import { mapGetters } from 'vuex'
import * as _ from 'lodash'

export default {
  name: 'App',
  components: { NavBarTop, NavBarLeft, Content, LoadingPage },
  data: () => ({
    toastConfig: {
      toastContainerId: 1,
      animation: 'ease-out-right',
      preventDuplicates: true,
      showCloseButton: true
    }
  }),
  computed: {
    ...mapGetters([
      'isAuthenticated',
      'darkEnabled',
      'isLoadingPage',
      'permissoesLoaded'
    ]),
    hideMenu() {
      return !this.isAuthenticated || _.get(this.$route, 'meta.hidemenu', false)
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.$store.dispatch(MENU_LOAD_ITEMS)
      this.injectScripts()
    }
  },
  methods: {
    injectScripts() {
      injectScript(
        'https://static.zdassets.com/ekr/snippet.js?key=ffb1d844-f576-4436-be88-a0211dc1f1b9',
        'ze-snippet'
      )
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/css/global.scss';
@import '@/assets/css/toast.scss';
@import '@/assets/css/sweet-alert.scss';
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.slide-popup-enter-active,
.slide-popup-leave-active,
.slide-pages-enter-active,
.slide-pages-leave-active {
  transition-duration: 0.4s;
  transition-property: height, opacity, transform;
  transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
}

.slide-popup-enter {
  opacity: 0;
  transform: translate(2em, 0);
}
.slide-popup-leave-active {
  opacity: 0;
  transform: translate(2em, 0);
}
.slide-pages-enter {
  opacity: 0;
}
.slide-pages-leave-active {
  opacity: 0;
}
</style>
