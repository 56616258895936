import {
  GATEWAY_CRETE,
  GATEWAY_LOAD,
  GATEWAY_REMOVE,
  GATEWAY_UPDATE,
  GATEWAY_CONFIG,
  GATEWAY_LIST
} from '../actions/gateway'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'
import * as _ from 'lodash'

const state = {
  opcoes_gateways: [],
  opcoes_antifraude: [],
  gateways_list: []
}

const getters = {
  getGatewaysOpcoes: state => state.opcoes_gateways,
  getAntifraudeOpcoes: state => state.opcoes_antifraude,
  getGatewaysList: state => state.gateways_list
}

const actions = {
  [GATEWAY_CONFIG]: ({ dispatch, commit }) => {
    return new Promise((resolve, reject) => {
      Api.v4.gateway
        .config()
        .then(resp => {
          commit(GATEWAY_CONFIG, resp.data)
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [GATEWAY_CRETE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gateway
        .saveGateway({ ...payload, idsupplier: getters.getFornecedorId }, true)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [GATEWAY_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gateway
        .saveGateway({ ...payload, idsupplier: getters.getFornecedorId }, false)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [GATEWAY_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gateway
        .deleteGateway(getters.getFornecedorId, payload)
        .then(() => {
          resolve()
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [GATEWAY_LOAD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gateway
        .getById(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [GATEWAY_LIST]: ({ dispatch, getters, commit }) => {
    return new Promise((resolve, reject) => {
      Api.v4.gateway
        .getGateways(getters.getFornecedorId)
        .then(resp => {
          commit(GATEWAY_LIST, resp.data)
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {
  [GATEWAY_CONFIG]: (state, gateways) => {
    state.opcoes_gateways = _.get(gateways, 'gateway', []) || []
    state.opcoes_antifraude = _.get(gateways, 'antifraude', []) || []
  },

  [GATEWAY_LIST]: (state, gateways) => {
    state.gateways_list = gateways || []
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
