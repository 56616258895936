import {
  LINK_ACAO_CREATE,
  LINK_ACAO_REMOVE,
  LINK_ACAO_UPDATE,
  LINK_ACAO_LOAD
} from '../actions/link-acao'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'
const state = {}

const getters = {}
const actions = {
  [LINK_ACAO_LOAD]: ({ dispatch, getters }, idlinkacao) => {
    return new Promise((resolve, reject) => {
      Api.v4.linkAcao
        .busca(getters.getFornecedorId, idlinkacao)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [LINK_ACAO_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.linkAcao
        .create(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [LINK_ACAO_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.linkAcao
        .remve(getters.getFornecedorId, payload.idlinkacao, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [LINK_ACAO_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.linkAcao
        .update(getters.getFornecedorId, payload.idlinkacao, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}
const mutations = {}

export default { state, getters, actions, mutations }
