import {
  PAGAMENTO_ESTORNO,
  PAGAMENTO_DETALHE,
  PAGAMENTO_ESTORNO_FEITOS
} from '../actions/pagamento'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [PAGAMENTO_ESTORNO]: ({ getters, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.payment
        .refundManual(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PAGAMENTO_DETALHE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.payment
        .getDetails(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PAGAMENTO_ESTORNO_FEITOS]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.payment
        .getrefunds(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
