<template>
  <v-card
    class="mx-auto card-default"
    :width="width"
    :raised="shadown ? true : false"
    style="border-radius: 8px"
    :loading="loading"
    :disabled="loading"
    :dark="darkEnabled"
  >
    <slot name="content-full"></slot>
    <div class="content-card">
      <slot></slot>
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Card',
  props: {
    width: {
      type: String,
      default: '100%',
      required: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    },
    shadown: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters(['darkEnabled'])
  }
}
</script>

<style lang="scss" scoped>
.card-default {
  border-radius: 8px;
  .content-card {
    padding: 15px;
  }
}
</style>
