import {
  PAGINA_ESTATICA_CREATE,
  PAGINA_ESTATICA_UPDATE,
  PAGINA_ESTATICA_LOAD,
  PAGINA_ESTATICA_REMOVE,
  PAGINA_ESTATICA_LIST
} from '../actions/paginaEstatica'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [PAGINA_ESTATICA_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.paginaestatica
        .adicionar(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PAGINA_ESTATICA_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.paginaestatica
        .atualizar(getters.getFornecedorId, payload.idconteudoestatico, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PAGINA_ESTATICA_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.paginaestatica
        .remover(getters.getFornecedorId, payload)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PAGINA_ESTATICA_LOAD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.paginaestatica
        .obterPorId(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PAGINA_ESTATICA_LIST]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.paginaestatica
        .listar(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
