export const PARAMETRO_LOAD_TAGS = 'PARAMETRO_LOAD_TAGS'
export const PARAMETRO_LOAD = 'PARAMETRO_LOAD'
export const PARAMETRO_LOAD_DELIVERY_AREA = 'PARAMETRO_LOAD_DELIVERY_AREA'
export const PARAMETRO_DOMAIN_VALIDATE = 'PARAMETRO_DOMAIN_VALIDATE'
export const PARAMETRO_UPDATE_AGENDA = 'PARAMETRO_UPDATE_AGENDA'
export const PARAMETRO_UPDATE_DATA = 'PARAMETRO_UPDATE_DATA'
export const ORDENACAO_PESQUISA = {
  'Nenhuma Selecionada': 'Nenhuma Selecionada',
  'Ordenar Relevância na pesquisa': 'Relevância na pesquisa',
  'Ordenar Mais vendidos': 'Mais vendidos',
  'Ordenar A - Z': 'A - Z',
  'Ordenar Z - A': 'Z - A'
}
export const FUSO_HORARIO = [
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco'
]
export const ORDER_STATUS_LABEL = {
  ORCAMENTO_NOVO: 'Em análise',
  ORCAMENTO_PRECIFICADO: 'Orçado',
  ENVIADO: 'Pedido recebido',
  SEPARANDO: 'Preparando seu pedido',
  AGUARDANDOPAGAMENTO: 'Aguardando pagamento',
  PAGO: 'Pedido pronto para entrega',
  PRONTO_RETIRADA: 'Pronto para retirada',
  EMENTREGA: 'Pedido saiu para entrega',
  ENTREGUE: 'Pedido entregue',
  CANCELADO: 'CANCELADO'
}
export const ORDER_STATUS = {
  ORCAMENTO_NOVO: 'ORCAMENTO_NOVO',
  ORCAMENTO_PRECIFICADO: 'ORCAMENTO_PRECIFICADO',
  ENVIADO: 'ENVIADO',
  SEPARANDO: 'SEPARANDO',
  PAGO: 'PAGO',
  EMENTREGA: 'EMENTREGA',
  ENTREGUE: 'ENTREGUE',
  CANCELADO: 'CANCELADO',
  PRONTO_RETIRADA: 'PRONTO_RETIRADA',
  AGUARDANDOPAGAMENTO: 'AGUARDANDOPAGAMENTO'
}
export const FORMAS_ARREDONDAMENTO = [
  {
    text: 'Arredondar para baixo utilizando dígito "5" para a decisão',
    value: 'ROUND_HALF_DOWN'
  },
  {
    text: 'Arredondar para cima utilizando dígito "5" para a decisão',
    value: 'ROUND_HALF_UP'
  },
  {
    text: 'Arredondar sempre para baixo',
    value: 'ROUND_DOWN'
  },
  {
    text: 'Arredondar sempre para cima',
    value: 'ROUND_UP'
  },
  {
    text: 'Não arredondar',
    value: 'ROUND_FLOOR'
  }
]
export const DECIMAIS = [
  { text: 1, value: '1' },
  { text: 2, value: '2' },
  { text: 3, value: '3' },
  { text: 4, value: '4' },
  { text: 5, value: '5' },
  { text: 6, value: '6' }
]
export const SEMANAS = [
  { day: 'Domingo', iHour: '', fHour: '', active: false },
  { day: 'Segunda-feira', iHour: '', fHour: '', active: false },
  { day: 'Terça-feira', iHour: '', fHour: '', active: false },
  { day: 'Quarta-feira', iHour: '', fHour: '', active: false },
  { day: 'Quinta-feira', iHour: '', fHour: '', active: false },
  { day: 'Sexta-feira', iHour: '', fHour: '', active: false },
  { day: 'Sábado', iHour: '', fHour: '', active: false }
]
export const ELEMENTOS_OBRIGATORIOS = [
  { text: 'Cidade', value: ['cidade'] },
  { text: 'Cidade e bairro', value: ['cidade', 'bairro'] },
  { text: 'Cidade, bairro e rua', value: ['cidade', 'bairro', 'rua'] }
]
export const ESTADOS = [
  { text: 'Acre', value: 'AC' },
  { text: 'Alagoas', value: 'AL' },
  { text: 'Amapá', value: 'AP' },
  { text: 'Amazonas', value: 'AM' },
  { text: 'Bahia', value: 'BA' },
  { text: 'Ceará', value: 'CE' },
  { text: 'Distrito Federal', value: 'DF' },
  { text: 'Espírito Santo', value: 'ES' },
  { text: 'Goiás', value: 'GO' },
  { text: 'Maranhão', value: 'MA' },
  { text: 'Mato Grosso', value: 'MT' },
  { text: 'Mato Grosso do Sul', value: 'MS' },
  { text: 'Minas Gerais', value: 'MG' },
  { text: 'Pará', value: 'PA' },
  { text: 'Paraíba', value: 'PB' },
  { text: 'Paraná', value: 'PR' },
  { text: 'Pernambuco', value: 'PE' },
  { text: 'Piauí', value: 'PI' },
  { text: 'Rio de Janeiro', value: 'RJ' },
  { text: 'Rio Grande do Norte', value: 'RN' },
  { text: 'Rio Grande do Sul', value: 'RS' },
  { text: 'Rondônia', value: 'RO' },
  { text: 'Roraima', value: 'RR' },
  { text: 'Santa Catarina', value: 'SC' },
  { text: 'São Paulo', value: 'SP' },
  { text: 'Sergipe', value: 'SE' },
  { text: 'Tocantins', value: 'TO' }
]
