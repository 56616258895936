import { AUTH_LOGIN, AUTH_LOGOUT } from '../actions/auth'
import { ERRORS_SHOW } from '../actions/errors'
import { MENU_LOAD_ITEMS } from '../actions/menu'
import Api from '../../service/api-web'
import Cookies from 'js-cookie'

import router from '../../router'
const state = {
  token: `${Cookies.get('authorization') || ''}${Cookies.get(
    'authorization1'
  ) || ''}${Cookies.get('authorization2') || ''}${Cookies.get(
    'authorization3'
  ) || ''}`,
  funcao: Cookies.get('funcao') || 'for'
}

const getters = {
  isAuthenticated: state => !!state.token,
  isAdmin: state => state.funcao === 'adm'
}

const actions = {
  [AUTH_LOGIN]: ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.login(payload)
        .then(resp => {
          commit(AUTH_LOGIN, resp.data)
          dispatch(MENU_LOAD_ITEMS)
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      Api.logout()
        .then(resp => {
          commit(AUTH_LOGOUT)
          router.replace({ path: '/login' })
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {
  [AUTH_LOGIN]: (state, payload) => {
    let {
      authorization = '',
      authorization1 = '',
      authorization2 = '',
      authorization3 = '',
      funcao = 'for'
    } = payload
    state.token = `${authorization}${authorization1}${authorization2}${authorization3}`
    const cookieConfig = { expires: 365, secure: true }
    Cookies.set('authorization', authorization, cookieConfig)
    Cookies.set('authorization1', authorization1, cookieConfig)
    Cookies.set('authorization2', authorization2, cookieConfig)
    Cookies.set('authorization3', authorization3, cookieConfig)
    Cookies.set('funcao', funcao)
  },
  [AUTH_LOGOUT]: state => {
    state.token = ''
    Cookies.set('authorization', '')
    Cookies.set('authorization1', '')
    Cookies.set('authorization2', '')
    Cookies.set('authorization3', '')
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
