import Vue from 'vue'
import Geocoder from '@pderas/vue2-geocoder'
const GOOGLE_MAPS_API_KEY = 'AIzaSyC6pVhPeT8AmD4IZoyHh7aWoHfSOq9eBIQ'

Vue.use(Geocoder, {
  defaultCountryCode: null,
  defaultLanguage: 'pt',
  defaultMode: 'address', // or 'lat-lng'
  googleMapsApiKey: GOOGLE_MAPS_API_KEY
})
