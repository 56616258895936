import StringUtils from '../utils/stringUtils'
const event = name => {
  let evt = document.createEvent('Event')
  evt.initEvent(name, true, true)
  return evt
}

export default {
  bind: function(el, binding, vnode) {
    el.addEventListener('input', e => {
      if (e.target.value) {
        vnode.elm.dispatchEvent(event('input'))
        e.target.value = StringUtils.normalizeUrl(e.target.value)
      }
    })
  }
}
