import { ERRORS_SHOW } from '../actions/errors'
import { AUTH_LOGOUT } from '../actions/auth'
import VueOnToast from 'vue-on-toast'
import * as _ from 'lodash'

const state = {}
const getters = {}

const actions = {
  [ERRORS_SHOW]: async ({ dispatch }, errors) => {
    if (errors != null && _.get(errors, 'response.data', null) != null) {
      let status = _.get(errors, 'response.status', null)
      let message =
        _.get(errors, 'response.data.message') ||
        _.get(errors, 'response.data.messageError') ||
        _.get(errors, 'response.data.msg') ||
        _.get(errors, 'response.data.msgError') ||
        _.get(JSON.parse(await errors.response.data.text()), 'message') ||
        _.get(JSON.parse(await errors.response.data.text()), 'error') ||
        `Ocorreu um erro ${status}`
      switch (status) {
        case 401:
        case 403:
          VueOnToast.ToastService.pop('error', 'Sessão expirada')
          dispatch(AUTH_LOGOUT)
          break
        default:
          VueOnToast.ToastService.pop('error', message)
          break
      }
    } else {
      VueOnToast.ToastService.pop(
        'error',
        'Erro na aplicação',
        'Ocorreu um erro inesperado'
      )
    }
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
