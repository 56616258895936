import {
  PARAMETRO_LOAD_TAGS,
  PARAMETRO_LOAD,
  PARAMETRO_DOMAIN_VALIDATE,
  PARAMETRO_LOAD_DELIVERY_AREA,
  PARAMETRO_UPDATE_AGENDA,
  PARAMETRO_UPDATE_DATA
} from '../actions/parametros'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [PARAMETRO_UPDATE_DATA]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.params
        .submitParams(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PARAMETRO_LOAD_TAGS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.parametros
        .getTags(
          getters.getFornecedorId,
          payload.onlyAdmin,
          payload.onlyAdvancedMaintenance
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PARAMETRO_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.params
        .obtemDados(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PARAMETRO_DOMAIN_VALIDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.params
        .validaDominio(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PARAMETRO_UPDATE_AGENDA]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.params
        .alterarAgendaEntrega(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PARAMETRO_LOAD_DELIVERY_AREA]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.params
        .getDeliveryArea(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
