import {
  POLITICA_DESCONTO_SAVE_ITENS,
  POLITICA_DESCONTO_SAVE,
  POLITICA_DESCONTO_LOAD,
  POLITICA_DESCONTO_REMOVE
} from '../actions/politicaDesconto'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}
const getters = {}

const actions = {
  [POLITICA_DESCONTO_SAVE]: ({ dispatch, getters }, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await Api.v4.discountPolicy.save(
          getters.getFornecedorId,
          getters.getEmpresaId,
          payload,
          'set'
        )
        resolve(resp.data)
      } catch (error) {
        dispatch(ERRORS_SHOW, error)
        reject(error)
      }
    })
  },
  [POLITICA_DESCONTO_SAVE_ITENS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.discountPolicy
        .save(
          getters.getFornecedorId,
          getters.getEmpresaId,
          payload,
          'increment'
        )
        .then(resp => {
          resolve(resp && resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [POLITICA_DESCONTO_REMOVE]: ({ dispatch, getters }, id) => {
    return new Promise((resolve, reject) => {
      Api.v4.discountPolicy
        .remove(getters.getFornecedorId, getters.getEmpresaId, id)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [POLITICA_DESCONTO_LOAD]: ({ dispatch, getters }, id) => {
    return new Promise((resolve, reject) => {
      Api.v4.discountPolicy
        .get(getters.getFornecedorId, getters.getEmpresaId, id)
        .then(resp => {
          resolve(resp && resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {}
export default {
  state,
  getters,
  actions,
  mutations
}
