import {
  GET_CONFIG_EMAIL,
  GET_INTEGRATIONS,
  GET_DOMAIN,
  GET_ERP_STACK,
  REMOVE_OAUTH,
  UPDATE_INTEGRATIONS
} from '../actions/integracoes'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const actions = {
  [GET_CONFIG_EMAIL]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.marketing.email
        .getConfig(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [GET_INTEGRATIONS]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.integracoes
        .getIntegrations(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [GET_DOMAIN]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.params
        .obtemDadosDominio(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [GET_ERP_STACK]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.integracoes
        .getErpStack(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [REMOVE_OAUTH]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.integracoes
        .removeOauth(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [UPDATE_INTEGRATIONS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.integracoes
        .updateIntegrations(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
export default {
  actions
}
