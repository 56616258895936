import menu from './menu'
import errors from './errors'
import novidades from './novidades'
import auth from './auth'
import gateway from './gateway'
import cupom from './cupom'
import relatorios from './relatorios'
import diagnostico from './diagnostico'
import loading from './loading'
import formaPagamento from './formaPagamento'
import cliente from './cliente'
import grupoPreco from './grupoPreco'
import segmento from './segmento'
import politicaDesconto from './politica-desconto'
import listaCompras from './listaCompras'
import caracteristicaProduto from './caracteristicaProduto'
import caracteristicaCliente from './caracteristicaCliente'
import caracteristicaPedido from './caracteristicaPedido'
import produto from './produto'
import setor from './setor'
import pagamento from './pagamento'
import oauth from './oauth'
import linkAcao from './link-acao'
import marketing from './marketing'
import tagProduto from './tagsProduto'
import edicaoPlanilha from './edicao-planilha'
import comboPromocional from './combo-promocional'
import usuario from './usuario'
import imagemProduto from './imagemProduto'
import categoria from './categoria'
import paginaEstatica from './paginaEstatica'
import restricaoCliente from './restricaoCliente'
import formasEntrega from './formasEntrega'
import tabelaPreco from './tabelaPreco'
import calendario from './calendario'
import areaVenda from './areaVenda'
import dadosEmpresa from './dadosEmpresa'
import admin from './admin'
import integracoes from './integracoes'
import fornecedor from './fornecedor'
import parametros from './parametros'
import statusPedidos from './statusPedidos'
import tema from './tema'
import textoEstatico from './textoEstatico'
import tagsAdicionais from './tagsAdicionais'
import assinatura from './assinatura'
import notificacaoPush from './notificacaoPush'
import carrossel from './carrossel'

export default {
  menu,
  errors,
  novidades,
  cliente,
  auth,
  gateway,
  cupom,
  loading,
  segmento,
  listaCompras,
  diagnostico,
  relatorios,
  grupoPreco,
  formaPagamento,
  politicaDesconto,
  caracteristicaProduto,
  caracteristicaCliente,
  caracteristicaPedido,
  produto,
  setor,
  pagamento,
  oauth,
  linkAcao,
  marketing,
  tagProduto,
  edicaoPlanilha,
  comboPromocional,
  usuario,
  imagemProduto,
  categoria,
  paginaEstatica,
  restricaoCliente,
  calendario,
  formasEntrega,
  tabelaPreco,
  areaVenda,
  dadosEmpresa,
  admin,
  integracoes,
  fornecedor,
  parametros,
  statusPedidos,
  tema,
  textoEstatico,
  tagsAdicionais,
  notificacaoPush,
  assinatura,
  carrossel
}
