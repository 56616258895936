export const CLIENTE_CREATE_BIND_INFO = 'CLIENTE_CREATE_BIND_INFO'
export const CLIENTE_EXPORT_NAV_LOGS = 'CLIENTE_EXPORT_NAV_LOGS'
export const CLIENTE_LOAD_SEGMENTS = 'CLIENTE_LOAD_SEGMENTS'
export const CLIENTE_CREATE = 'CLIENTE_CREATE'
export const CLIENTE_UPDATE = 'CLIENTE_UPDATE'
export const CLIENTE_LOAD = 'CLIENTE_LOAD'
export const CLIENTE_LOAD_BY_IDS = 'CLIENTE_LOAD_BY_IDS'
export const CLIENTE_REMOVE = 'CLIENTE_REMOVE'
export const CLIENTE_RESET_PASSWORD = 'CLIENTE_RESET_PASSWORD'
export const CLIENTE_ADDRESS_UPDATE_DEFAULT = 'CLIENTE_ADDRESS_UPDATE_DEFAULT'
export const CLIENTE_ADDRESS_UPDATE = 'CLIENTE_ADDRESS_UPDATE'
export const CLIENTE_ADDRESS_CREATE = 'CLIENTE_ADDRESS_CREATE'
export const CLIENTE_ADDRESS_REMOVE = 'CLIENTE_ADDRESS_REMOVE'
export const CLIENTE_VERIFICACAO = 'CLIENTE_VERIFICACAO'
export const LOG_NAV_LOGS = 'LOG_NAV_LOGS'

export const ADDRESS_COORDS_LOAD = 'ADDRESS_COORDS_LOAD'
export const ADDRESS_REMOVE = 'ADDRESS_REMOVE'
export const ADDRESS_LOAD = 'ADDRESS_LOAD'
export const ADDRESS_FORCE_CREATE = 'ADDRESS_FORCE_CREATE'

export const USUARIO_REMOVE = 'USUARIO_REMOVE'
export const USUARIO_CREATE = 'USUARIO_CREATE'
