<script>
import { mapGetters } from 'vuex'
export default {
  name: 'FormMixin',
  watch: {
    validation: {
      deep: true,
      handler(newVal) {
        this.touched = newVal.$dirty
      }
    }
  },
  computed: {
    ...mapGetters(['darkEnabled']),
    errors() {
      if (this.validation && (this.validation.$dirty || this.touched)) {
        let errorsMess = []
        if (
          this.validation.required != undefined &&
          !this.validation.required
        ) {
          errorsMess.push(this.errorMessage['required'] || 'Campo obrigatório')
        }
        if (this.validation.email != undefined && !this.validation.email) {
          errorsMess.push(this.errorMessage['email'] || 'E-mail inválido')
        }
        if (this.validation.numeric != undefined && !this.validation.numeric) {
          errorsMess.push(
            this.errorMessage['numeric'] || 'O campo deve ser do tipo numérico'
          )
        }
        if (
          this.validation.alphanumeric != undefined &&
          !this.validation.alphanumeric
        ) {
          errorsMess.push(
            this.errorMessage['alphanumeric'] ||
              'O campo deve ser do tipo alfanúmerico'
          )
        }
        if (this.errorMessage && this.errorMessage['custom']) {
          errorsMess.push(this.errorMessage['custom'])
        }
        return errorsMess
      }
      return []
    },
    count() {
      return this.counter ? this.maxLength || true : false
    }
  },
  methods: {
    onChange() {
      this.$emit('change', this.model)
      this.$emit('input', this.model)
      this.checkValidation()
    },
    onBlur() {
      this.$emit('blur', this.model)
      this.checkValidation()
    },
    checkValidation() {
      if (this.validation) {
        this.validation.$touch()
        this.touched = true
      }
    }
  }
}
</script>
