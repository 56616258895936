export const PAGINA_ESTATICA_CREATE = 'PAGINA_ESTATICA_CREATE'
export const PAGINA_ESTATICA_UPDATE = 'PAGINA_ESTATICA_UPDATE'
export const PAGINA_ESTATICA_LOAD = 'PAGINA_ESTATICA_LOAD'
export const PAGINA_ESTATICA_REMOVE = 'PAGINA_ESTATICA_REMOVE'
export const PAGINA_ESTATICA_LIST = 'PAGINA_ESTATICA_LIST'
export const TAGS = [
  {
    text: 'App ecommerce',
    value: 'APP'
  },
  {
    text: 'Site ecommerce',
    value: 'WEB'
  },
  {
    text: 'App de fidelização - área principal',
    value: 'APP_FID_MAIN',
    type: 'APPFID'
  },
  {
    text: 'App de fidelização - área rodapé',
    value: 'APP_FID_FOOTER',
    type: 'APPFID'
  }
]
