import {
  TABELA_PRECO_UPDATE_PAJE,
  TABELA_PRECO_LOAD_ALL,
  TABELA_PRECO_UPDATE_DISPONIVEL_VENDA,
  TABELA_PRECO_UPDATE_PRECOS,
  TABELA_PRECO_UPDATE_PRECOS_LOCAL,
  TABELA_PRECO_UPDATE_CLIENTE,
  TABELA_PRECO_LOAD_CLIENTE,
  TABELA_PRECO_LOAD_TIPO_GRUPO,
  TABELA_PRECO_UPLOAD_LOCAL,
  TABELA_PRECO_DOWNLOAD_LOCAL_CRITICAS,
  TABELA_PRECO_STATUS_UPLOAD
} from '../actions/tabelaPreco'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [TABELA_PRECO_LOAD_TIPO_GRUPO]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .getTipoGrupoClienteReferencia(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TABELA_PRECO_LOAD_CLIENTE]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .getDefaultclient(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TABELA_PRECO_UPDATE_PRECOS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .updateRemotePrices(
          getters.getEmpresaId,
          getters.getFornecedorId,
          payload
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TABELA_PRECO_UPDATE_PRECOS_LOCAL]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.tabelaPreco
        .alterarDadosTabelaPrecoLocal(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TABELA_PRECO_UPDATE_PAJE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .updatePajeAvailability(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TABELA_PRECO_UPDATE_CLIENTE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .updateDefaultClient(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TABELA_PRECO_LOAD_ALL]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .list(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TABELA_PRECO_UPDATE_DISPONIVEL_VENDA]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .updatePajeAvailability(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TABELA_PRECO_UPLOAD_LOCAL]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .uploadLocalPrice(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TABELA_PRECO_DOWNLOAD_LOCAL_CRITICAS]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tabelaPreco
        .dowloadLocalPriceCriticas(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TABELA_PRECO_STATUS_UPLOAD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.tabelaPreco
        .getStatusUpload(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
