import {
  CALENDARIO_CREATE,
  CALENDARIO_UPDATE,
  CALENDARIO_LOAD,
  CALENDARIO_REMOVE
} from '../actions/calendario'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'
//import VueOnToast from 'vue-on-toast'

const state = {}

const getters = {}

const actions = {
  [CALENDARIO_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.calendario
        .create(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CALENDARIO_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.calendario
        .update(getters.getFornecedorId, payload.idcalendarioentrega, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CALENDARIO_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.calendario
        .delete(getters.getFornecedorId, payload)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CALENDARIO_LOAD]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.calendario
        .get(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
