<template>
  <v-text-field
    v-model="model"
    :label="label"
    :placeholder="placeholder || ''"
    outlined
    rounded
    :solo="solo"
    flat
    :disabled="disabled"
    :readonly="readonly"
    :dense="dense"
    :type="visibled ? 'text' : 'password'"
    :append-icon="visibled ? 'fas fa-eye' : 'fas fa-eye-slash'"
    :error-messages="errors"
    :dark="darkEnabled"
    :clearable="clearable"
    :hint="hint"
    :counter="count"
    :maxLength="maxLength"
    @input="onChange"
    @blur="onBlur"
    @click:append="visibled = !visibled"
  >
    <template v-slot:append>
      <v-tooltip top v-if="help">
        <template v-slot:activator="{ on }">
          <v-icon v-on="on">far fa-question-circle</v-icon>
        </template>
        {{ help }}
      </v-tooltip>
    </template>
  </v-text-field>
</template>

<script>
import FormMixin from './FormMixin'
export default {
  name: 'FormPassword',
  props: {
    value: {
      type: [String, Number],
      default: '',
      required: false
    },
    label: {
      type: String,
      default: '',
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    maxLength: {
      type: String,
      default: '',
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    help: {
      type: String,
      default: '',
      required: false
    },
    counter: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  mixins: [FormMixin],
  data: () => ({
    visibled: false,
    model: '',
    touched: false
  }),
  created() {
    this.model = this.value
  },
  watch: {
    value: {
      deep: true,
      handler(newVal) {
        this.model = newVal
      }
    }
  }
}
</script>
