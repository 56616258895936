import { AREA_VENDA_FORNECEDOR_LOAD } from '../actions/areaVenda'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [AREA_VENDA_FORNECEDOR_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.areavenda
        .getAreasFornecedor(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
