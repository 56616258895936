<template>
  <div
    class="content-view"
    :class="{
      menuIsOpen: menuIsOpen && !hideMenu,
      menuHide: hideMenu,
      dark: darkEnabled,
      popupIsOpen: popupIsOpen
    }"
    :style="contentStyle"
  >
    <Header />
    <div class="container-page">
      <transition mode="out-in">
        <router-view class="child-view view-parent"></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Header from '@/components/Header'
export default {
  name: 'Content',
  components: { Header },
  props: {
    hideMenu: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    ...mapGetters([
      'menuIsOpen',
      'darkEnabled',
      'popupIsOpen',
      'getPopupHeight'
    ]),
    contentStyle() {
      let height =
        this.getPopupHeight > 0 ? this.getPopupHeight + 'px' : 'inherit'
      return {
        height,
        'max-height': height
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-view {
  width: 100%;
  min-height: 100vh;
  background-color: #f1f3f6;
  padding-top: 65px;
  padding-left: 200px;
  transition: all 0.3s ease-in-out;
  color: #000;
  &.dark {
    background-color: #424242e0;
    color: #fff;
  }
  &.popupIsOpen {
    overflow: hidden;
    max-height: 100vh;
  }
  &.menuHide {
    padding-left: 0px;
    padding-top: 0px;
    transform: translateX(0px);
  }
}
@media (max-width: 768px) {
  .content-view {
    padding-left: 0px;
    transform: translateX(200px);
    &.menuIsOpen {
      padding-left: 0px;
      transform: translateX(0px);
    }
  }
}
</style>
