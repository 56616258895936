import {
  FORMA_PAGAMENTO_LIST,
  FORMA_PAGAMENTO_DEFAULT,
  FORMA_PAGAMENTO_BYID,
  FORMA_PAGAMENTO_REMOVE,
  FORMA_PAGAMENTO_ADD,
  FORMA_PAGAMENTO_UPDATE
} from '../actions/formaPagamento'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {
  list: []
}

const getters = {
  getFormasPagamento: state => state.list
}
const actions = {
  [FORMA_PAGAMENTO_LIST]: ({ dispatch, commit }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaPagamento
        .list(payload)
        .then(resp => {
          const response = resp.data
          commit(FORMA_PAGAMENTO_LIST, response)
          resolve(response)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [FORMA_PAGAMENTO_DEFAULT]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaPagamento
        .toggleDefault(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [FORMA_PAGAMENTO_BYID]: ({ dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaPagamento
        .getById(params)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [FORMA_PAGAMENTO_REMOVE]: ({ dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaPagamento
        .remove(params)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [FORMA_PAGAMENTO_ADD]: ({ dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaPagamento
        .create(params)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [FORMA_PAGAMENTO_UPDATE]: ({ dispatch }, params) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaPagamento
        .update(params)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {
  [FORMA_PAGAMENTO_LIST](state, value) {
    state.list = value
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
