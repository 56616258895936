import {
  RESTRICAO_CLIENTE_CREATE,
  RESTRICAO_CLIENTE_CREATE_ALL,
  RESTRICAO_CLIENTE_LOAD_PRODUCTS,
  RESTRICAO_CLIENTE_LOAD_CLIENTS,
  RESTRICAO_CLIENTE_REMOVE
} from '../actions/restricaoCliente'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [RESTRICAO_CLIENTE_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.clientRestriction
        .save(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [RESTRICAO_CLIENTE_CREATE_ALL]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.clientRestriction
        .saveAll(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [RESTRICAO_CLIENTE_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.clientRestriction
        .remove(getters.getFornecedorId, payload)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [RESTRICAO_CLIENTE_LOAD_PRODUCTS]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.clientRestriction
        .getProducts(payload.idrestricaocliente, payload.page, payload.limit)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [RESTRICAO_CLIENTE_LOAD_CLIENTS]: ({ dispatch, getters }, payload) => {
    const data = {
      search: payload.search,
      page: payload.page,
      limit: payload.limit,
      idfornecedor: getters.getFornecedorId
    }
    return new Promise((resolve, reject) => {
      Api.v4.clientRestriction
        .getClients(payload.idrestricaocliente, data)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
