import {
  TAGAGRUPADOR_DELETA,
  TAGPRODUTO_DELETA,
  TAGAGRUPADOR_CREATE,
  TAGAGRUPADOR_LOAD,
  TAGPRODUTO_CREATE,
  TAGPRODUTO_LOAD,
  TAGAGRUPADOR_UPDATE,
  TAGPRODUTO_UPDATE
} from '../actions/tagsProduto'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}
const getters = {}

const actions = {
  [TAGAGRUPADOR_DELETA]: ({ dispatch, getters }, idagrupador) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagPesquisa
        .deletaAgrupador(getters.getFornecedorId, idagrupador)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TAGPRODUTO_DELETA]: ({ dispatch, getters }, idtag) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagPesquisa
        .deletaTag(getters.getFornecedorId, idtag)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TAGAGRUPADOR_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagPesquisa
        .inserirAgrupador(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TAGPRODUTO_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagPesquisa
        .inserirTag(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TAGAGRUPADOR_LOAD]: ({ dispatch, getters }, idagrupador) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagPesquisa
        .obterAgrupador(getters.getFornecedorId, idagrupador)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TAGPRODUTO_LOAD]: ({ dispatch, getters }, idtag) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagPesquisa
        .obterTag(getters.getFornecedorId, idtag)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TAGAGRUPADOR_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagPesquisa
        .atualizaAgrupador(
          getters.getFornecedorId,
          payload.idagrupadortagpesquisa,
          payload
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [TAGPRODUTO_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagPesquisa
        .atualizaTag(getters.getFornecedorId, payload.idtagpesquisa, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
