<template>
  <div class="menu-left" :class="{ menuIsOpen: menuIsOpen }">
    <v-navigation-drawer
      height="100%"
      width="200px"
      permanent
      :dark="darkEnabled"
    >
      <v-list dense style="text-align: left">
        <div
          v-for="(item, index) in getArrayMenu"
          :key="'item-menu-' + index + '-' + item.grupo"
        >
          <v-list-item
            class="list-item"
            v-if="!item.subitens || item.subitens.length <= 1"
            link
            :href="item.link.substring(item.link.indexOf('https'))"
            :target="item.tag.includes('target-blank') ? '_blank' : null"
          >
            <v-list-item-title class="btn-font-size">
              <b>{{ item.nome }}</b>
            </v-list-item-title>
          </v-list-item>
          <v-list-group v-else :value="isGroupActive(item)">
            <template v-slot:activator>
              <v-list-item-title class="btn-font-size"
                ><b>{{ item.grupo }}</b></v-list-item-title
              >
            </template>
            <template v-slot:appendIcon>
              <v-icon size="17">fas fa-angle-down</v-icon>
            </template>
            <v-list-item
              v-for="(subitem, subindex) in item.subitens"
              :key="'subindex-' + subindex + '-' + subitem.iditem"
              link
              class="list-subitem"
              :style="
                isMenuActive(subitem)
                  ? 'color: #1976d2 !important; background-color: #e4effa;'
                  : ''
              "
              :href="subitem.link.substring(subitem.link.indexOf('https'))"
              :target="subitem.tag.includes('target-blank') ? '_blank' : null"
            >
              <v-list-item-title class="btn-font-size">
                {{ subitem.nome }}
                <div
                  class="chat-budge-menu"
                  v-if="
                    subitem.nome == 'Central de Atendimento' &&
                      quantidade_mensagens_nao_lidas > 0
                  "
                ></div>
              </v-list-item-title>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiWeb from '../service/api-web'

export default {
  name: 'NavBarLeft',
  data: () => ({
    quantidade_mensagens_nao_lidas: 0
  }),
  computed: {
    ...mapGetters([
      'menuIsOpen',
      'getArrayMenu',
      'darkEnabled',
      'getUsuarioId',
      'getFornecedorId'
    ])
  },
  created() {
    this.getChatCountMessages()
  },
  methods: {
    isGroupActive(item) {
      let filter = (item.subitens || []).filter(
        subitem => location.pathname == subitem.link
      )
      return filter.length > 0
    },
    getChatCountMessages() {
      ApiWeb.v1.usuario
        .obtemQuantidadeMensagensChat(this.getUsuarioId, this.getFornecedorId)
        .then(resp => {
          this.quantidade_mensagens_nao_lidas = parseInt(resp.data.count)
        })
    },
    isMenuActive(subitem) {
      return location.pathname.endsWith(subitem.link)
    }
  }
}
</script>

<style lang="scss">
.menu-left {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 65px;
  transform: translate3d(0px, 0px, 0px);
  transition: all 0.3s ease-in-out;
  background-color: #f7f9fa;
  .list-subitem {
    padding-left: 25px;
  }
  .v-list-item--link {
    border-bottom: 1px solid #e4e5e7;
  }
}
@media (max-width: 768px) {
  .menu-left {
    transform: translate3d(0px, 0px, 0px);
    &.menuIsOpen {
      transform: translate3d(-201px, 0px, 0px);
    }
  }
}
.chat-budge-menu {
  background-color: #ff1e19;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 10;
  font-size: 12px;
  color: #ffff;
}
</style>
