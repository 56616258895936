import { ifUserHasAccess } from '../guards'

const route = [
  {
    path: '/fornecedor/customizacao/textos-estaticos',
    name: 'Textos estáticos',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/customizacao/texto-estatico/TextosEstaticos'),
    meta: {
      header: {
        title: 'Textos estáticos',
        subtitle:
          'Use essa área para editar os textos estáticos do seu e-commerce',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Textos estáticos',
            route: '/fornecedor/customizacao/textos-estaticos'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/customizacao/carrossel',
    name: 'Carrossel',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/customizacao/carrossel/Carrossel.vue'),
    meta: {
      header: {
        title: 'Personalização de Carrossel',
        subtitle:
          'Use esta área para parametrizar as configurações dos carrosséis',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Personalização de Carrossel',
            route: '/fornecedor/customizacao/carrossel'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/customizacao/vitrine-produto',
    name: 'Vitrine',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/customizacao/vitrine/VitrineTemplate.vue'),
    meta: {
      header: {
        title: 'Personalização de vitrines de produtos',
        subtitle:
          'Use esta área para parametrizar as configurações das vitrines de produtos',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Personalização de vitrines de produtos',
            route: '/fornecedor/customizacao/vitrine-produto'
          }
        ]
      }
    }
  }
]

export default route
