import { RELATORIO_LOAD_CSV, RELATORIO_LOAD } from '../actions/relatorios'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {
  active: null
}

const getters = {}

const actions = {
  [RELATORIO_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.relatorio
        .list(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [RELATORIO_LOAD_CSV]: ({ dispatch, getters }, body) => {
    return new Promise((resolve, reject) => {
      Api.v4.relatorio
        .getCsv(getters.getFornecedorId, body)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
  /*   [CUPOM_RECOMENDACAO_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.cupomDesconto.recomendacao
        .save(getters.getFornecedorId, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CUPOM_RECOMENDACAO_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.cupomDesconto.recomendacao
        .get(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CUPOM_DESCONTO_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.cupomDesconto.fornecedor
        .create(getters.getFornecedorId, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CUPOM_DESCONTO_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.cupomDesconto.fornecedor
        .remove(getters.getFornecedorId, payload.idcupomdesconto)
        .then(() => resolve())
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  } */
}
const mutations = {}
export default {
  state,
  getters,
  actions,
  mutations
}
