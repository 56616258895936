import {
  CLIENTE_CREATE,
  CLIENTE_UPDATE,
  CLIENTE_LOAD,
  CLIENTE_LOAD_BY_IDS,
  CLIENTE_CREATE_BIND_INFO,
  CLIENTE_EXPORT_NAV_LOGS,
  CLIENTE_LOAD_SEGMENTS,
  CLIENTE_REMOVE,
  CLIENTE_ADDRESS_CREATE,
  CLIENTE_ADDRESS_UPDATE,
  CLIENTE_ADDRESS_UPDATE_DEFAULT,
  CLIENTE_RESET_PASSWORD,
  ADDRESS_COORDS_LOAD,
  ADDRESS_REMOVE,
  ADDRESS_LOAD,
  ADDRESS_FORCE_CREATE,
  LOG_NAV_LOGS,
  CLIENTE_VERIFICACAO
} from '../actions/cliente'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {
  active: null
}

const getters = {}

const actions = {
  [CLIENTE_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .create(getters.getFornecedorId, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .update(getters.getFornecedorId, getters.getUsuarioId, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_CREATE_BIND_INFO]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .bindInfoCadastroExtraCliente(getters.getFornecedorId, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_LOAD_SEGMENTS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .listAvailableSegments(getters.getFornecedorId, payload.idcliente)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_LOAD_BY_IDS]: ({ dispatch, getters }, { ids, page }) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .getClientsByIds(getters.getFornecedorId, ids, page)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .remove(
          getters.getFornecedorId,
          payload.idcliente,
          getters.getUsuarioId
        )
        .then(() => resolve())
        .catch(err => {
          const { response } = err
          dispatch(ERRORS_SHOW, err)
          reject(response.data)
        })
    })
  },
  [CLIENTE_EXPORT_NAV_LOGS]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .getNavigatonLogsExport(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [LOG_NAV_LOGS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .getNavigatonLogs(
          getters.getFornecedorId,
          payload.idcliente,
          payload.qtd
        )
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_RESET_PASSWORD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .resetPassword(getters.getFornecedorId, payload.idcliente)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_LOAD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .getFull(getters.getFornecedorId, payload.idcliente)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_ADDRESS_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente.address
        .save(getters.getFornecedorId, payload.idcliente, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_ADDRESS_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente.address
        .update(getters.getFornecedorId, payload.idcliente, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_ADDRESS_UPDATE_DEFAULT]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente.address
        .updateDefault(getters.getFornecedorId, payload.idcliente, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [ADDRESS_LOAD]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.address
        .get(payload.idendereco)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [ADDRESS_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.address
        .del(getters.getFornecedorId, payload.idendereco)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [ADDRESS_COORDS_LOAD]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.address
        .getCoords(payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [ADDRESS_FORCE_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.address
        .forceCreateAddress(getters.getFornecedorId, payload.idcliente)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CLIENTE_VERIFICACAO]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.cliente
        .sendVerificacao(
          getters.getFornecedorId,
          payload.canal,
          payload.idcliente,
          payload.fonte
        )
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {}
export default {
  state,
  getters,
  actions,
  mutations
}
