const tags = [
  {
    tag: 'uses-delegated-price-storage',
    description: 'O fornecedor usa o Paje'
  },
  {
    tag: 'uses-price-storage-v2',
    description: 'O fornecedor usa o roteamento para Paje v2'
  },
  {
    tag: 'support-user-cnpj',
    description: 'Aceitar cadastro de usuário com CNPJ'
  },
  {
    tag: 'support-user-cpf',
    description: 'Aceitar cadastro de usuário com CPF'
  },
  {
    tag: 'support-price-variance-by-payment',
    description: 'O fornecedor usa formas de pagamento selecionáveis'
  },
  {
    tag: 'uses-only-products-with-image',
    description: 'O fornecedor somente exibe produtos com imagem'
  },
  { tag: 'support-budget', description: 'Fornecedor usa orçamento' },
  {
    tag: 'sincronizes-budget-submission',
    description: 'Submissões de orçamento também são submetidas para o ERP'
  },
  {
    tag: 'support-client-limit',
    description: 'Apresentar Limites de crédito'
  },
  {
    tag: 'support-client-billing',
    description: 'Apresentar Faturas Importadas'
  },
  { tag: 'has-delivery', description: 'O fornecedor opera delivery' },
  {
    tag: 'has-collect',
    description: 'O fornecedor opera retirada na loja'
  },
  {
    tag: 'uses-local-discount-policy',
    description: 'Cadastra políticas de desconto no banco do e-commerce '
  },
  {
    tag: 'uses-single-address-for-client',
    description: 'O fornecedor usa um unico endereço para o cliente'
  },
  {
    tag: 'block-not-approved-user',
    description:
      'Bloqueia entrada / mix para usuários com cadastro não aprovado'
  },
  {
    tag: 'bypass-personal-data-validation',
    description: '[implantaçao] não validar dados pessoais ao cadastrar usuario'
  },
  {
    tag: 'uses-refined-remote-billing-counting',
    description:
      'Usa faturamento com contagem de grupos de preço pelo Paje. *EXPERIMENTAL E PERIGOSO*'
  },
  {
    tag: 'moves-client-within-region',
    description:
      'Permite que um cliente seja cadastrado em outro fornecedor da mesma empresa, caso este fornecedor esteja fora da área de atendimento'
  },
  {
    tag: 'uses-fidelity-app',
    description: 'Indica que o fornecedor tem o aplicativo de fidelização'
  },
  {
    tag: 'show-unavailable-products',
    description: 'Permite que o fornecedor exiba produtos indisponiveis'
  },
  {
    tag: 'export-price-paje-xml',
    description:
      'Configura o fornecedor para exportar os preços do grupo padrão em xml  (só com paje)'
  },
  {
    tag: 'use-payment-v2',
    description: 'O fornecedor usa o payment na versão 2.0'
  },
  {
    tag: 'enable-max-limit-control',
    description: 'Habilita o controle do máximo por pedido para produtos'
  },
  {
    tag: 'enable-min-limit-control',
    description: 'Habilita o controle do mínimo por pedido para produtos'
  },
  {
    tag: 'uses-generic-foreign-document',
    description: 'Permite que o fornecedor trabalhe com documentos estrangeiros'
  },
  {
    tag: 'uses-share-coupom',
    description: 'Utilizar cupom de compartilhamento'
  },
  {
    tag: 'disable-cookies-registration',
    description: 'Desabilita a gravação de Cookies'
  },
  {
    tag: 'user-id-erp',
    description: 'Permite que usuários tenha um código de ERP'
  },
  {
    tag: 'allow-age-control',
    description: 'Habilitar controle de maioridade'
  },
  {
    tag: 'disable-evaluation-orders-required-app',
    description: 'Desabilita a avaliação obrigatória dos pedidos no aplicativo'
  },
  {
    tag: 'use-erp-address',
    description: 'Utiliza o endereço vindo do ERP prioritariamente'
  },
  {
    tag: 'allow-number-order-customer',
    description: 'Permite informar o número do pedido do cliente'
  },
  {
    tag: 'disable-export-order',
    description: 'Desabilitar recurso para exportar pedidos/orçamentos em pdf'
  },
  {
    tag: 'uses-google-shopping',
    description: 'Permite que a loja utilize o Google shopping'
  },
  {
    tag: 'uses-facebook-shopping',
    description: 'Permite que a loja utilize o Facebook catálogos'
  },
  {
    tag: 'block-user-outside-area',
    description:
      'Bloqueia visualização de produtos para clientes com endereço fora da área de atuação'
  }
]
export default tags
