<script>
export default {
  methods: {
    confirmSwal({ title, text, html, type = 'warning' }, callback = () => {}) {
      this.$swal
        .fire({
          title,
          text,
          type,
          html,
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(result => {
          if (result.value) callback()
        })
    }
  }
}
</script>
