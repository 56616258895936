import {
  GET_FORNECEDOR_DATA,
  FORNECEDOR_LOAD,
  FORNECEDOR_LOAD_ALL
} from '../actions/fornecedor'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'
const actions = {
  [FORNECEDOR_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v1.fornecedor
        .obtemDadosFornecedor(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [FORNECEDOR_LOAD_ALL]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v1.fornecedor
        .buscarFornecedoresByEmpresa(getters.getEmpresaId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [GET_FORNECEDOR_DATA]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v1.fornecedor
        .obtemDadosFornecedor(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
export default {
  actions
}
