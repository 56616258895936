import {
  TEMA_LOAD,
  TEMA_LOAD_MENU,
  TEMA_UPDATE_MENU,
  TEMA_RESTAURAR_MENU,
  TEMA_LOAD_STATIC,
  TEMA_UPLOAD_IMAGE,
  TEMA_UPDATE
} from '../actions/tema'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [TEMA_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.theme
        .obtemDadosTema(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TEMA_LOAD_MENU]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.theme
        .obterDadosMenu(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TEMA_LOAD_STATIC]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.theme
        .obterDadosEstaticos(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TEMA_UPLOAD_IMAGE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.theme
        .uploadImage(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TEMA_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.theme
        .submitTheme(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TEMA_RESTAURAR_MENU]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.theme
        .restaurarTema(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TEMA_UPDATE_MENU]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.theme
        .submitMenus(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
