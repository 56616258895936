import {
  SEGMENTO_CLIENTE_LIST,
  SEGMENTO_LIST,
  SEGMENTO_LIST_ALL
} from '../actions/segmento'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

// TODO: checar melhor local
const state = {
  listSgmentosCliente: [],
  listSegmentos: []
}

const getters = {
  getSegmentos: state => state.listSgmentosCliente,
  getListSegmentos: state => state.listSegmentos,
  getIdListSegmentos: state =>
    (state.listSegmentos || []).map(item => item.idsegmento) || []
}
const actions = {
  [SEGMENTO_LIST]: ({ dispatch, commit, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.fornecedor
        .getSegmentos(getters.getFornecedorId)
        .then(resp => {
          commit(SEGMENTO_LIST, resp.data)
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [SEGMENTO_CLIENTE_LIST]: ({ dispatch, commit }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.segmentoCliente
        .list(payload)
        .then(resp => {
          commit(SEGMENTO_CLIENTE_LIST, resp.data)
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [SEGMENTO_LIST_ALL]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.segmentos
        .getAll(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {
  [SEGMENTO_CLIENTE_LIST](state, value) {
    state.listSgmentosCliente = value
  },
  [SEGMENTO_LIST]: (state, payload) => {
    state.listSegmentos = payload
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
