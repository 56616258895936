import {
  GRUPO_PRECO_LIST,
  GRUPO_PRECO_REMOVE,
  GRUPO_PRECO_UPDATE_PADRAO,
  GRUPO_PRECO_VALIDA_CEP,
  GRUPO_PRECO_SALVAR_CEP,
  GRUPO_PRECO_LOAD_FAIXA_CEP
} from '../actions/grupoPreco'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {
  list: []
}

const getters = {
  getGruposPreco: state => state.list
}
const actions = {
  [GRUPO_PRECO_LIST]: ({ dispatch, commit }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gruposDePrecos
        .list(payload)
        .then(resp => {
          commit(GRUPO_PRECO_LIST, resp.data)
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [GRUPO_PRECO_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gruposDePrecos
        .delete(payload, getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [GRUPO_PRECO_UPDATE_PADRAO]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gruposDePrecos
        .setDefault(payload.codigoGrupo, getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [GRUPO_PRECO_VALIDA_CEP]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gruposDePrecos
        .validarFaixaCep(payload.codigoGrupo, getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [GRUPO_PRECO_SALVAR_CEP]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gruposDePrecos
        .salvarFaixasCep(payload.codigoGrupo, getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [GRUPO_PRECO_LOAD_FAIXA_CEP]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.gruposDePrecos
        .getGrupoPreco(payload, getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {
  [GRUPO_PRECO_LIST](state, value) {
    state.list = value
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
