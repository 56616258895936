import {
  NOTIFICACAO_PUSH_LOAD_CLIENTS,
  NOTIFICACAO_PUSH_SEND
} from '../actions/notificacaoPush'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [NOTIFICACAO_PUSH_LOAD_CLIENTS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.marketing.push
        .getUsers(
          getters.getFornecedorId,
          payload.cadastroStart,
          payload.cadastroEnd,
          payload.compraStart,
          payload.compraEnd,
          payload.acessoStart,
          payload.acessoEnd,
          payload.nascimentoStart,
          payload.nascimentoEnd,
          payload.carrinhoAberto,
          payload.cadastroAprovado,
          payload.segmentosSelecionados,
          payload.offset,
          payload.limit
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [NOTIFICACAO_PUSH_SEND]: ({ dispatch, getters }, payload) => {
    const data = {
      ...payload,
      idfornecedor: getters.getFornecedorId
    }
    return new Promise((resolve, reject) => {
      Api.v2.marketing.push
        .notificar(data)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {}
export default {
  state,
  getters,
  actions,
  mutations
}
