export const OBTEM_PEDIDO_SIMPLES = 'OBTEM_PEDIDO_SIMPLES'
export const BUSCAR_STATUS_PEDIDO = 'BUSCAR_STATUS_PEDIDO'
export const SET_STATUS_PEDIDO = 'SET_STATUS_PEDIDO'
export const LIMPAR_ID_PEDIDO_ERP = 'LIMPAR_ID_PEDIDO_ERP'
export const SOFT_DELETE_PEDIDO = 'SOFT_DELETE_PEDIDO'
export const DESABILITAR_MIX = 'DESABILITAR_MIX'
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS'
export const REMOVE_IMAGE_HISTORY = 'REMOVE_IMAGE_HISTORY'
export const REMOVER_POLITICAS = 'REMOVER_POLITICAS'
export const REMOVER_CLIENTES = 'REMOVER_CLIENTES'
export const SUBMIT_TAGS = 'SUBMIT_TAGS'
export const UPDATE_PAGE_AGE_CONTROL = 'UPDATE_PAGE_AGE_CONTROL'
