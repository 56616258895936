import {
  DADOS_EMPRESA_SAVE,
  DADOS_EMPRESA_LOAD,
  DADOS_EMPRESA_GET,
  DADOS_EMPRESA_SAVE_PAYMENT
} from '../actions/dadosEmpresa'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}
const getters = {}

const actions = {
  [DADOS_EMPRESA_SAVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.company
        .saveCompanySupplier(
          getters.getFornecedorId,
          getters.getEmpresaId,
          payload
        )
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [DADOS_EMPRESA_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.company
        .getCompanySupplier(getters.getFornecedorId, getters.getEmpresaId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [DADOS_EMPRESA_GET]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.company
        .get(getters.getEmpresaId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [DADOS_EMPRESA_SAVE_PAYMENT]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.company
        .updatePaymentInfo(getters.getEmpresaId, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
