import store from '../store'
import { LOADING_HIDE, LOADING_SHOW } from '@/store/actions/loading'
const publicRoutes = ['/oauth']

export const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

export const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

export const checkPermission = (to, from, next) => {
  if (
    publicRoutes.includes(to.fullPath) ||
    store.getters.getPermissoes.includes(to.fullPath)
  ) {
    return next()
  } else {
    const permissaoBasePublica = publicRoutes.find(permissao =>
      to.fullPath.startsWith(permissao)
    )
    const permissaoBase = store.getters.getPermissoes.find(permissao =>
      to.fullPath.startsWith(permissao)
    )
    if (permissaoBasePublica || permissaoBase) {
      return next()
    }
    return next('/sem-permissao')
  }
}
export const ifUserHasAccess = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (store.getters.permissoesLoaded) {
      return checkPermission(to, from, next)
    } else {
      store.dispatch(LOADING_SHOW)
      store.watch(
        () => store.getters.getPermissoes,
        () => {
          store.dispatch(LOADING_HIDE)
          return checkPermission(to, from, next)
        }
      )
    }
  } else {
    return next('/login')
  }
}
