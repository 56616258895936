import {
  CATEGORIA_CREATE,
  CATEGORIA_UPDATE,
  CATEGORIA_LOAD,
  CATEGORIA_LOAD_RAIZ,
  CATEGORIA_LOAD_CHILDREN,
  CATEGORIA_REMOVE,
  CATEGORIA_ORDENACAO
} from '../actions/categoria'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'
//import VueOnToast from 'vue-on-toast'

const state = {}

const getters = {}

const actions = {
  [CATEGORIA_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .salvarSetor(getters.getFornecedorId, payload.idsegmento, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CATEGORIA_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .atualizarSetor(getters.getFornecedorId, payload.idsegmento, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CATEGORIA_REMOVE]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.setoresProduto
        .remove(payload)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CATEGORIA_ORDENACAO]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .salvarOrdenacao(getters.getFornecedorId, payload)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CATEGORIA_LOAD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .getSetor(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [CATEGORIA_LOAD_RAIZ]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .setoresRaiz(
          getters.getFornecedorId,
          payload.idsegmento,
          payload.promocional
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [CATEGORIA_LOAD_CHILDREN]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .filhos(payload.idsegmento, payload.idsetor)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
