import Vue from 'vue'
import {
  formatPrecision,
  formatDate,
  formatDateTime,
  formatMoney
} from '../filters/formater'

Vue.filter('formatDate', formatDate)
Vue.filter('formatPrecision', formatPrecision)
Vue.filter('formatDateTime', formatDateTime)
Vue.filter('formatMoney', formatMoney)
