export const PRODUTO_LOAD = 'PRODUTO_LOAD'
export const PRODUTO_CREATE = 'PRODUTO_CREATE'
export const PRODUTO_UPDATE = 'PRODUTO_UPDATE'
export const PRODUTO_REMOVE = 'PRODUTO_REMOVE'
export const PRODUTO_DESASSOCIAR = 'PRODUTO_DESASSOCIAR'
export const PRODUTO_ASSOCIAR = 'PRODUTO_ASSOCIAR'
export const PRODUTO_SETORES_FOLHA = 'PRODUTO_SETORES_FOLHA'
export const PRODUTO_LOAD_BY_COD_BARRAS = 'PRODUTO_LOAD_BY_COD_BARRAS'
export const PRODUTOS_LOAD_BY_COD_INTERNO = 'PRODUTOS_LOAD_BY_COD_INTERNO'
export const PRODUTOS_LOAD_BY_IDS_EXTRA = 'PRODUTOS_LOAD_BY_IDS_EXTRA'
export const PRODUTO_DESASSOCIAR_TAGS = 'PRODUTO_DESASSOCIAR_TAGS'
export const PRODUTO_LOAD_IMAGES = 'PRODUTO_LOAD_IMAGES'
export const PRODUTO_REMOVE_IMAGE = 'PRODUTO_REMOVE_IMAGE'
export const PRODUTO_ASSOCIAR_TAGS = 'PRODUTO_PRODUTO_ASSOCIAR_TAGS'
export const OBTER_PRODUTOS = 'OBTER_PRODUTOS'
export const PRODUTO_REMOVE_SUBITEM = 'PRODUTO_REMOVE_SUBITEM'
