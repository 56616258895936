import {
  NOVIDADES_COUNT,
  NOVIDADES_LIST,
  NOVIDADES_LOAD
} from '../actions/novidades'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'
import * as _ from 'lodash'
const state = { contador: 0 }
const getters = {
  getNovidadesContador: state => state.contador
}

const actions = {
  [NOVIDADES_COUNT]: ({ commit }) => {
    Api.v4.novidades
      .getNew()
      .then(resp => {
        commit(NOVIDADES_COUNT, _.get(resp, 'data.total', 0))
      })
      .catch(() => {
        // console.log('Falha ao buscar contador de novidades', err)
      })
  },
  [NOVIDADES_LIST]: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      Api.v4.novidades
        .list()
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [NOVIDADES_LOAD]: ({ dispatch }, idnovidade) => {
    return new Promise((resolve, reject) => {
      Api.v4.novidades
        .obter(idnovidade)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {
  [NOVIDADES_COUNT]: (state, payload) => {
    state.contador = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
