import {
  TAGADICIONAL_BYID,
  TAGADICIONAL_UPDATE,
  TAGADICIONAL_CREATE,
  TAGADICIONAL_REMOVE
} from '../actions/tagsAdicionais'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const actions = {
  [TAGADICIONAL_BYID]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagcustom
        .obter(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [TAGADICIONAL_UPDATE]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagcustom
        .atualiza(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [TAGADICIONAL_CREATE]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagcustom
        .adiciona(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [TAGADICIONAL_REMOVE]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.tagcustom
        .remove(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
export default {
  actions
}
