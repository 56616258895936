import {
  EDICAO_PLANILHA_IMPORTAR,
  EDICAO_PLANILHA_EXPORTAR,
  EDICAO_PLANILHA_CONFIG
} from '../actions/edicao-planilha'

import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [EDICAO_PLANILHA_IMPORTAR]: ({ dispatch, getters }, form) => {
    return new Promise((resolve, reject) => {
      Api.v4.eidcaoPlanilha
        .importar(getters.getFornecedorId, form)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [EDICAO_PLANILHA_EXPORTAR]: ({ dispatch, getters }, modelo) => {
    return new Promise((resolve, reject) => {
      Api.v4.eidcaoPlanilha
        .exportar(getters.getFornecedorId, modelo)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [EDICAO_PLANILHA_CONFIG]: ({ dispatch }, modelo) => {
    return new Promise((resolve, reject) => {
      Api.v4.eidcaoPlanilha
        .configuracao(modelo)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
