export const TABELA_PRECO_UPDATE_PAJE = 'TABELA_PRECO_UPDATE_PAJE'
export const TABELA_PRECO_LOAD_ALL = 'TABELA_PRECO_LOAD_ALL'
export const TABELA_PRECO_UPDATE_DISPONIVEL_VENDA =
  'TABELA_PRECO_UPDATE_DISPONIVEL_VENDA'
export const TABELA_PRECO_UPDATE_PRECOS = 'TABELA_PRECO_UPDATE_PRECOS'
export const TABELA_PRECO_UPDATE_PRECOS_LOCAL =
  'TABELA_PRECO_UPDATE_PRECOS_LOCAL'
export const TABELA_PRECO_UPDATE_CLIENTE = 'TABELA_PRECO_UPDATE_CLIENTE'
export const TABELA_PRECO_LOAD_CLIENTE = 'TABELA_PRECO_LOAD_CLIENTE'
export const TABELA_PRECO_LOAD_TIPO_GRUPO = 'TABELA_PRECO_LOAD_TIPO_GRUPO'
export const TABELA_PRECO_UPLOAD_LOCAL = 'TABELA_PRECO_UPLOAD_LOCAL'
export const TABELA_PRECO_DOWNLOAD_LOCAL_CRITICAS =
  'TABELA_PRECO_DOWNLOAD_LOCAL_CRITICAS'
export const TABELA_PRECO_STATUS_UPLOAD = 'TABELA_PRECO_STATUS_UPLOAD'
