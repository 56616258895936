import { USUARIO_REMOVE, USUARIO_CREATE } from '../actions/cliente'
import {
  USUARIO_DISABLE,
  USUARIO_ENABLE,
  USUARIO_UPDATE,
  USUARIO_NEW,
  USUARIO_UPDATE_INTEGRATIONS,
  USUARIO_LOAD_PROFILE,
  USUARIO_UPDATE_PASSWORD,
  USUARIO_UPDATE_NAME,
  USUARIO_UPDATE_PERMISSION
} from '../actions/usuario'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {
  active: null
}

const getters = {}

const actions = {
  [USUARIO_REMOVE]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.usuario
        .removerVinculoUsuario(
          payload.idusuario,
          payload.idfornecedor,
          payload.idcadastroextracliente
        )
        .then(() => resolve())
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_CREATE]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.usuario
        .criarVinculoUsuario(
          payload.email,
          payload.idcadastroextracliente,
          payload.idfornecedor
        )
        .then(() => resolve())
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_UPDATE_PERMISSION]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.usuario
        .atualizarPermissaoNavegacao(
          getters.getFornecedorId,
          payload.idusuario,
          payload
        )
        .then(() => resolve())
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_DISABLE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.usuario
        .desativarUsuario(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_ENABLE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.usuario
        .reativarUsuario(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.usuario
        .atualizarUsuario(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_NEW]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.usuario
        .criarUsuario(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_UPDATE_INTEGRATIONS]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.usuario
        .atualizarIntegracoes(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_UPDATE_PASSWORD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.usuario
        .atualizarSenha(
          getters.getFornecedorId,
          payload.oldPass,
          payload.newPass
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_UPDATE_NAME]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v1.usuario
        .alterarNomeUsuario(
          getters.getFornecedorId,
          payload.idusuario,
          payload.nome
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [USUARIO_LOAD_PROFILE]: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      Api.v2.usuario
        .getPerfil()
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {}
export default {
  state,
  getters,
  actions,
  mutations
}
