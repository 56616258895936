import moment from 'moment'
import momentTime from 'moment-timezone'

const fusoMap = [
  'America/Noronha',
  'America/Belem',
  'America/Fortaleza',
  'America/Recife',
  'America/Araguaina',
  'America/Maceio',
  'America/Bahia',
  'America/Sao_Paulo',
  'America/Campo_Grande',
  'America/Cuiaba',
  'America/Santarem',
  'America/Porto_Velho',
  'America/Boa_Vista',
  'America/Manaus',
  'America/Eirunepe',
  'America/Rio_Branco'
]

export const formatDate = date => {
  return moment(date).format('DD/MM/YYYY')
}

export const formatDateTime = (date, fuso) => {
  const fusoAtual = fusoMap.filter(fusoTime => fusoTime == fuso).toString()
  return momentTime
    .utc(date)
    .tz(fusoAtual || 'America/Sao_Paulo')
    .format('DD/MM/YYYY HH:mm')
}

const currencyFormater = (value, round = 2) => {
  let currencyType = {
    currency: 'BRL',
    style: 'currency',
    minimumFractionDigits: round
  }
  return new Intl.NumberFormat('pt-BR', currencyType).format(value)
}

const toFixedTruncated = (ammount = 0, precision = 2) => {
  const numberOfPositions = 2
  const truncatePrecision = Number(precision) + numberOfPositions
  const val = Number(ammount).toFixed(truncatePrecision)
  return val.slice(0, val.length - numberOfPositions)
}

export const formatMoney = (value, round = 2) => {
  if (value === null || value === '') return value

  if (Number.isNaN(Number(value))) {
    return value
  }
  value = Number(value) || 0
  const decPlaces = isNaN((round = Math.abs(round))) ? 2 : round
  const sign = value < 0 ? '-' : ''
  const val = toFixedTruncated(value, decPlaces)
  return sign + currencyFormater(val, round)
}

export const formatPrecision = (value, round = 2) => {
  if (!value) return ''
  const _val = Number(value).toFixed(round)
  return isNaN(_val) ? value : _val
}
