<template>
  <v-btn
    :block="full"
    :color="outlined ? color : type"
    :rounded="rounded"
    :outlined="outlined"
    :type="submit ? 'submit' : 'button'"
    :text="flat"
    :icon="icon"
    :disabled="disabled"
    @click="onClick"
    class="formButton"
    :to="to"
    :loading="loading"
  >
    <v-icon v-if="beforeIcon" size="12px" style="margin-right:5px;">{{
      beforeIcon
    }}</v-icon>
    <span v-if="text">{{ text }}</span>
    <slot></slot>
    <v-icon v-if="afterIcon" size="12px" style="margin-left:5px;">{{
      afterIcon
    }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'FormButton',
  props: {
    text: {
      type: String,
      default: '',
      required: false
    },
    submit: {
      type: Boolean,
      default: false,
      required: false
    },
    full: {
      type: Boolean,
      default: false,
      required: false
    },
    flat: {
      type: Boolean,
      default: false,
      required: false
    },
    outlined: {
      type: Boolean,
      default: false,
      required: false
    },
    rounded: {
      type: Boolean,
      default: true,
      required: false
    },
    icon: {
      type: Boolean,
      default: false,
      required: false
    },
    type: {
      type: String,
      default: 'primario',
      required: false
    },
    color: {
      type: String,
      default: '',
      required: false
    },
    beforeIcon: {
      type: String,
      default: '',
      required: false
    },
    afterIcon: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      requited: false
    },
    to: {
      type: String,
      default: undefined,
      requited: false
    },
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  methods: {
    onClick(e) {
      this.$emit('click', e)
    }
  }
}
</script>
