import {
  MARKETING_LOAD_TEMPLATES,
  MARKETING_LOAD_TEMPLATES_SELECTED,
  MARKETING_UPDATE_TEMPLATES_SELECTED,
  MARKETING_EMAIL_CONFIG,
  MAKRETING_LOAD_TEMPLATE_ELASTIC
} from '../actions/marketing'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'
import * as _ from 'lodash'

const state = { config: null, template_elastic: [] }
const getters = {
  getEmailService: state => _.get(state, 'config.service', 'ELASTIC_MAIL'),
  getEmailConfig: state => state.config,
  getTemplateElastic: state => state.template_elastic
}
const actions = {
  [MARKETING_LOAD_TEMPLATES]: ({ dispatch }) => {
    return new Promise((resolve, reject) => {
      Api.v2.marketing.email
        .getListaTemplate()
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [MARKETING_LOAD_TEMPLATES_SELECTED]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.marketing.email
        .getTemplatesEscolhidos(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [MARKETING_UPDATE_TEMPLATES_SELECTED]: (
    { dispatch, getters },
    selecaoTemplates
  ) => {
    return new Promise((resolve, reject) => {
      Api.v2.marketing.email
        .saveTemplatesEscolhidos(getters.getFornecedorId, selecaoTemplates)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [MARKETING_EMAIL_CONFIG]: ({ dispatch, commit, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.marketing.email
        .getConfig(getters.getFornecedorId)
        .then(resp => {
          commit(MARKETING_EMAIL_CONFIG, resp.data)
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [MAKRETING_LOAD_TEMPLATE_ELASTIC]: ({ dispatch, commit, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.marketing.email
        .getTemplates(getters.getFornecedorId)
        .then(resp => {
          commit(
            MAKRETING_LOAD_TEMPLATE_ELASTIC,
            _.get(resp, 'data.templates', [])
          )
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}
const mutations = {
  [MARKETING_EMAIL_CONFIG]: (state, config) => {
    state.email_config = config
  },
  [MAKRETING_LOAD_TEMPLATE_ELASTIC]: (state, templates) => {
    state.template_elastic = templates || []
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
