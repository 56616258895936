export const CALENDARIO_CREATE = 'CALENDARIO_CREATE'
export const CALENDARIO_UPDATE = 'CALENDARIO_UPDATE'
export const CALENDARIO_LOAD = 'CALENDARIO_LOAD'
export const CALENDARIO_REMOVE = 'CALENDARIO_REMOVE'
export const DIAS = {
  Domingo: 0,
  'Segunda-feira': 1,
  'Terca-feira': 2,
  'Quarta-feira': 3,
  'Quinta-feira': 4,
  'Sexta-feira': 5,
  Sábado: 6
}
export const MESES = {
  Janeiro: 0,
  Fevereiro: 1,
  Março: 2,
  Abril: 3,
  Maio: 4,
  Junho: 5,
  Julho: 6,
  Agosto: 7,
  Setembro: 8,
  Outubro: 9,
  Novembro: 10,
  Dezembro: 11
}
export const MESESEMPORTUGUES = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro'
]
export const DIASSEMANAEMPORTUGUES = ['Do', 'Se', 'Te', 'Qu', 'Qu', 'Se', 'Sa']
