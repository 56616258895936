export const TEMA_LOAD = 'TEMA_LOAD'
export const TEMA_LOAD_MENU = 'TEMA_LOAD_MENU'
export const TEMA_UPDATE_MENU = 'TEMA_UPDATE_MENU'
export const TEMA_RESTAURAR_MENU = 'TEMA_RESTAURAR_MENU'
export const TEMA_LOAD_STATIC = 'TEMA_LOAD_STATIC'
export const TEMA_UPLOAD_IMAGE = 'TEMA_UPLOAD_IMAGE'
export const TEMA_UPDATE = 'TEMA_UPDATE'
export const TAGS_CARD = [
  { tag: '{{ nome }}', text: 'Nome do produto' },
  { tag: '{{ codigo_barras }}', text: 'Código de barras' },
  { tag: '{{ codigo_interno }}', text: 'Código do produto no ERP' },
  { tag: '{{ descricao_embalagem }}', text: 'Descrição da embalagem' },
  { tag: '{{ agrupador_embalagem }}', text: 'Código agrupador de embalagem' },
  { tag: '{{ multiplo }}', text: 'Múltiplos do produto' },
  {
    tag: '{{ descricao_auto_multiplo }}',
    text: 'Descrição automática de múltiplos'
  },
  { tag: '{{ unidade_venda_maior }}', text: 'Unidade de venda' },
  { tag: '{{ unidade_venda_menor }}', text: 'Unidade de venda múltiplos' },
  { tag: '{{ granularidade }}', text: 'Granularidade do produto' },
  { tag: '{{ max_por_pedido }}', text: 'Quantidade máxima por pedido' },
  {
    tag: '{{ mensagem_auto_maximo }}',
    text: 'Informação automática de máximo por pedido'
  },
  { tag: '{{ preco_atacado }}', text: 'Preço de atacado' },
  { tag: '{{ preco_varejo }}', text: 'Preço de varejo' },
  {
    tag: '{{ preco_unitario }}',
    text: 'Preço unitário (somente para produtos com múltiplo)'
  },
  {
    tag: '{{ max_disponivel_venda_geral }}',
    text: 'Máximo disponível para venda geral'
  },
  {
    tag: '{{ mensagem_auto_max_disponivel_venda_geral }}',
    text: 'Informação automática de máximo por pedido'
  },
  {
    tag: '{{ quantidade_subitem_embalagem }}',
    text: 'Quantidade de subitem do produto (informativo)'
  },
  {
    tag: '{{ preco_subitem_embalagem }}',
    text: 'Preço aproximado do subitem (preço / quantidade de subitem)'
  },
  {
    tag: '{{ preco_subitem_embalagem_fixo }}',
    text:
      'Preço aproximado do subitem (preço / quantidade de subitem), exibe mesmo que a quantidade do subitem seja = 1'
  },
  {
    tag: '{{ mensagem_auto_preco_quant_item_embalagem }}',
    text: 'Apresentaçao do preço do subitem gerada automaticamente'
  },
  {
    tag: '{{ caracteristica_nome_interno_nome }}',
    text:
      'Apresenta o nome da característica do produto (troque "nome_interno" pelo nome interno da característica desejada)'
  },
  {
    tag: '{{ caracteristica_nome_interno }}',
    text:
      'Apresenta o valor da característica do produto (troque "nome_interno" pelo nome interno da característica desejada)'
  },
  {
    tag: '{{ politica_vigencia_inicial }}',
    text: 'Apresenta a data de vigência inicial da politica aplicada'
  },
  {
    tag: '{{ politica_vigencia_final }}',
    text: 'Apresenta a data de vigência final da politica aplicada'
  },
  {
    tag: '{{ maximo_parcelas }}',
    text: 'Máximo de parcelas da forma de pagamento selecionada'
  },
  {
    tag: '{{ preco_parcela_menor }}',
    text: 'Menor parcela possível na forma de pagamento selecionada'
  },
  {
    tag: '{{ mensagem_auto_parcelamento }}',
    text:
      'Mensagem automática informando o parcelamento com o menor valor de parcela'
  },
  { tag: '{{ quebra_de_linha }}', text: 'Quebra de linha' }
]
export const TEMA_CARDS = [
  { text: 'Exibir atacado', value: 'ATACADO' },
  { text: 'Exibir política de desconto', value: 'POLITICA_DESCONTO' },
  { text: 'Exibir precificação variável', value: 'PRECIFICACAO_VARIAVEL' },
  { text: 'Exibir produto indisponível', value: 'INDISPONIVEL' },
  { text: 'Exibir produto sob demanda', value: 'SOB_DEMANDA' }
]
export const TEMA_NUMBERS = [
  { text: '1', value: '1' },
  { text: '2', value: '2' },
  { text: '3', value: '3' },
  { text: '4', value: '4' },
  { text: '5', value: '5' },
  { text: '6', value: '6' },
  { text: '7', value: '7' },
  { text: '8', value: '8' },
  { text: '9', value: '9' },
  { text: '10', value: '10' }
]
export const TEMA_TEMPLATE = {
  nome: 'Café 3 Corações',
  codigo_barras: '7765828272',
  codigo_interno: '7765828272|00991',
  descricao_embalagem: 'embalagem de 300g',
  multiplo: 3,
  descricao_auto_multiplo: 'CX com 3 UN',
  agrupador_embalagem: '(agrup 2200033)',
  unidade_venda_maior: 'CX',
  unidade_venda_menor: 'UN',
  granularidade: 0.3,
  max_por_pedido: 10,
  preco_atacado: '3,123456',
  preco_varejo: '5,123456',
  preco_unitario: '1,123456',
  preco_atacado_padrao: '3,123456',
  preco_varejo_padrao: '6,123456',
  preco_unitario_padrao: '1,123456',
  mensagem_auto_maximo: 'Máximo: 10 CX',
  quantidade_subitem_embalagem: '5',
  preco_subitem_embalagem: '1',
  preco_subitem_embalagem_fixo: '2',
  max_disponivel_venda_geral: '151',
  mensagem_auto_preco_quant_item_embalagem: 'Quantidade disponível: 151 CX',
  desconto_cupom: '10%',
  codigo_desconto: '4bf0c7f3b227',
  caracteristica_nome_interno_nome: 'Sabor',
  caracteristica_nome_interno: 'Extra forte',
  maximo_parcelas: '3',
  preco_parcela_menor: '1,20',
  mensagem_auto_parcelamento: 'em até 3 vezes de R$ 1,20',
  quebra_de_linha: '<br>'
}
