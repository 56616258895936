import {
  CARROSSEL_LIST,
  CARROSSEL_CREATE,
  CARROSSEL_DELETE,
  CARROSSEL_UPDATE
} from '../actions/carrossel'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const actions = {
  [CARROSSEL_LIST]: ({ dispatch, getters }, tipoConteudo) => {
    return new Promise((resolve, reject) => {
      Api.v2.customizacao.carrossel
        .listCarrossel(getters.getFornecedorId, tipoConteudo)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [CARROSSEL_CREATE]: ({ dispatch, getters }, payload) => {
    CARROSSEL_DELETE
    return new Promise((resolve, reject) => {
      Api.v2.customizacao.carrossel
        .createCarrossel(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [CARROSSEL_DELETE]: ({ dispatch, getters }, idcarrossel) => {
    return new Promise((resolve, reject) => {
      Api.v2.customizacao.carrossel
        .deleteCarrossel(getters.getFornecedorId, idcarrossel)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [CARROSSEL_UPDATE]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.customizacao.carrossel
        .updateCarrossel(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

export default {
  actions
}
