import {
  FORMA_ENTREGA_CREATE,
  FORMA_ENTREGA_UPDATE,
  FORMA_ENTREGA_LOAD,
  FORMA_ENTREGA_REMOVE,
  FORMA_ENTREGA_CALENDARIO_LIST,
  FORMA_ENTREGA_FORNECEDOR,
  FORMA_ENTREGA_FORMAS_LIST,
  FORMA_ENTREGA_SIMULAR,
  FORMA_ENTREGA_LIST
} from '../actions/formasEntrega'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [FORMA_ENTREGA_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .saveFormaEntrega(getters.getFornecedorId, payload, true)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [FORMA_ENTREGA_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .saveFormaEntrega(getters.getFornecedorId, payload, false)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [FORMA_ENTREGA_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .removeFormasEntrega(
          getters.getFornecedorId,
          payload.idformaentrega,
          payload.tipo
        )
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [FORMA_ENTREGA_LOAD]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .get(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [FORMA_ENTREGA_CALENDARIO_LIST]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .getCalendarios(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [FORMA_ENTREGA_FORNECEDOR]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .getDadosFornecedor(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [FORMA_ENTREGA_FORMAS_LIST]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .getDetalhesCustoTransporte(payload.idformaentrega, payload.body)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [FORMA_ENTREGA_SIMULAR]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .simular(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          if (err.response && err.response.data) {
            reject(err.response.data)
          } else {
            reject(err)
          }
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [FORMA_ENTREGA_LIST]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.formaEntrega
        .getListaFormasEntrega(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
