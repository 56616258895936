import {
  CARACTERISTICA_PRODUTO_CREATE,
  CARACTERISTICA_PRODUTO_DELETE,
  CARACTERISTICA_PRODUTO_LOAD,
  CARACTERISTICA_PRODUTO_UPDATE,
  CARACTERISTICA_PRODUTO_LIST
} from '../actions/caracteristicaProduto'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = { list: [] }

const getters = {
  getListCaracteristicas: state => state.list || []
}

const actions = {
  [CARACTERISTICA_PRODUTO_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto.caracteristic
        .adiciona(getters.getFornecedorId, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CARACTERISTICA_PRODUTO_DELETE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto.caracteristic
        .remove(getters.getFornecedorId, payload)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CARACTERISTICA_PRODUTO_LOAD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto.caracteristic
        .get(getters.getFornecedorId, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CARACTERISTICA_PRODUTO_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto.caracteristic
        .atualiza(getters.getFornecedorId, payload.idcaracteristica, payload)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [CARACTERISTICA_PRODUTO_LIST]: ({ dispatch, commit, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto.caracteristic
        .list(getters.getFornecedorId)
        .then(resp => {
          commit(CARACTERISTICA_PRODUTO_LIST, resp.data)
          resolve(resp)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {
  [CARACTERISTICA_PRODUTO_LIST]: (state, payload) => {
    state.list = payload || []
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
