<template>
  <div>
    <v-text-field
      :key="formKey"
      v-if="type == 'mask'"
      v-model="model"
      v-mask="mask"
      :placeholder="placeholder || ''"
      :error-messages="errors"
      :disabled="disabled"
      :readonly="readonly"
      :dark="darkEnabled"
      :dense="dense"
      :label="label"
      :solo="solo"
      :clearable="clearable"
      outlined
      rounded
      flat
      :hint="hint"
      :counter="count"
      :maxLength="maxLength"
      @input="onChange"
      @blur="onBlur"
      :persistent-hint="persistenthint"
      ><template v-slot:append>
        <slot name="append">
          <v-tooltip top v-if="help">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">far fa-question-circle</v-icon>
            </template>
            {{ help }}
          </v-tooltip>
        </slot>
      </template></v-text-field
    >
    <v-text-field
      :key="formKey"
      v-if="type == 'money'"
      v-model="model"
      v-money="money"
      :placeholder="placeholder || ''"
      :error-messages="errors"
      :disabled="disabled"
      :readonly="readonly"
      :dark="darkEnabled"
      :dense="dense"
      :label="label"
      :solo="solo"
      :clearable="clearable"
      :maxLength="maxLength"
      outlined
      rounded
      flat
      :hint="hint"
      :counter="count"
      @input="onChange"
      @blur="onBlur"
      ><template v-slot:append>
        <slot name="append">
          <v-tooltip top v-if="help">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">far fa-question-circle</v-icon>
            </template>
            {{ help }}
          </v-tooltip>
        </slot>
      </template></v-text-field
    >
    <v-text-field
      :key="formKey"
      v-if="type == 'default'"
      v-model="model"
      :type="typeField || 'string'"
      :placeholder="placeholder || ''"
      :error-messages="errors"
      :disabled="disabled"
      :readonly="readonly"
      :dark="darkEnabled"
      :dense="dense"
      :label="label"
      :solo="solo"
      :clearable="clearable"
      :maxLength="maxLength"
      outlined
      rounded
      flat
      :hint="hint"
      :counter="count"
      @input="onChange"
      @blur="onBlur"
      :persistent-hint="persistenthint"
      :step="step"
      :max="maxField"
      :min="minField"
      ><template v-slot:append>
        <slot name="append">
          <v-tooltip top :max-width="helpMaxWidth" v-if="help">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">far fa-question-circle</v-icon>
            </template>
            <span v-html="help"></span>
          </v-tooltip>
        </slot>
      </template>
    </v-text-field>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import FormMixin from './FormMixin'
export default {
  name: 'FormField',
  props: {
    label: {
      type: String,
      default: '',
      required: false
    },
    value: {
      type: [String, Number],
      default: '',
      required: false
    },
    persistenthint: {
      type: Boolean,
      default: false,
      required: false
    },
    placeholder: {
      type: String,
      default: '',
      required: false
    },
    dense: {
      type: Boolean,
      default: true,
      required: false
    },
    solo: {
      type: Boolean,
      default: false,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    mask: {
      type: [String, Array],
      default: '',
      required: false
    },
    maxLength: {
      type: String,
      default: '',
      required: false
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false
    },
    errorMessage: {
      type: [Object],
      default: () => ({}),
      required: false
    },
    validation: {
      type: Object,
      default: () => null,
      required: false
    },
    clearable: {
      type: Boolean,
      default: false,
      required: false
    },
    hint: {
      type: String,
      default: '',
      required: false
    },
    help: {
      type: String,
      default: '',
      required: false
    },
    helpMaxWidth: {
      type: String,
      default: '100%',
      required: false
    },
    counter: {
      type: Boolean,
      default: false,
      required: false
    },
    money: {
      type: Object,
      default: () => null,
      required: false
    },
    typeField: {
      type: String,
      required: false
    },
    maxField: {
      type: String,
      required: false
    },
    minField: {
      type: String,
      required: false
    },
    step: {
      type: String,
      required: false
    },
    personalizedMessage: {
      type: String,
      required: false
    }
  },
  directives: {
    mask,
    money: VMoney
  },
  mixins: [FormMixin],
  data: () => ({
    visibled: false,
    model: '',
    touched: false,
    formKey: Math.random() * 10000
  }),
  created() {
    this.model = this.value
  },
  watch: {
    value: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal != oldVal && newVal != this.model) {
          this.model = newVal
          if (this.type == 'money') {
            this.formKey = Math.random() * 10000
          }
        }
      }
    }
  },
  computed: {
    type() {
      if (this.mask) {
        return 'mask'
      } else if (this.money) {
        return 'money'
      } else {
        return 'default'
      }
    },
    errors() {
      if (this.validation && (this.validation.$dirty || this.touched)) {
        let errorsMess = []
        if (
          (!this.validation.customRequired && this.validation.$error) ||
          (!this.validation.required && this.validation.$error)
        ) {
          errorsMess.push(
            this.errorMessage['required'] || this.personalizedMessage
              ? this.personalizedMessage
              : 'Campo obrigatório'
          )
        }
        return errorsMess
      }
      return []
    }
  }
}
</script>

<style lang="scss">
@media print {
  .v-text-field--rounded.v-text-field--outlined fieldset {
    border: none;
  }
  .v-input__icon.v-input__icon--append {
    display: none;
  }
}
</style>
