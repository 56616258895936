import {
  OBTEM_PEDIDO_SIMPLES,
  BUSCAR_STATUS_PEDIDO,
  SET_STATUS_PEDIDO,
  LIMPAR_ID_PEDIDO_ERP,
  SOFT_DELETE_PEDIDO,
  DESABILITAR_MIX,
  REMOVE_PRODUCTS,
  REMOVE_IMAGE_HISTORY,
  REMOVER_POLITICAS,
  REMOVER_CLIENTES,
  SUBMIT_TAGS,
  UPDATE_PAGE_AGE_CONTROL
} from '../actions/statusPedidos'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const actions = {
  [OBTEM_PEDIDO_SIMPLES]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.pedido
        .obtemPedidoSimples(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [BUSCAR_STATUS_PEDIDO]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.parametros
        .getStatusPedido(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [SET_STATUS_PEDIDO]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.pedido
        .setStatusPedido(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [LIMPAR_ID_PEDIDO_ERP]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.pedido
        .limparIdPedidoERP(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [SOFT_DELETE_PEDIDO]: ({ dispatch, getters }, numpedido) => {
    return new Promise((resolve, reject) => {
      Api.v4.manutencao
        .softDeletePedido({
          idfornecedor: getters.getFornecedorId,
          idusuario: getters.getUsuarioId,
          numpedido: numpedido
        })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [DESABILITAR_MIX]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.cargapreco
        .desabilitarMix(getters.getFornecedorId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [REMOVE_PRODUCTS]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.manutencao
        .removeProducts(getters.getFornecedorId, getters.getUsuarioId)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [REMOVE_IMAGE_HISTORY]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.maintenance
        .removeImageImportHistory(
          getters.getFornecedorId,
          getters.getUsuario.idusuario
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [REMOVER_POLITICAS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.maintenance
        .removerPoliticas(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [REMOVER_CLIENTES]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.maintenance
        .removerClientes(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [SUBMIT_TAGS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.params
        .submitTags(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },

  [UPDATE_PAGE_AGE_CONTROL]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.fornecedor
        .updatePageAgeControl(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

export default {
  actions
}
