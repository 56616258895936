import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../pages/Login'
import customizacao from './routes/customizacao'
import { ifAuthenticated, ifNotAuthenticated, ifUserHasAccess } from './guards'

Vue.use(VueRouter)

let routes = [
  {
    path: '/verify/:idfornecedor/:idtokenconfirmacao',
    name: 'Verificar',
    component: () => import('@/pages/cadastro/verificacao')
  },
  {
    path: '/boleto/:idBoleto',
    name: 'Boleto',
    component: () => import('@/pages/Boleto')
  },
  {
    path: '/fornecedor/novidades',
    name: 'Novidades',
    beforeEnter: ifAuthenticated,
    component: () => import('@/pages/novidades/Novidades'),
    meta: {
      header: {
        title: 'NOVIDADES DO PORTAL',
        subtitle:
          'Aqui você pode conferir as novidades liberadas para seu e-commerce.',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Novidades',
            route: '/fornecedor/novidades'
          }
        ]
      }
    }
  },
  {
    path: '/',
    name: 'home',
    redirect: '/fornecedor/inicio',
    beforeEnter: ifAuthenticated
  },
  {
    path: '/fornecedor/comercial/cupom-desconto',
    name: 'CupomDesconto',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/comercial/cupom/CupomDesconto'),
    meta: {
      header: {
        title: 'CUPOM DE DESCONTO',
        subtitle: 'Use esta área para configurar os cupons de desconto',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Cupons de desconto',
            route: '/fornecedor/comercial/cupom-desconto'
          }
        ]
      }
    },
    children: [
      {
        path: 'config/recomendacao',
        name: 'CupomRecomendacaoForm',
        beforeEnter: ifAuthenticated,
        component: () => import('@/pages/comercial/cupom/CupomRecomendacaoForm')
      },
      {
        path: 'novo',
        name: 'CupomDescontoForm',
        beforeEnter: ifAuthenticated,
        component: () => import('@/pages/comercial/cupom/CupomDescontoForm')
      },
      {
        path: ':id',
        name: 'CupomDescontoDetalhes',
        beforeEnter: ifAuthenticated,
        component: () => import('@/pages/comercial/cupom/CupomDescontoDetalhes')
      }
    ]
  },
  {
    path: '/fornecedor/comercial/politicas-desconto',
    name: 'Politica de desconto',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/comercial/politica-desconto/PoliticaDesconto'),
    meta: {
      header: {
        title: 'POLITICA DE DESCONTO',
        subtitle:
          'Use esta área para cadastrar ou editar regras para descontos',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Politíca de desconto',
            route: '/fornecedor/comercial/politicas-desconto'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'PoliticaDescontoForm',
        beforeEnter: ifAuthenticated,
        component: () =>
          import('@/pages/comercial/politica-desconto/PoliticaDescontoForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/gateway',
    name: 'Gateway',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/gateway/Gateway'),
    meta: {
      header: {
        title: 'CADASTRO DE GATEWAYS',
        subtitle:
          'Nesta área você irá cadastrar/configurar os gateways utilizados para a realização de pagamento por cartão.',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Gateway de pagamento',
            route: '/fornecedor/cadastro/gateway'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'GatewayForm',
        beforeEnter: ifAuthenticated,
        component: () => import('@/pages/cadastro/gateway/GatewayForm')
      }
    ]
  },
  {
    path: '/fornecedor/comercial/lista-compras',
    name: 'ListaCompras',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/comercial/lista-compras/ListaCompras'),
    meta: {
      header: {
        title: 'CADASTRO DE LISTA DE COMPRAS',
        subtitle:
          'Nesta área você irá cadastrar/configurar as listas de compra utilizados para a realização de pagamento por cartão.',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Lista de compras',
            route: '/fornecedor/comercial/lista-compras'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'ListaComprasForm',
        beforeEnter: ifAuthenticated,
        component: () =>
          import('@/pages/comercial/lista-compras/ListaComprasForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/cliente',
    name: 'Cliente',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/cliente/Cliente'),
    meta: {
      header: {
        title: 'CADASTRO DE CLIENTE',
        subtitle: 'Use esta área para criar e gerenciar clientes',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Cliente',
            route: '/fornecedor/cadastro/cliente'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'ClienteForm',
        beforeEnter: ifAuthenticated,
        component: () => import('@/pages/cadastro/cliente/ClienteForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/cliente/:idclient/lista/endereco',
    name: 'ClienteEndereco',
    beforeEnter: ifAuthenticated,
    component: () =>
      import('@/pages/cadastro/cliente/endereco/ClienteEnderecos'),
    meta: {
      header: {
        title: 'ENDEREÇOS DO CLIENTE',
        subtitle:
          'Use esta área para criar e gerenciar os endereços do cliente',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Endereço',
            route: '/fornecedor/cadastro/cliente/'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'ClienteEnderecoForm',
        component: () =>
          import('@/pages/cadastro/cliente/endereco/ClienteEnderecoForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/cliente/:idclient/lista/usuario',
    name: 'ClienteUsuario',
    beforeEnter: ifAuthenticated,
    component: () => import('@/pages/cadastro/cliente/usuario/ClienteUsuarios'),
    meta: {
      header: {
        title: 'GESTÃO DE USUÁRIOS',
        subtitle: 'Use esta área para criar e gerenciar os usuários do cliente',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Gestão de usuários',
            route: '/fornecedor/cadastro/cliente/'
          }
        ]
      }
    },
    children: [
      {
        path: ':id/:idcadastroextracliente/:idfornecedor',
        name: 'ClienteUsuarioForm',
        component: () =>
          import('@/pages/cadastro/cliente/usuario/ClienteUsuarioForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/relatorios',
    name: 'Relatorios',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/relatorios/Relatorios'),
    meta: {
      header: {
        title: 'Relatórios',
        subtitle: 'Use essa área para gerenciar os relatórios.',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Relatórios',
            route: '/fornecedor/cadastro/relatorios'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/configuracao/mensagens-automaticas',
    name: 'Mensagens automáticas',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/configuracoes/mensagens-automaticas'),
    meta: {
      header: {
        title: 'MENSAGENS AUTOMÁTICAS',
        subtitle:
          'Use essa área para editar as mensagens enviadas pela plataforma ao cliente',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Mensagens automáticas',
            route: '/fornecedor/configuracao/mensagens-automaticas'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/cadastro/caracteristica-produto',
    name: 'Características de produto',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/produto/caracteristicaProduto'),
    meta: {
      header: {
        title: 'Características de produto',
        subtitle:
          'Use essa área para criar e editar atributos que definem as caracteristicas de produto',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Características de produto',
            route: '/fornecedor/cadastro/caracteristica-produto'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'caracteristicaProdutoForm',
        component: () =>
          import('@/pages/cadastro/produto/caracteristicaProdutoForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/caracteristica-cliente',
    name: 'Características de cliente',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/cadastro/cliente/caracteristica/caracteristicaCliente'),
    meta: {
      header: {
        title: 'Características de cliente',
        subtitle:
          'Use essa área para criar e editar atributos que definem as caracteristicas de cliente',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Características de cliente',
            route: '/fornecedor/cadastro/caracteristica-cliente'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'caracteristicaClienteForm',
        component: () =>
          import(
            '@/pages/cadastro/cliente/caracteristica/caracteristicaClienteForm'
          )
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/caracteristica-pedido',
    name: 'Características de pedido',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/cadastro/pedido/caracteristica/caracteristicaPedido'),
    meta: {
      header: {
        title: 'Características de pedido',
        subtitle:
          'Use essa área para criar e editar atributos que definem as caracteristicas de pedido',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Características de pedido',
            route: '/fornecedor/cadastro/caracteristica-pedido'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'caracteristicaPedidoForm',
        component: () =>
          import(
            '@/pages/cadastro/pedido/caracteristica/caracteristicaPedidoForm'
          )
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/produto',
    name: 'Produto',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/produto/Produto'),
    meta: {
      header: {
        title: 'Produtos',
        subtitle: 'Use esta área para gerenciar o cadastro de produtos',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Produtos',
            route: '/fornecedor/cadastro/produto'
          }
        ]
      }
    },
    children: [
      {
        path: ':idproduto/:idcadastroextraproduto',
        name: 'ProdutoForm',
        component: () => import('@/pages/cadastro/produto/ProdutoForm')
      },
      {
        path: 'similar/:idproduto/:idcadastroextraproduto',
        name: 'ProdutoSimilarModal',
        component: () => import('@/pages/cadastro/produto/ProdutoSimilarModal')
      },
      {
        path: ':idproduto',
        name: 'ProdutoFormId',
        component: () => import('@/pages/cadastro/produto/ProdutoForm')
      }
    ]
  },
  {
    path: '/fornecedor/marketing/link-acao',
    name: 'Link de ação',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/marketing/link-acao/LinkAcao'),
    meta: {
      header: {
        title: 'Link de ações',
        subtitle:
          'Use esta área para gerenciar links que executam ações na loja',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Link de ações',
            route: '/fornecedor/marketing/link-acao'
          }
        ]
      }
    },
    children: [
      {
        path: ':idlinkacao',
        name: 'LinkAcaoForm',
        component: () => import('@/pages/marketing/link-acao/LinkAcaoForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/tag-produto',
    name: 'TagProdutos',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/tagsprodutos/Tagsprodutos'),
    meta: {
      header: {
        title: 'TAGS DE PRODUTOS',
        subtitle:
          'Use esta área para para cadastra tags e agrupadores para produtos',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Tags de produtos',
            route: '/fornecedor/cadastro/tag-produto'
          }
        ]
      }
    },
    children: [
      {
        path: 'agrupador/:id',
        name: 'AgrupadorForm',
        component: () =>
          import('@/pages/cadastro/tagsprodutos/TagAgrupadorForm')
      },
      {
        path: 'tag/:id',
        name: 'TagsForm',
        component: () => import('@/pages/cadastro/tagsprodutos/TagPesquisaForm')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/sem-permissao',
    name: 'SemPermissao',
    component: () => import('@/pages/SemPermissao')
  },
  {
    path: '/oauth/callback/:service',
    name: 'OauthCallback',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/oauth/OauthCallback'),
    meta: {
      hidemenu: true
    }
  },
  {
    path: '/rota-desconhecida',
    name: 'Demo',
    beforeEnter: ifAuthenticated,
    component: () => import('@/pages/demo/Demo'),
    meta: {
      header: {
        title: 'Demonstração dos componentes',
        subtitle:
          'Nesta área temos uma demonstração de uso de cada componente de front-end da plataforma',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Demonstração dos componentes',
            route: '/rota-desconhecida'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/pagamentos',
    name: 'Pagamentos',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/atendimento/pagamento/Pagamentos'),
    meta: {
      header: {
        title: 'PAINEL DE PAGAMENTOS',
        subtitle:
          'Nesta área você pode visualizar e gerenciar os pagamentos recebidos.',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Pagamentos',
            route: '/fornecedor/fornecedor/pagamentos'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'PagamentoDetalhes',
        component: () =>
          import('@/pages/atendimento/pagamento/PagamentoDetalhes')
      }
    ]
  },
  {
    path: '/fornecedor/configuracao/envio-mensagens',
    name: 'Log de mensagens',
    component: () =>
      import('@/pages/configuracoes/mensagens-enviadas/mensagens-enviadas'),
    beforeEnter: ifUserHasAccess,
    meta: {
      header: {
        title: 'LOG DE MENSAGENS',
        subtitle:
          'Nesta área você pode visualizar mensagens enviadas pela plataforma para seus clientes.',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'LOG de mensagens',
            route: '/fornecedor/configuracao/envio-mensagens'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/inicio',
    name: 'Início',
    component: () => import('@/pages/Inicio'),
    beforeEnter: ifUserHasAccess,
    meta: {
      header: {
        title: 'Seja bem-vindo ao sistema omnichannel da Lifeapps',
        subtitle: 'Nesta área você pode visualizar as informações da sua loja'
      }
    }
  },
  {
    path: '/fornecedor/marketing/templates-email',
    name: 'Templates de e-mail',
    component: () =>
      import('@/pages/marketing/template-email/TemplateEmail.vue'),
    beforeEnter: ifUserHasAccess,
    meta: {
      header: {
        title: 'CONFIGURAÇÃO DE TEMPLATES DE E-MAIL',
        subtitle:
          'Configure aqui a associação de um template de e-mail com os eventos do LifeApps Omnichannel.',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Templates de e-mail',
            route: '/fornecedor/marketing/templates-email'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/central-atendimento',
    name: 'Central de Atendimentos',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/venda-mais/CentralAtendimento'),
    meta: {
      header: {
        title: 'Central de Atendimento',
        subtitle: 'Use essa área para acompanhar os atendimentos Venda+',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Central de Atendimento',
            route: '/fornecedor/central-atendimento'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/comercial/combos-promocionais',
    name: 'Combo promocional',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/comercial/combos-promocionais/ComboPromocional'),
    meta: {
      header: {
        title: 'COMBO PROMOCIONAL',
        subtitle: 'Use esta área para cadastrar ou editar combos promocionais',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Combo promocional',
            route: '/fornecedor/comercial/combos-promocionais'
          }
        ]
      }
    },
    children: [
      {
        path: ':id',
        name: 'ComboPromocionalForm',
        beforeEnter: ifAuthenticated,
        component: () =>
          import('@/pages/comercial/combos-promocionais/ComboPromocionalForm')
      }
    ]
  },
  {
    path: '/fornecedor/dashboard',
    name: 'Dashboard',
    component: () => import('@/pages/Dashboard'),
    beforeEnter: ifUserHasAccess,
    meta: {
      header: {
        title: 'Dashboard',
        subtitle:
          'Acompanhe o andamento do seu negócio na plataforma LifeApps Omnichannel',
        infoMessage:
          'As informações dessa página podem levar algumas horas para serem atualizadas',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Dashboard',
            route: '/fornecedor/Dashboard'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/cadastro/imagens-produto',
    name: 'Imagens de Produtos',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/imagemproduto/ImagemProduto'),
    meta: {
      header: {
        title: 'Imagens de Produtos',
        subtitle: 'Use essa área para gerenciar as imagens dos produtos',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Imagens de Produtos',
            route: '/fornecedor/cadastro/imagens-produto'
          }
        ]
      }
    },
    children: [
      {
        path: 'upload',
        name: 'ImagemProdutoForm',
        component: () =>
          import('@/pages/cadastro/imagemproduto/ImagemProdutoForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/setores-produto',
    name: 'Categorias',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/categoria/Categoria'),
    meta: {
      header: {
        title: 'Categorias',
        subtitle:
          'Use esta área para gerenciar o cadastro de categorias de produto',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Categorias',
            route: '/fornecedor/cadastro/setores-produto'
          }
        ]
      }
    },
    children: [
      {
        path: ':idsetor',
        name: 'CategoriaForm',
        component: () => import('@/pages/cadastro/categoria/CategoriaForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/pagina-estatica',
    name: 'PaginaEstatica',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/paginaestatica/PaginaEstatica'),
    meta: {
      header: {
        title: 'Páginas Estáticas',
        subtitle:
          'Use essa área para gerenciar as páginas estáticas da aplicação',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Páginas Estáticas',
            route: '/fornecedor/cadastro/pagina-estatica'
          }
        ]
      }
    },
    children: [
      {
        path: ':idconteudoestatico',
        name: 'PaginaEstaticaForm',
        component: () =>
          import('@/pages/cadastro/paginaestatica/PaginaEstaticaForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/restricoes-cliente',
    name: 'RestricaoCliente',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/cadastro/restricaocliente/RestricaoCliente'),
    meta: {
      header: {
        title: 'Restrições de Clientes',
        subtitle: 'Use essa área para gerenciar as restrições de venda',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Restrições de Clientes',
            route: '/fornecedor/cadastro/restricoes-cliente'
          }
        ]
      }
    },
    children: [
      {
        path: ':idrestricaocliente',
        name: 'RestricaoClienteForm',
        component: () =>
          import('@/pages/cadastro/restricaocliente/RestricaoClienteForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/integracao-carga-preco',
    name: 'CargaPreco',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/cargapreco/CargaPreco'),
    meta: {
      header: {
        title: 'Integração de Carga de Preço',
        subtitle: 'Os detalhes das últimas atualizações de preço',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Integração de Carga de Preço',
            route: '/fornecedor/cadastro/integracao-carga-preco'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/cadastro/integracao-carga-preco/:idprocessamento',
    name: 'CargaPrecoDetalhe',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/cargapreco/CargaPrecoDetalhe'),
    meta: {
      header: {
        title: 'Integração de Carga de Preço',
        subtitle: 'Os detalhes das últimas atualizações de preço',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Integração de Carga de Preço',
            route: '/fornecedor/cadastro/integracao-carga-preco'
          },
          {
            text: 'Detalhes',
            route:
              '/fornecedor/cadastro/integracao-carga-preco/:idprocessamento'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/cadastro/formas-entrega',
    name: 'FormaEntrega',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/formasentrega/FormaEntrega'),
    meta: {
      header: {
        title: 'Formas de Entrega',
        subtitle: 'Use essa área para gerenciar as formas de entrega',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Formas de Entrega',
            route: '/fornecedor/cadastro/formas-entrega'
          }
        ]
      }
    },
    children: [
      {
        path: ':idformaentrega',
        name: 'FormaEntregaForm',
        component: () =>
          import('@/pages/cadastro/formasentrega/FormaEntregaForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/tabela-de-precos',
    name: 'TabelaPreco',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/tabelapreco/TabelaPreco'),
    meta: {
      header: {
        title: 'Tabela de Preços',
        subtitle: 'Use esta área para gerenciar sua tabela de preços',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Tabela de Preços',
            route: '/fornecedor/cadastro/tabela-de-precos'
          }
        ]
      }
    },
    children: [
      {
        path: ':codigo_grupo_preco/:vinculo',
        name: 'TabelaPrecoForm',
        component: () => import('@/pages/cadastro/tabelapreco/TabelaPrecoForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/calendarios',
    name: 'Calendário',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/cadastro/calendario/Calendario'),
    meta: {
      header: {
        title: 'Calendário',
        subtitle: 'Aqui você consegue cadastrar calendários de entrega',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Calendário',
            route: '/fornecedor/cadastro/calendarios'
          }
        ]
      }
    },
    children: [
      {
        path: ':idcalendarioentrega',
        name: 'CalendarioForm',
        component: () => import('@/pages/cadastro/calendario/CalendarioForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/forma-pagamento',
    name: 'FormaDePagamento',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/cadastro/formadepagamento/FormaDePagamento'),
    meta: {
      header: {
        title: 'Forma de pagamento',
        subtitle:
          'Use esta área para criar e gerenciar suas formas de pagamento',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Forma de pagamento',
            route: '/fornecedor/cadastro/forma-pagamento'
          }
        ]
      }
    },
    children: [
      {
        path: 'novo',
        name: 'FormaDePagamentoModalForm',
        beforeEnter: ifAuthenticated,
        component: () =>
          import(
            '@/pages/cadastro/formadepagamento/forms/FormaDePagamentoModalForm'
          )
      },
      {
        path: ':idgateway/:idformapagamento',
        name: 'FormaDePagamentoModalForm',
        beforeEnter: ifAuthenticated,
        component: () =>
          import(
            '@/pages/cadastro/formadepagamento/forms/FormaDePagamentoModalForm'
          )
      }
    ]
  },
  {
    path: '/fornecedor/configuracao/dados-empresa',
    name: 'Dados da Empresa',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/configuracoes/dados-empresa/DadosEmpresa'),
    meta: {
      header: {
        title: 'Dados Básicos da Empresa',
        subtitle: 'Informações da sua empresa e seu representante',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Dados da Empresa',
            route: '/fornecedor/configuracao/dados-empresa'
          }
        ]
      }
    },
    children: []
  },
  {
    path: '/fornecedor/configuracao/integracoes',
    name: 'Integracoes',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/configuracoes/integracoes/integracoes'),
    meta: {
      header: {
        title: 'Integrações',
        subtitle:
          'Use esta área para gerenciar as integrações com a plataforma',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Integrações',
            route: '/fornecedor/configuracao/integracoes'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/configuracao/informacoes-cobranca',
    name: 'Informações de Cobrança',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/configuracoes/informacoes-cobranca/InformacoesCobranca'),
    meta: {
      header: {
        title: 'Informações de Cobrança',
        subtitle: 'Informações de cobrança da empresa',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Informações de Cobrança',
            route: '/fornecedor/configuracao/informacoes-cobranca'
          }
        ]
      }
    },
    children: []
  },
  {
    path: '/fornecedor/configuracao/parametros',
    name: 'Parâmetros',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/configuracoes/parametros/Parametros'),
    meta: {
      header: {
        title: 'Parâmetros',
        subtitle:
          'Use esta área para exibir os seus dados cadastrais e configurar a plataforma',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Parâmetros',
            route: '/fornecedor/configuracao/parametros'
          }
        ]
      }
    },
    children: []
  },
  {
    path: '/fornecedor/configuracoes/usuarios',
    name: 'Usuario',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/configuracoes/usuario/Usuario'),
    meta: {
      header: {
        title: 'Usuários',
        subtitle: 'Use esta área para gerenciar os usuários do portal',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Usuários',
            route: '/fornecedor/configuracoes/usuarios'
          }
        ]
      }
    },
    children: [
      {
        path: 'novo',
        name: 'UsuarioForm',
        component: () => import('@/pages/configuracoes/usuario/UsuarioForm')
      }
    ]
  },
  {
    path: '/fornecedor/configuracoes/usuarios/meu-usuario',
    name: 'MeuUsuario',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/configuracoes/usuario/MeuUsuario'),
    meta: {
      header: {
        title: 'Meu usuário',
        subtitle: 'Aqui você pode configurar o seu usuário',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Usuários',
            route: '/fornecedor/configuracoes/usuarios'
          },
          {
            text: 'Meu usuário',
            route: '/fornecedor/configuracoes/usuarios/meu-usuario'
          }
        ]
      }
    },
    children: []
  },
  {
    path: '/fornecedor/configuracao/manutencao-mix',
    name: 'Manutencao avancada',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/configuracoes/manutencao-avancada/manutencaoAvancada'),
    meta: {
      header: {
        title: 'MANUTENÇÃO ADMINISTRATIVA DO MIX DE PRODUTOS',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Manutenção de mix',
            route: '/fornecedor/configuracao/manutencao-mix'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/configuracao/tema',
    name: 'Tema',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/configuracoes/tema/Tema'),
    meta: {
      header: {
        title: 'Tema',
        subtitle: 'Use essa área para editar as cores e logo da sua loja',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Tema',
            route: '/fornecedor/configuracao/tema'
          }
        ]
      }
    },
    children: []
  },
  {
    path: '/fornecedor/configuracao/tags-adicionais',
    name: 'TagsAdicionais',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/configuracoes/tags-adicionais/TagsAdicionais'),
    meta: {
      header: {
        title: 'Tags adicionais',
        subtitle:
          'Use essa área para gerênciar as tags adicionais de javascript do site',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Tags adicionais',
            route: '/fornecedor/configuracao/tags-adicionais'
          }
        ]
      }
    },
    children: [
      {
        path: 'novo',
        name: 'TagsAdicionaisForm',
        beforeEnter: ifAuthenticated,
        component: () =>
          import(
            '@/pages/configuracoes/tags-adicionais/forms/TagsAdicionaisModalForm'
          )
      },
      {
        path: ':idfornecedor/:idtagcustomizada',
        name: 'TagsAdicionaisForm',
        beforeEnter: ifAuthenticated,
        component: () =>
          import(
            '@/pages/configuracoes/tags-adicionais/forms/TagsAdicionaisModalForm'
          )
      }
    ]
  },
  {
    path: '/fornecedor/comercial/assinaturas',
    name: 'Acompanhamento',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/assinatura/acompanhamento/Acompanhamento'),
    meta: {
      header: {
        title: 'Assinaturas',
        subtitle:
          'Use esta área para exibir e gerenciar as assinaturas de clientes',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Assinaturas',
            route: '/fornecedor/comercial/assinaturas'
          }
        ]
      }
    },
    children: [
      {
        path: ':idassinatura',
        name: 'AcompanhamentoForm',
        component: () =>
          import('@/pages/assinatura/acompanhamento/AcompanhamentoForm')
      }
    ]
  },
  {
    path: '/fornecedor/cadastro/tipos-assinaturas',
    name: 'Configuracao',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/assinatura/configuracao/Configuracao'),
    meta: {
      header: {
        title: 'Tipos de assinaturas',
        subtitle:
          'Use esta área para exibir e gerenciar os tipos de assinaturas de clientes',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Tipos de assinaturas',
            route: '/fornecedor/cadastro/tipos-assinaturas'
          }
        ]
      }
    },
    children: [
      {
        path: 'novo',
        name: 'ConfiguracaoForm',
        component: () =>
          import('@/pages/assinatura/configuracao/ConfiguracaoForm')
      }
    ]
  },
  {
    path: '/fornecedor/comercial/conjunto-produtos',
    name: 'Conjunto',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/comercial/conjunto/Conjunto'),
    meta: {
      header: {
        title: 'Conjuntos de Produtos',
        subtitle:
          'Use esta área para cadastrar e gerenciar os conjutos de produto',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Conjuntos',
            route: '/fornecedor/comercial/conjunto-produtos'
          }
        ]
      }
    },
    children: [
      {
        path: ':idproduto/:idcadastroextraproduto',
        name: 'ConjuntoForm',
        component: () => import('@/pages/comercial/conjunto/ConjuntoForm')
      },
      {
        path: ':idproduto',
        name: 'ConjuntoFormId',
        component: () => import('@/pages/comercial/conjunto/ConjuntoForm')
      }
    ]
  },
  {
    path: '/fornecedor/marketing/push-notification',
    name: 'NotificacaoPush',
    beforeEnter: ifUserHasAccess,
    component: () =>
      import('@/pages/marketing/notificacao-push/NotificacaoPush'),
    meta: {
      header: {
        title: 'Notificações para Clientes',
        subtitle:
          'As empresas com aplicativo próprio (Whitelabel) podem utilizar esse espaço para enviar notificações para seus clientes',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Notificações para Clientes',
            route: '/fornecedor/marketing/push-notification'
          }
        ]
      }
    },
    children: []
  },
  {
    path: '/fornecedor/faturas',
    name: 'Faturas',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/faturas/Faturas'),
    meta: {
      header: {
        title: 'Faturas',
        subtitle:
          'Veja os detalhes das faturas geradas e dos pedidos vinculados',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Faturas',
            route: '/fornecedor/faturas'
          }
        ]
      }
    }
  },
  {
    path: '/fornecedor/faturas/:idfatura',
    name: 'Faturas',
    beforeEnter: ifUserHasAccess,
    component: () => import('@/pages/faturas/FaturasPedido'),
    meta: {
      header: {
        title: 'Faturas',
        subtitle: 'Veja os pedidos vinculados à esta fatura',
        breadcrumb: [
          { text: 'Início', route: '/' },
          {
            text: 'Faturas',
            route: '/fornecedor/faturas'
          },
          {
            text: 'Detalhe Fatura'
          }
        ]
      }
    }
  },
  {
    path: '*',
    name: 'Legacy',
    beforeEnter(to) {
      window.location = process.env.VUE_APP_LEGACY_URL
        ? `${process.env.VUE_APP_LEGACY_URL}${to.fullPath}`
        : to.fullPath
    }
  }
]
routes = routes.concat(customizacao)

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
