import Axios from 'axios'
import Cookies from 'js-cookie'

let baseApiURL = process.env.VUE_APP_LEGACY_URL || ''
baseApiURL = baseApiURL.replace(/\/$/, '') + '/'

const _host = `${process.env.VUE_APP_LEGACY_URL}`.replace(
  /(http(s)?:)(\/\/)/,
  ''
)
const useHeader =
  baseApiURL != '/' && window.location.host != _host ? true : false
const api = Axios.create({
  baseURL: baseApiURL,
  withCredentials: false
})

api.interceptors.request.use(
  function(config) {
    config.headers['x-accept'] = 'application/json'
    config.headers['Access-Control-Allow-Origin'] = '*'
    if (useHeader) {
      config.headers['authorization'] = Cookies.get('authorization') || ''
      config.headers['authorization1'] = Cookies.get('authorization1') || ''
      config.headers['authorization2'] = Cookies.get('authorization2') || ''
      config.headers['authorization3'] = Cookies.get('authorization3') || ''
    }
    let idfornecedor = Cookies.get('ID_FORNECEDOR')
    if (idfornecedor) {
      config.headers['ID_FORNECEDOR'] = idfornecedor
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

export const v1 = () => {
  const baseURL = baseApiURL + 'api/v1' || 'api/v1'
  const mount = (...args) => {
    /* eslint-disable */
    if (!args) throw new Error('Invalid resource mounted: was null')
    let urlMounted = ''
    Array.apply(null, args).forEach(function(part) {
      urlMounted += '/' + part
    })
    return urlMounted.replace(/(\/){2,}/g, '/') //cleanup slashed, just in case
  }
  const axiosInstance = Axios.create({
    baseURL: baseURL,
    withCredentials: false
  })
  axiosInstance.interceptors.request.use(
    function(config) {
      config.headers['x-accept'] = 'application/json'
      config.headers['Access-Control-Allow-Origin'] = '*'
      if (useHeader) {
        config.headers['authorization'] = Cookies.get('authorization') || ''
        config.headers['authorization1'] = Cookies.get('authorization1') || ''
        config.headers['authorization2'] = Cookies.get('authorization2') || ''
        config.headers['authorization3'] = Cookies.get('authorization3') || ''
      }
      let idfornecedor = Cookies.get('ID_FORNECEDOR')
      if (idfornecedor) {
        config.headers['ID_FORNECEDOR'] = idfornecedor
      }
      return config
    },
    function(error) {
      return Promise.reject(error)
    }
  )
  const root = {}
  const get = axiosInstance.get
  const post = (resource, data) => {
    return axiosInstance.post(resource, data)
  }

  const put = function(resource, data) {
    return axiosInstance.put(resource, data)
  }
  const del = function(resource) {
    return axiosInstance.delete(resource)
  }

  root.usuario = {
    alterarNomeUsuario: function(idfornecedor, idusuario, nome) {
      return put(
        mount('/usuarios/usuario/nome') + `?idfornecedor=${idfornecedor}`,
        { idusuario, nome }
      )
    },
    obtemQuantidadeMensagensChat: function(
      idusuario,
      idfornecedor,
      successHandler,
      failCallback
    ) {
      return get(
        mount('/usuarios/', idusuario, '/', idfornecedor, '/chatcountmessages'),
        successHandler,
        failCallback
      )
    },
    removerVinculoUsuario: function(
      idusuario,
      idfornecedor,
      idcadastroextraproduto,
      successHandler,
      failCallback
    ) {
      return del(
        mount(
          '/app/usuario/',
          idcadastroextraproduto,
          '/',
          idusuario,
          '/',
          idfornecedor
        ),
        successHandler,
        failCallback
      )
    },
    criarVinculoUsuario: function(email, idcadastroextracliente, idfornecedor) {
      return post(
        mount('/app/usuario/', idcadastroextracliente, '/', idfornecedor),
        { email }
      )
    },
    desativarUsuario: function(idfornecedor, data) {
      return put(mount('/usuarios/usuario?idfornecedor=', idfornecedor), data)
    },
    reativarUsuario: function(idfornecedor, data) {
      return put(mount('/usuarios/usuario?idfornecedor=', idfornecedor), data)
    },
    atualizarUsuario: function(idfornecedor, data) {
      return put(mount('/usuarios/usuario?idfornecedor=', idfornecedor), data)
    },
    criarUsuario: function(idfornecedor, data) {
      return post(mount('/usuarios/usuario?idfornecedor=', idfornecedor), data)
    }
  }

  root.produto = {
    obtemProdutos: function(idfornecedor, search) {
      return get(
        mount(
          '/produtos/s2format-simple?idfornecedor=' +
            idfornecedor +
            '&search=' +
            search
        )
      )
    }
  }

  root.pedido = {
    updateFormaEntrega: function(idfornecedor, pedido) {
      return put(
        mount(
          '/fornecedor/',
          idfornecedor,
          '/pedido/',
          pedido.numpedido,
          '/formaEntrega'
        ),
        {
          pedido
        }
      )
    },
    updateEnderecos: function(idfornecedor, pedido) {
      return put(
        mount(
          '/fornecedor/',
          idfornecedor,
          '/pedido/',
          pedido.numpedido,
          '/enderecos'
        ),
        {
          pedido
        }
      )
    }
  }

  root.setoresProduto = {
    remove: data => {
      return put('setores/setor', data)
    }
  }

  root.fornecedor = {
    obtemDadosFornecedor: function(idfornecedor) {
      return get(mount('/fornecedores/fornecedor/' + idfornecedor))
    },
    buscarFornecedoresByEmpresa: function(idempresa) {
      return get(mount(`empresa/${idempresa}/fornecedores`))
    }
  }

  root.usuario.forcarintegracao = function(idfornecedor, cliente) {
    return post(mount('/usuario/forcarintegracao/' + idfornecedor), cliente)
  }

  root.tabelaPreco = {
    alterarDadosTabelaPrecoLocal: function(idfornecedor, dados) {
      return put(
        mount('fornecedor/', idfornecedor, '/tabelaprecos/todos-dados'),
        dados
      )
    },
    getStatusUpload: function(idfornecedor, file) {
      return get(
        mount(
          'fornecedor/',
          idfornecedor,
          '/tabelaprecos/getuploadstatus/',
          file
        )
      )
    }
  }

  root.params = {
    alterarAgendaEntrega: function(idfornecedor, data) {
      return put(mount('fornecedor/', idfornecedor, '/alteraragenda'), data)
    }
  }

  return root
}

export const v2 = () => {
  const baseURL = baseApiURL
  const mount = (...args) => {
    if (!args) throw new Error('Invalid resource mounted: was null')
    let urlmounted = '/api/web/internal/v2'
    Array.apply(null, args).forEach(function(part) {
      urlmounted += '/' + part
    })
    return urlmounted.replace(/(\/){2,}/g, '/') //cleanup slashed, just in case
  }
  const axiosInstance = Axios.create({
    baseURL: baseURL
  })
  axiosInstance.interceptors.request.use(
    function(config) {
      config.headers['x-accept'] = 'application/json'
      config.headers['Access-Control-Allow-Origin'] = '*'
      if (useHeader) {
        config.headers['authorization'] = Cookies.get('authorization') || ''
        config.headers['authorization1'] = Cookies.get('authorization1') || ''
        config.headers['authorization2'] = Cookies.get('authorization2') || ''
        config.headers['authorization3'] = Cookies.get('authorization3') || ''
      }
      let idfornecedor = Cookies.get('ID_FORNECEDOR')
      if (idfornecedor) {
        config.headers['ID_FORNECEDOR'] = idfornecedor
      }
      return config
    },
    function(error) {
      return Promise.reject(error)
    }
  )
  const root = {}
  const get = axiosInstance.get
  const post = (resource, data) => {
    return axiosInstance.post(resource, data)
  }

  const put = function(resource, data) {
    return axiosInstance.put(resource, data)
  }
  const del = function(resource) {
    return axiosInstance.delete(resource)
  }

  root.config = {
    get: (idfornecedor, dominio, assunto) => {
      return get(
        `/api/v2/configuracao/fornecedor/${idfornecedor}/${dominio}/${assunto}`
      )
    }
  }

  root.cupomDesconto = {
    buscaCupom: idcupomdesconto => {
      return get(mount('cupom-desconto/idcupom', idcupomdesconto))
    },
    listFornecedor: idfornecedor => {
      return get(mount('cupom-desconto/listar/fornecedor', idfornecedor))
    },
    fornecedor: {
      create: (idfornecedor, cupom) => {
        return put(mount('cupom-desconto/fornecedor', idfornecedor), cupom)
      },
      remove: (idfornecedor, idcupomdesconto) => {
        return del(
          mount(
            'cupom-desconto/fornecedor',
            idfornecedor,
            'idcupom',
            idcupomdesconto
          )
        )
      },
      validar: (idfornecedor, codigoCupom, idcliente) => {
        return get(
          `/api/v2/app/cupom-desconto/validar-desconto/fornecedor/${idfornecedor}/cliente/${idcliente}/cupom/${codigoCupom}`
        )
      }
    },
    recomendacao: {
      get: idfornecedor => {
        return get(
          mount('cupom-desconto/recomendacao/fornecedor', idfornecedor)
        )
      },
      save: (idfornecedor, cupom) => {
        return post(
          mount('cupom-desconto/recomendacao/fornecedor', idfornecedor),
          cupom
        )
      }
    }
  }

  root.kpi = {}
  root.kpi.avaliacaoMedia = function(idfornecedor) {
    return get(mount('kpi/fornecedor/', idfornecedor, '/avaliacao-media'))
  }
  root.kpi.ruptura = {}
  root.kpi.ruptura.indice = function(idfornecedor) {
    return get(mount('kpi/fornecedor/', idfornecedor, '/ruptura/indice'))
  }
  root.kpi.ruptura.detalhes = function(idfornecedor) {
    return get(mount('kpi/fornecedor/', idfornecedor, '/ruptura/detalhes'))
  }

  root.kpi.atendimento = {}
  root.kpi.atendimento.media = function(idfornecedor) {
    return get(
      mount('kpi/fornecedor/', idfornecedor, '/tempo-atendimento/medio')
    )
  }
  root.kpi.ticket = {}
  root.kpi.ticket.medio = function(idfornecedor) {
    return get(mount('kpi/fornecedor/', idfornecedor, '/ticket/medio'))
  }

  root.pedido = {}
  root.pedido.total = {}
  root.pedido.total.porStatus = function(
    idfornecedor,
    ativo,
    { cliente, numpedido, tipoEntrega }
  ) {
    const params = `?ativo=${ativo}&tipoentrega=${tipoEntrega}&cliente=${cliente}&numpedido=${numpedido}`
    return get(
      mount(`pedido/fornecedor/${idfornecedor}/total/por-status${params}`)
    )
  }

  root.segmentos = {}
  root.segmentos.getAll = function(especializado) {
    return get(mount(`segmentos?especializado=${especializado}`))
  }

  root.setoresProduto = {}
  root.setoresProduto.getSetor = function(idfornecedor, idsetor) {
    return get(mount(`setores-produto/get/setor/${idfornecedor}/${idsetor}`))
  }
  root.setoresProduto.setoresRaiz = function(
    idfornecedor,
    idsegmento,
    promocional
  ) {
    return get(
      mount(
        `/segmento/${idsegmento}/setores-produto/raiz?idfornecedor=${idfornecedor}&promocional=${promocional}`
      )
    )
  }
  root.setoresProduto.setoresFolha = function(idfornecedor, idsegmento) {
    const query = idsegmento ? '&segmento=' + idsegmento : ''
    return get(
      mount('/setores-produto/folha?idfornecedor=' + idfornecedor + query)
    )
  }
  root.setoresProduto.setoresTodos = function(idfornecedor, idsegmento) {
    const query = idsegmento ? `&segmento=${idsegmento}` : ''
    return get(
      mount(`/setores-produto/todos?idfornecedor=${idfornecedor}${query}`)
    )
  }
  root.setoresProduto.filhos = function(idsegmento, idsetor) {
    return get(
      mount(
        '/segmento',
        idsegmento,
        '/setores-produto/setor/' + idsetor + '/filhos'
      )
    )
  }
  root.setoresProduto.aplicarSetoresProdutos = function(
    idfornecedor,
    setores,
    alvosAplicacao,
    disponibilizar
  ) {
    return post(
      mount('/setores-produto/aplicar-setores-produtos', idfornecedor),
      {
        setores: setores,
        produtos: alvosAplicacao.produtos,
        idscadastroextra: alvosAplicacao.idscadastroextra,
        disponibilizar: disponibilizar
      }
    )
  }
  root.setoresProduto.desassociarSetoresProdutos = function(
    idfornecedor,
    produtos
  ) {
    return post(
      mount('/setores-produto/desassociar-setores-produtos', idfornecedor),
      {
        produtos: produtos
      }
    )
  }
  root.setoresProduto.salvarSetor = function(idfornecedor, segmento, dados) {
    return put(
      mount(
        `segmento/${segmento}/setores-produto/setor?idfornecedor=${idfornecedor}`
      ),
      dados
    )
  }
  root.setoresProduto.atualizarSetor = function(idfornecedor, segmento, dados) {
    return post(
      mount(
        `segmento/${segmento}/setores-produto/setor?idfornecedor=${idfornecedor}`
      ),
      dados
    )
  }
  root.setoresProduto.salvarOrdenacao = function(idfornecedor, setores) {
    return post(
      mount(`setores-produto/${idfornecedor}/salvar-ordenacao`),
      setores
    )
  }

  root.produto = {}
  root.produto.inserirProduto = function(idfornecedor, produto) {
    return post(mount('produto') + '?idfornecedor=' + idfornecedor, produto)
  }
  root.produto.atualizarProduto = function(idfornecedor, produto) {
    return put(
      mount(
        'produto',
        produto.idproduto,
        produto.idcadastroextraproduto || '',
        `?idfornecedor=${idfornecedor}`
      ),
      produto
    )
  }

  root.produto.removeProdutoExtra = function(
    idfornecedor,
    idcadastroextraproduto
  ) {
    return del(
      mount('produto', idcadastroextraproduto || '') +
        '?idfornecedor=' +
        idfornecedor
    )
  }
  root.produto.findById = function(
    idfornecedor,
    idproduto,
    idcadastroextraproduto
  ) {
    return get(
      mount('produto', idproduto, idcadastroextraproduto) +
        '?idfornecedor=' +
        idfornecedor
    )
  }
  root.produto.findByCodBarras = function(idfornecedor, codigo) {
    return get(
      mount('produto?idfornecedor=' + idfornecedor + '&codigobarras=' + codigo)
    )
  }
  root.produto.aplicarUsoProduto = function(
    idfornecedor,
    idsproduto,
    usoDoProduto
  ) {
    return post(mount('produto/aplicar-uso-produto'), {
      produtos: idsproduto,
      usoDoProduto: usoDoProduto,
      fornecedor: idfornecedor
    })
  }
  root.produto.buscaImagens = function(idfornecedor, idcadastroextraproduto) {
    return get(
      mount('produto', idfornecedor, idcadastroextraproduto, 'imagens')
    )
  }
  root.produto.removeImagem = function(
    idfornecedor,
    idcadastroextraproduto,
    imagem
  ) {
    return del(
      mount(
        'produto',
        idfornecedor,
        idcadastroextraproduto,
        'imagem',
        `?imagem=${imagem}`
      )
    )
  }

  root.empresa = {}
  root.empresa.listAll = function() {
    return get(mount('empresa'))
  }
  root.empresa.inserirOuAtualizar = function(data) {
    return post(mount('empresa'), data)
  }

  root.fornecedor = {}
  root.fornecedor.getSegmentos = function(idfornecedor) {
    return get(mount('fornecedor', idfornecedor, 'segmentos'))
  }

  root.fornecedor.updatePageAgeControl = function(
    idfornecedor,
    paginaControleMaioridade
  ) {
    return put(
      mount('fornecedor', idfornecedor, 'update-page-age-control'),
      paginaControleMaioridade
    )
  }

  root.areavenda = {}
  root.areavenda.getAreasFornecedor = function(idfornecedor) {
    return get(mount('area-venda/fornecedor', idfornecedor))
  }

  root.cargapreco = {}
  root.cargapreco.desabilitarMix = function(idfornecedor) {
    return get(mount('integracao-carga-preco/desabilitar-mix', idfornecedor))
  }

  root.usuario = {}
  root.usuario.getPerfil = function() {
    return get(mount('/meu-usuario/perfil'))
  }
  root.usuario.getUsuarios = function(idempresa, callback) {
    get(mount('/usuarios', idempresa), callback)
  }
  root.usuario.atualizarSenha = function(idfornecedor, oldPass, newPass) {
    return post(
      mount('/meu-usuario/alterar-senha') + `?idfornecedor=${idfornecedor}`,
      { oldPass: oldPass, newPass: newPass }
    )
  }
  root.usuario.atualizarIntegracoes = function(integracoes) {
    return post(mount('/meu-usuario/integracoes'), integracoes)
  }

  root.usuario.navegacao = function() {
    return get(mount('/meu-usuario/permissao-navegacao'))
  }

  root.usuario.atualizarPermissaoNavegacao = function(
    idfornecedor,
    idusuario,
    permissoes
  ) {
    return post(
      mount('/meu-usuario/permissao-navegacao') +
        `?idfornecedor=${idfornecedor}`,
      { idusuario: idusuario, permissoes: permissoes }
    )
  }

  root.marketing = { push: {}, email: {} }
  root.marketing.email.getConfig = function(idfornecedor) {
    return get(mount('/marketing/email/config', idfornecedor))
  }
  root.marketing.whatsapp = {
    getConfig: function(idfornecedor) {
      return get(mount('/marketing/whatsapp/config', idfornecedor))
    }
  }
  root.marketing.email.getConfigElasticMail = function(idfornecedor) {
    return get(mount('/marketing/email/config/elastic', idfornecedor))
  }
  root.marketing.email.saveConfigElasticMail = function(
    idfornecedor,
    apiKey,
    accountId
  ) {
    return post(mount('/marketing/email/config/elastic', idfornecedor), {
      apiKey: apiKey,
      accountId: accountId
    })
  }
  root.marketing.email.getTemplates = function(idfornecedor) {
    return get(mount('/marketing/email/templates', idfornecedor))
  }
  root.marketing.email.saveTemplatesEscolhidos = function(
    idfornecedor,
    templates
  ) {
    return post(
      mount('/marketing/email/templates-escolhidos', idfornecedor),
      templates || {}
    )
  }
  root.marketing.email.getTemplatesEscolhidos = function(
    idfornecedor,
    templates
  ) {
    return get(
      mount('/marketing/email/templates-escolhidos', idfornecedor),
      templates || {}
    )
  }
  root.marketing.email.getListaTemplate = function() {
    return get(mount('/marketing/email/lista-template'))
  }
  root.marketing.push.getUsers = function(
    idfornecedor,
    cadastroStart,
    cadastroEnd,
    compraStart,
    compraEnd,
    acessoStart,
    acessoEnd,
    nascimentoStart,
    nascimentoEnd,
    carrinhoAberto,
    cadastroAprovado,
    segmentosSelecionados,
    offset,
    limit
  ) {
    return get(
      mount(
        '/marketing/push/get-users?' +
          `idfornecedor=${idfornecedor || ''}&` +
          `cadastroStart=${cadastroStart || ''}&` +
          `cadastroEnd=${cadastroEnd || ''}&` +
          `compraStart=${compraStart || ''}&` +
          `compraEnd=${compraEnd || ''}&` +
          `acessoStart=${acessoStart || ''}&` +
          `acessoEnd=${acessoEnd || ''}&` +
          `nascimentoStart=${nascimentoStart || ''}&` +
          `nascimentoEnd=${nascimentoEnd || ''}&` +
          `carrinhoAberto=${carrinhoAberto || ''}&` +
          `segmentosSelecionados=${segmentosSelecionados || ''}&` +
          `cadastroAprovado=${cadastroAprovado || ''}&` +
          `offset=${offset || ''}&` +
          `limit=${limit || ''}&`
      )
    )
  }
  root.marketing.push.notificar = function(data) {
    return post(mount('/marketing/push/notify'), data)
  }

  root.customizacao = { carrossel: {} }

  root.customizacao.carrossel.createCarrossel = function(idfornecedor, { title, tipoConteudo }) {
    return post(mount('/customizacao/carrossel/create', idfornecedor), {
      title: title,
      tipoConteudo: tipoConteudo
    })
  }
  root.customizacao.carrossel.updateCarrossel = function({ idcarrossel, configs }) {
    return post(
      mount('/customizacao/carrossel/update-carrossel', idcarrossel),
      configs
    )
  }
  root.customizacao.carrossel.listCarrossel = function(
    idfornecedor,
    tipoConteudo
  ) {
    return get(
      mount('/customizacao/carrossel/list', idfornecedor) +
        '?tipo_conteudo=' +
        tipoConteudo
    )
  }

  root.customizacao.carrossel.deleteCarrossel = function(
    idfornecedor,
    idcarrossel
  ) {
    return del(mount('/customizacao/carrossel/', idfornecedor, idcarrossel), {})
  }

  root.faturas = {}
  root.faturas.getFatura = function(idfaturacliente) {
    return get(`/api/v2/app/faturas/faturas-cliente/${idfaturacliente}`)
  }

  return root
}

export const v4 = () => {
  var root = {}
  const baseURL = baseApiURL + 'api/v4' || 'api/v4'
  const mount = (...args) => {
    if (!args) throw new Error('Invalid resource mounted: was null')
    let urlMouted = ''
    Array.apply(null, args).forEach(function(part) {
      urlMouted += '/' + part
    })
    return urlMouted.replace(/(\/){2,}/g, '/') //cleanup slashed, just in case
  }
  const axiosInstance = Axios.create({
    baseURL: baseURL,
    withCredentials: false
  })
  axiosInstance.interceptors.request.use(
    function(config) {
      config.headers['x-accept'] = 'application/json'
      config.headers['Access-Control-Allow-Origin'] = '*'
      if (useHeader) {
        config.headers['authorization'] = Cookies.get('authorization') || ''
        config.headers['authorization1'] = Cookies.get('authorization1') || ''
        config.headers['authorization2'] = Cookies.get('authorization2') || ''
        config.headers['authorization3'] = Cookies.get('authorization3') || ''
      }
      let idfornecedor = Cookies.get('ID_FORNECEDOR')
      if (idfornecedor) {
        config.headers['ID_FORNECEDOR'] = idfornecedor
      }
      return config
    },
    function(error) {
      return Promise.reject(error)
    }
  )
  const get = axiosInstance.get
  const post = (resource, data, headers = {}) => {
    return axiosInstance.post(resource, data, {
      headers
    })
  }

  const put = function(resource, data, headers = {}) {
    return axiosInstance.put(resource, data, { headers })
  }
  const httpDelete = function(resource) {
    return axiosInstance.delete(resource)
  }

  root.formaPagamento = {}

  root.formaPagamento.getById = function({ idfornecedor, idformaPagamento }) {
    return get(`/payment-method/fornecedor/${idfornecedor}/${idformaPagamento}`)
  }

  root.formaPagamento.create = function({ idfornecedor, formaPagamento }) {
    return post('/payment-method/fornecedor/' + idfornecedor, formaPagamento)
  }

  root.formaPagamento.update = function({ idfornecedor, formaPagamento }) {
    return put('/payment-method/fornecedor/' + idfornecedor, formaPagamento)
  }

  root.formaPagamento.list = function({ idfornecedor }) {
    return get('/payment-method/' + idfornecedor)
  }

  root.formaPagamento.toggleDefault = function(data) {
    return post('/payment-method/toggle-default', data)
  }

  root.formaPagamento.remove = function({
    idfornecedor,
    idempresa,
    idformapagto
  }) {
    return httpDelete(
      '/payment-method/' + idformapagto + '/' + idfornecedor + '/' + idempresa
    )
  }

  root.cliente = {}
  root.cliente.create = function(idfornecedor, cliente) {
    return post('/client/' + idfornecedor, cliente)
  }
  root.cliente.update = function(idfornecedor, idusuario, cliente) {
    return put(`/client/${idfornecedor}/${idusuario}`, cliente)
  }
  root.cliente.remove = function(idfornecedor, idcliente, idusuario) {
    return httpDelete(`/client/${idfornecedor}/${idcliente}/${idusuario}`)
  }
  root.cliente.list = function(idfornecedor) {
    return get('/client/' + idfornecedor)
  }
  root.cliente.listQuery = function(idfornecedor, query) {
    return get(mount('/client/', idfornecedor, '/search/options', query))
  }
  root.cliente.getNavigatonLogsExport = function(idfornecedor) {
    return get(mount(`/log/navigation/export/${idfornecedor}`))
  }
  root.cliente.getNavigatonLogs = function(idfornecedor, idcliente, qtd) {
    return get(
      mount(
        `/log/navigation/client?idfornecedor=${idfornecedor}&idcliente=${idcliente}&qtd=${qtd}`
      )
    )
  }
  root.cliente.listDatatable = function(idfornecedor) {
    return get('/client/datatable/' + idfornecedor)
  }
  root.cliente.getFull = function(idfornecedor, idcliente) {
    return get(`/client/${idcliente}/supplier/${idfornecedor}`)
  }
  root.cliente.sendVerificacao = function(idfornecedor, canal, idcliente, fonte) {
    return get(`verificacao/enviar-verificacao?canal=${canal}&idcliente=${idcliente}&idfornecedor=${idfornecedor}&fonte=${fonte}`)
  }
  root.cliente.address = {
    save: (idfornecedor, idcliente, payload) => {
      return post(`/client/${idcliente}/supplier/${idfornecedor}`, payload)
    },
    update: (idfornecedor, idcliente, payload) => {
      return put(`/client/${idcliente}/supplier/${idfornecedor}`, payload)
    },
    updateDefault: (idfornecedor, idcliente, payload) => {
      return put(
        `/client/${idcliente}/supplier/${idfornecedor}/default`,
        payload
      )
    }
  }

  root.cliente.bindInfoCadastroExtraCliente = function(idfornecedor, cliente) {
    return post('/client/multiple/data-cextra/' + idfornecedor, cliente)
  }
  root.cliente.listAvailablePaymentMethods = function(idfornecedor, idcliente) {
    return get(
      mount('/client/available-payment-methods/', idfornecedor, idcliente)
    )
  }
  root.cliente.listAvailableSegments = function(idfornecedor, idcliente) {
    return get(mount('/client/available-segments/', idfornecedor, idcliente))
  }
  root.cliente.getClientsByIds = function(idfornecedor, ids, page) {
    return post(
      mount(`/client/list/by-ids/${idfornecedor}`) + `?page=${page}`,
      { ids }
    )
  }
  root.cliente.resetPassword = function(idfornecedor, idclient) {
    return get(mount(`/client/reset-password/${idfornecedor}/${idclient}`))
  }
  root.cliente.resetPassordCustomers = function(
    idfornecedor,
    idclient,
    novaSenha,
    email
  ) {
    return put(mount(`/client/reset-password/${idfornecedor}/${idclient}`), {
      senhaNova: novaSenha,
      email: email
    })
  }
  root.cliente.getEndereco = function(idfornecedor, idcliente) {
    return get(mount('/client', idfornecedor, 'endereco', idcliente))
  }
  root.clientRestriction = {}
  root.clientRestriction.get = function(idrestricaocliente) {
    return get(mount('/product-restriction', idrestricaocliente))
  }
  root.clientRestriction.getProducts = function (idrestricaocliente, page, limit) {
    return get(mount(`/product-restriction/${idrestricaocliente}/produtos?page=${page}&limit=${limit}`))
  }
  root.clientRestriction.getClients = function (idrestricaocliente, { search, page, limit, idfornecedor }) {
    const params = `?page=${page}&search=${search}&idfornecedor=${idfornecedor}&limit=${limit}`
    return get(mount(`/product-restriction/${idrestricaocliente}/clientes${params}`))
  }
  root.clientRestriction.saveAll = function(idfornecedor, data) {
    return post(mount('/product-restriction/', idfornecedor), data)
  }
  root.clientRestriction.save = function(idfornecedor, data) {
    return post(mount('/product-restriction-only', idfornecedor), data)
  }
  root.clientRestriction.remove = function(idfornecedor, idrestricaocliente) {
    return httpDelete(
      mount('/product-restriction', idrestricaocliente) +
        `?idfornecedor=${idfornecedor}`
    )
  }
  root.cliente.caracteristic = {
    list: idfornecedor => {
      return get(mount('caracteristica-cliente/all/', idfornecedor))
    },
    get: (idfornecedor, idcaracteristica) => {
      return get(
        mount('caracteristica-cliente/', idfornecedor, idcaracteristica)
      )
    },
    adiciona: (idfornecedor, form) => {
      return post(mount('caracteristica-cliente/', idfornecedor), form)
    },
    atualiza: (idfornecedor, idcaracteristica, form) => {
      return put(
        mount('caracteristica-cliente/', idfornecedor, idcaracteristica),
        form
      )
    },
    remove: (idfornecedor, idcaracteristica) => {
      return httpDelete(
        mount('caracteristica-cliente/', idfornecedor, idcaracteristica)
      )
    }
  }

  root.gateway = {}
  root.gateway.getGateways = function(idfornecedor) {
    return get('/gateways/' + idfornecedor)
  }
  root.gateway.getById = function(idfornecedor, idgateway) {
    return get(`/gateways/${idfornecedor}/${idgateway}`)
  }
  root.gateway.saveGateway = function(gateway, isPost) {
    const formData = new FormData()
    Object.keys(gateway).map(key => {
      if (gateway[key] != null) {
        formData.append(key, gateway[key])
      }
    })
    if (isPost) {
      return post('/gateways', formData, {
        'Content-Type': 'multipart/form-data'
      })
    } else {
      return put('/gateways', formData, {
        'Content-Type': 'multipart/form-data'
      })
    }
  }
  root.gateway.deleteGateway = function(idfornecedor, idgateway) {
    return httpDelete(
      '/gateways/' + idgateway + `?idfornecedor=${idfornecedor}`
    )
  }
  root.gateway.config = function() {
    return get('/gateways/config')
  }

  root.gruposDePrecos = {}
  root.gruposDePrecos.list = function({ idfornecedor }) {
    return get('/grupo-preco/' + idfornecedor)
  }

  root.gruposDePrecos.setDefault = function(
    codigoGrupoPreco,
    idfornecedor,
    body
  ) {
    return post(
      '/grupo-preco/' + codigoGrupoPreco + '/fornecedor/' + idfornecedor,
      body
    )
  }

  root.gruposDePrecos.getGrupoPreco = function(codigoGrupoPreco, idfornecedor) {
    return get(
      '/grupo-preco/' + codigoGrupoPreco + '/fornecedor/' + idfornecedor
    )
  }

  root.gruposDePrecos.salvarFaixasCep = function(
    codigoGrupoPreco,
    idfornecedor,
    faixas
  ) {
    return post(
      '/grupo-preco/' +
        codigoGrupoPreco +
        '/fornecedor/' +
        idfornecedor +
        '/faixas-cep',
      faixas
    )
  }

  root.gruposDePrecos.validarFaixaCep = function(
    codigoGrupoPreco,
    idfornecedor,
    novaFaixa
  ) {
    return post(
      `/grupo-preco/${codigoGrupoPreco}/fornecedor/${idfornecedor}/faixas-cep/validar`,
      novaFaixa
    )
  }

  root.gruposDePrecos.delete = function(codigoGrupoPreco, idfornecedor) {
    return httpDelete(
      '/grupo-preco/' + codigoGrupoPreco + '/fornecedor/' + idfornecedor
    )
  }
  root.listaCompras = {
    listar: idfornecedor => {
      return get(mount('shopping-list/', idfornecedor))
    },
    obter: (idfornecedor, id) => {
      return get(mount(`shopping-list/${idfornecedor}/products`, id))
    },
    adicionar: (idfornecedor, form) => {
      return post(mount('shopping-list/', idfornecedor), form)
    },
    remove: (idfornecedor, id) => {
      return httpDelete(mount(`shopping-list/${idfornecedor}/remove/`, id))
    }
  }

  root.tabelaPreco = {}
  root.tabelaPreco.list = function() {
    return get('/tabela-preco/datatable')
  }
  root.tabelaPreco.updateRemotePrices = function(
    idempresa,
    idfornecedor,
    data
  ) {
    return post(
      mount('/tabela-preco/update/precos/', idempresa, idfornecedor),
      data
    )
  }
  root.tabelaPreco.updatePajeAvailability = function(idfornecedor, data) {
    return post(
      mount('/tabela-preco/update/disponibilidade/', idfornecedor),
      data
    )
  }
  root.tabelaPreco.updateDefaultClient = function(idfornecedor, data) {
    return post(
      mount('/tabela-preco/update/cliente-padrao/', idfornecedor),
      data
    )
  }
  root.tabelaPreco.getDefaultclient = function(idfornecedor) {
    return get(mount('/tabela-preco/cliente-padrao/', idfornecedor))
  }
  root.tabelaPreco.getTipoGrupoClienteReferencia = function(idfornecedor) {
    return get(mount('/tabela-preco/tipo-grupo/', idfornecedor))
  }
  root.tabelaPreco.uploadLocalPrice = function(idfornecedor, data) {
    return post(mount('/tabela-preco/upload/', idfornecedor), data)
  }
  root.tabelaPreco.dowloadLocalPriceCriticas = function(file) {
    return get(mount('/tabela-preco/download/', file))
  }

  root.pedido = {}
  root.pedido.getLogsEvento = function(numpedido) {
    return get('/orders/order-logs/' + numpedido + '/full')
  }
  root.pedido.reenviarParaErp = function(idfornecedor, numpedido) {
    return get('/orders/sync/erp/' + numpedido + '/' + idfornecedor)
  }
  root.pedido.updateDeliveryDate = function(
    idfornecedor,
    idorder,
    dataentrega
  ) {
    return put(
      '/orders/delivery-date/' + idorder + `?idfornecedor=${idfornecedor}`,
      dataentrega
    )
  }
  root.pedido.updatePedidoVerificadoManualmente = function(
    idfornecedor,
    idorder,
    verificado
  ) {
    return put(
      mount('/orders/manual-payment-verified/', idorder, verificado) +
        `?idfornecedor=${idfornecedor}`
    )
  }
  root.pedido.updatePaymentMethod = function(
    idfornecedor,
    idorder,
    idformapagto,
    quantParcelas
  ) {
    return put(
      mount('/orders/payment-method/', idfornecedor, '/order/', idorder) +
        `?idfornecedor=${idfornecedor}`,
      {
        idformapagto: idformapagto,
        quantParcelas: quantParcelas
      }
    )
  }

  root.pedido.updateResponsable = function(idfornecedor, idorder, data) {
    return put(
      '/orders/responsable/' + idorder + `?idfornecedor=${idfornecedor}`,
      data
    )
  }
  root.pedido.obtemPedidoSimples = function(idfornecedor, numpedido) {
    return get(
      mount('orders/simple-order-description', numpedido) +
        '?idfornecedor=' +
        idfornecedor
    )
  }

  root.pedido.setStatusPedido = function(
    idfornecedor,
    { numpedido, newStatus }
  ) {
    return post(
      mount('orders/set-status', numpedido) + '?idfornecedor=' + idfornecedor,
      { newStatus: newStatus }
    )
  }
  root.pedido.limparIdPedidoERP = function(idfornecedor, numpedido) {
    return post(
      mount(
        'orders/clean-id-pedido-erp/',
        numpedido,
        `?idfornecedor=${idfornecedor}`
      )
    )
  }
  root.pedido.updateMensagemLida = function(numpedido, data) {
    return put('/orders/update-read-messages/' + numpedido, data)
  }
  root.pedido.generateReceipt = function(idfornecedor, numorder, idclient) {
    return post(mount('/payment/generate-receipt/', numorder, idclient), {})
  }
  root.pedido.enviar = function(idfornecedor, data) {
    return post(mount(`/orders/${idfornecedor}/create`), data)
  }
  root.pedido.caracteristic = {
    list: idfornecedor => {
      return get(mount('caracteristica-pedido/all/', idfornecedor))
    },
    get: (idfornecedor, idcaracteristica) => {
      return get(
        mount('caracteristica-pedido/', idfornecedor, idcaracteristica)
      )
    },
    adiciona: (idfornecedor, form) => {
      return post(mount('caracteristica-pedido/', idfornecedor), form)
    },
    atualiza: (idfornecedor, idcaracteristica, form) => {
      return put(
        mount('caracteristica-pedido/', idfornecedor, idcaracteristica),
        form
      )
    },
    remove: (idfornecedor, idcaracteristica) => {
      return httpDelete(
        mount('caracteristica-pedido/', idfornecedor, idcaracteristica)
      )
    }
  }

  root.parametros = {}
  root.parametros.getTags = function(
    idfornecedor,
    onlyAdmin,
    onlyAdvancedMaintenance
  ) {
    return get(
      `/parameters/tags/${idfornecedor}?onlyAdmin=${onlyAdmin}&onlyAdvancedMaintenance=${onlyAdvancedMaintenance}`
    )
  }
  root.parametros.getStatusPedido = function(idfornecedor) {
    return get('/parameters/order-status?idfornecedor=' + idfornecedor)
  }

  root.produto = {}
  root.produto.removeSubItem = function(idvinculoconjunto) {
    return httpDelete(mount('conjunto/sub-item/', idvinculoconjunto))
  }
  root.produto.getProdutosByFornecedor = function(
    idfornecedor,
    page,
    searchtype,
    searchkey
  ) {
    const url = `${mount(
      'products',
      idfornecedor
    )}?page=${page}&${searchtype}=${searchkey}`
    return get(url)
  }
  root.produto.getProdutosByFornecedorQueries = function(idfornecedor, query) {
    const url = `${mount('products', idfornecedor)}?${query}`
    return get(url)
  }
  root.produto.getProdutosByIdsErp = function(idfornecedor, ids, page) {
    let url = `${mount('products/erp/', idfornecedor)}?page=${page}`
    return post(url, { ids })
  }
  root.produto.getProdutosIdsErpByCriteria = function(
    idfornecedor,
    searchtype,
    searchkey
  ) {
    const url = `${mount(
      'products/erp-all',
      idfornecedor
    )}?${searchtype}=${searchkey}`
    return get(url)
  }
  root.produto.getProdutosByIdsExtra = function(idfornecedor, ids, page) {
    let url = `${mount('products/cadastroextra/', idfornecedor)}?page=${page}`
    return post(url, { ids })
  }
  root.produto.getProdutosIdsExtraByCriteria = function(
    idfornecedor,
    searchtype,
    searchkey
  ) {
    const url = `${mount(
      'products/cadastroextra-all',
      idfornecedor
    )}?${searchtype}=${searchkey}`
    return get(url)
  }
  root.produto.caracteristic = {
    list: idfornecedor => {
      return get(mount('caracteristica-produto/all/', idfornecedor))
    },
    get: (idfornecedor, idcaracteristica) => {
      return get(
        mount('caracteristica-produto/', idfornecedor, idcaracteristica)
      )
    },
    adiciona: (idfornecedor, form) => {
      return post(mount('caracteristica-produto/', idfornecedor), form)
    },
    atualiza: (idfornecedor, idcaracteristica, form) => {
      return put(
        mount('caracteristica-produto/', idfornecedor, idcaracteristica),
        form
      )
    },
    remove: (idfornecedor, idcaracteristica) => {
      return httpDelete(
        mount('caracteristica-produto/', idfornecedor, idcaracteristica)
      )
    }
  }
  root.produto.carrossel = {
    listSelect: (idfornecedor, buscaTextual) => {
      return get(
        mount('carrossel/select/', idfornecedor) +
          `?search=${buscaTextual}&local=page:product-details`
      )
    }
  }
  root.produto.tagPesquisa = {
    associa: form => {
      return post(mount('/product/tag-pesquisa/associa'), form)
    },
    desassocia: form => {
      return post(mount('/product/tag-pesquisa/desassocia'), form)
    }
  }
  root.produto.obtemArquivoEdicao = idfornecedor => {
    return get(mount('product/file/', idfornecedor))
  }
  root.produto.obtemLogEdicao = idfornecedor => {
    return get(mount('product/file/log/', idfornecedor))
  }
  root.produto.editaEmMassa = (idfornecedor, form) => {
    const formData = new FormData()
    Object.keys(form).map(key => {
      if (form[key] != null) {
        formData.append(key, form[key])
      }
    })
    return post(mount('product/file/', idfornecedor), formData, {
      'Content-Type': 'multipart/form-data'
    })
  }

  root.imagemProduto = {}
  root.imagemProduto.uploadProductImages = formData => {
    return post(mount('product-image'), formData, {
      'Content-Type': 'multipart/form-data'
    })
  }

  root.params = {}
  root.params.submitParams = function(idfornecedor, data) {
    return put(mount('params/', idfornecedor), data)
  }
  root.params.submitTags = function(idfornecedor, data) {
    return put(mount('params/tags/', idfornecedor), data)
  }
  root.params.obtemDadosDominio = function(idfornecedor) {
    return get(mount('params/domain', idfornecedor))
  }
  root.params.obtemDados = function(idfornecedor) {
    return get(mount('params', idfornecedor))
  }
  root.params.getDeliveryArea = function(idfornecedor) {
    return get(mount('params/delivery-area', idfornecedor))
  }
  root.params.validaDominio = function(idfornecedor, dominio) {
    return get(
      mount(
        `params/domain-validate?dominio=${dominio}&idfornecedor=${idfornecedor}`
      )
    )
  }

  root.theme = {
    obtemDadosTema: function(idfornecedor) {
      return get(mount('theme', idfornecedor))
    },
    submitTheme: function(idfornecedor, data) {
      return put(mount('theme', idfornecedor), data)
    },
    restaurarTema: function(idfornecedor) {
      return httpDelete(mount('theme', idfornecedor))
    },
    submitMenus: function(idfornecedor, data) {
      return post(mount('theme/menus', idfornecedor), data)
    },
    obterDadosMenu: function(idfornecedor) {
      return get(mount('theme/menus', idfornecedor))
    },
    obterDadosEstaticos: function (idfornecedor) {
      return get(mount('theme/static-data', idfornecedor))
    },
    uploadImage: function (idfornecedor, data) {
      return post(mount('theme/upload/image', idfornecedor), data)
    },
  }

  root.integracoes = {
    updateIntegrations: function(idfornecedor, data) {
      return put(mount('integrations', idfornecedor), data)
    },
    getIntegrations: function(idfornecedor) {
      return get(mount('integrations', idfornecedor))
    },
    getErpStack: function(idfornecedor) {
      return get(mount('integrations/status/erp-stack', idfornecedor))
    },
    removeOauth: function(idfornecedor, service) {
      return httpDelete(mount('oauth', idfornecedor, service))
    }
  }

  root.calendario = {
    delete: function(idfornecedor, idcalendario) {
      return httpDelete(mount('calendario', idfornecedor, idcalendario))
    },
    create: function(idfornecedor, data) {
      return post(mount('calendario', idfornecedor), data)
    },
    update: function(idfornecedor, idcalendario, data) {
      return put(mount('calendario', idfornecedor, idcalendario), data)
    },
    get: function(idcalendario) {
      return get(mount('calendario', idcalendario))
    }
  }

  root.formaEntrega = {}
  root.formaEntrega.saveFormaEntrega = function(
    idfornecedor,
    formaEntrega,
    isPost
  ) {
    if (isPost) {
      return post(mount('forma-entrega', idfornecedor), formaEntrega)
    } else {
      return put(mount('forma-entrega', idfornecedor), formaEntrega)
    }
  }
  root.formaEntrega.get = function(idformaentrega) {
    return get(mount('forma-entrega', idformaentrega))
  }
  root.formaEntrega.getListaFormasEntrega = function(idfornecedor) {
    return get(mount('forma-entrega/lista-entrega/', idfornecedor))
  }
  root.formaEntrega.simular = function(idfornecedor, body) {
    return post(mount('forma-entrega/', idfornecedor, 'simular'), body)
  }
  root.formaEntrega.getDetalhesCustoTransporte = function(
    idformaentrega,
    body
  ) {
    return post(
      mount('forma-entrega/detalhes-custo-transporte', idformaentrega),
      body
    )
  }
  root.formaEntrega.getDadosFornecedor = function(idfornecedor) {
    return get(mount('forma-entrega/dados-fornecedor', idfornecedor))
  }
  root.formaEntrega.removeFormasEntrega = function(
    idfornecedor,
    idformaentrega,
    tipo
  ) {
    return httpDelete(
      mount('forma-entrega', idfornecedor, idformaentrega) + '?tipo=' + tipo
    )
  }
  root.formaEntrega.getCalendarios = function(idfornecedor) {
    return get(mount('forma-entrega/calendarios', idfornecedor))
  }

  root.formaEntrega.getFormasPedido = function(
    idfornecedor,
    idpedido,
    idendereco
  ) {
    return get(mount('forma-entrega/', idfornecedor, idpedido, idendereco))
  }
  root.formaEntrega.getLista = function(idfornecedor, data) {
    return post(mount('forma-entrega/', idfornecedor, 'lista'), data)
  }

  root.discountPolicy = {
    save: (idfornecedor, idempresa, data, savingmode) => {
      const url = `discount-policy/${idfornecedor}/${idempresa}?savingmode=${savingmode}`
      return post(mount(url), data)
    },
    get: (idfornecedor, idempresa, iddiscountpolicy) => {
      return get(
        mount('discount-policy', iddiscountpolicy, idfornecedor, idempresa)
      )
    },
    remove: (idfornecedor, idempresa, iddiscountpolicy) => {
      return httpDelete(
        mount('discount-policy', iddiscountpolicy, idfornecedor, idempresa)
      )
    }
  }

  root.promotionalCombo = {
    save: (idfornecedor, idempresa, data, savingmode) => {
      const url = `promotional-combo/${idfornecedor}/${idempresa}?savingmode=${savingmode}`
      return post(mount(url), data)
    },
    get: (idfornecedor, idempresa, idpromotionalcombo) => {
      return get(
        mount('promotional-combo', idpromotionalcombo, idfornecedor, idempresa)
      )
    },
    getItens: (idfornecedor, idempresa, idpromotionalcombo) => {
      return get(
        mount(
          'promotional-combo-itens',
          idpromotionalcombo,
          idfornecedor,
          idempresa
        )
      )
    },
    remove: (idfornecedor, idempresa, idpromotionalcombo) => {
      return httpDelete(
        mount('promotional-combo', idpromotionalcombo, idfornecedor, idempresa)
      )
    },
    buscaImagens: (idfornecedor, idpromotionalcombo) => {
      return get(
        mount('promotional-combo', idfornecedor, idpromotionalcombo, 'imagem')
      )
    },
    removeImagem: (idfornecedor, idpromotionalcombo, imagem) => {
      return httpDelete(
        mount(
          'promotional-combo',
          idfornecedor,
          idpromotionalcombo,
          'imagem',
          `?imagem=${imagem}`
        )
      )
    }
  }

  root.maintenance = {}
  root.maintenance.removeImageImportHistory = function(
    idfornecedor,
    idusuario
  ) {
    return httpDelete(
      mount('manutencao-avancada/image-import-history', idfornecedor, idusuario)
    )
  }
  root.maintenance.removerPoliticas = function(idfornecedor, all) {
    return httpDelete(
      mount(`/manutencao-avancada/politica/${idfornecedor}?all=${all}`)
    )
  }
  root.maintenance.removerClientes = function(idfornecedor, all) {
    return httpDelete(mount(`/manutencao-avancada/cliente/${idfornecedor}?all=${all}`))
  }

  root.segmentoCliente = {}
  root.segmentoCliente.save = (idfornecedor, data) => {
    return post(mount('segmento-cliente', idfornecedor), data)
  }
  root.segmentoCliente.getRelations = idsegmentocliente => {
    return get(mount('segmento-cliente/relations', idsegmentocliente))
  }
  root.segmentoCliente.list = ({ idfornecedor }) => {
    return get(mount('segmento-cliente', idfornecedor))
  }
  root.segmentoCliente.remove = idsegmentocliente => {
    return httpDelete(mount('segmento-cliente', idsegmentocliente))
  }

  root.relatorio = {}
  root.relatorio.list = idfornecedor => {
    return get(mount('report/list', idfornecedor))
  }
  root.relatorio.getCsv = (idfornecedor, data) => {
    var encodedData = encodeURIComponent(JSON.stringify(data))
    window.open(
      mount('api/v4/report/csv', idfornecedor, '?reportRequest=' + encodedData)
    )
  }
  root.diagnostico = {
    obter: function(idfornecedor) {
      return get(mount('diagnosis', idfornecedor))
    }
  }
  root.kpi = {
    obter: function(idfornecedor) {
      return get(mount('diagnosis/kpi/', idfornecedor))
    }
  }
  root.paginaestatica = { 
    obterPorId: function(idfornecedor, idconteudoestatico) {
      return get(mount('pagina-estatica', idfornecedor, idconteudoestatico))
    },
    adicionar: function(idfornecedor, data) {
      return post(mount('pagina-estatica/adicionar', idfornecedor), data)
    },
    atualizar: function(idfornecedor, idconteudoestatico, data) {
      return put(
        mount('pagina-estatica/atualizar', idfornecedor, idconteudoestatico),
        data
      )
    },
    remover: function(idfornecedor, idconteudoestatico) {
      return httpDelete(
        mount('pagina-estatica/remover', idfornecedor, idconteudoestatico)
      )
    },
    listar: function(idfornecedor) {
      return get(mount(`pagina-estatica/${idfornecedor}/listar`))
    }
  }
  root.mensagensautomaticas = {
    listaCanais: function() {
      return get(mount('mensagem-comunicacao/lista-canais'))
    },
    listaEventos: function(canalAlias) {
      return get(mount('mensagem-comunicacao/lista-eventos', canalAlias))
    },
    obterMensagem: function(idfornecedor, canalAlias, eventoAlias) {
      return get(
        mount('mensagem-comunicacao', idfornecedor, canalAlias, eventoAlias)
      )
    },
    atualizaMensagem: function(idfornecedor, idmensagemcomunicacao, data) {
      return post(
        mount(
          `mensagem-comunicacao/${idfornecedor}/atualiza-mensagem`,
          idmensagemcomunicacao
        ),
        data
      )
    },
    restauraPadrao: function(idfornecedor, idmensagemcomunicacao) {
      return httpDelete(
        mount(
          `mensagem-comunicacao/${idfornecedor}/deleta-mensagem`,
          idmensagemcomunicacao
        )
      )
    },
    templates: function(idfornecedor, canalAlias) {
      return get(
        mount(`mensagem-comunicacao/templates/${idfornecedor}/${canalAlias}`)
      )
    }
  }
  root.company = {
    getCompanySupplier: (idfornecedor, idempresa) => {
      return get(mount(`/empresa/dados-basicos/${idempresa}/${idfornecedor}`))
    },
    saveCompanySupplier: (idfornecedor, idempresa, dados) => {
      return post(
        mount(`/empresa/dados-basicos/${idempresa}/${idfornecedor}`),
        dados
      )
    },
    updatePaymentInfo: (idempresa, dados) => {
      return put(mount(`/empresa/info-cobranca/${idempresa}`), dados)
    },
    get: idempresa => {
      return get(mount(`/empresa/${idempresa}`))
    }
  }

  root.supplier = {
    createRemoteRepository: function(idfornecedor, dados) {
      return post(
        mount('admin/supplier/create-remote-repository', idfornecedor),
        dados
      )
    },
    createGroupPrice: function(idfornecedor) {
      return post(mount('admin/supplier/create-group-price', idfornecedor), {})
    },
    normalizarIdErpProdutos: function(idfornecedor) {
      return post(
        mount('admin/supplier/normalize-products-iderp', idfornecedor),
        {}
      )
    },
    copiarPrecoPaje: function(idfornecedor) {
      return post(
        mount('admin/supplier/copy-price-remote-repository', idfornecedor),
        {}
      )
    }
  }

  root.tagcustom = {
    obter: function({ idfornecedor, idtagcustomizada }) {
      return get(mount('tag-customizada', idfornecedor, idtagcustomizada))
    },
    adiciona: function({ idfornecedor, data }) {
      return post(mount('tag-customizada', idfornecedor), data)
    },
    atualiza: function({ idfornecedor, idtagcustomizada, data }) {
      return put(mount('tag-customizada', idfornecedor, idtagcustomizada), data)
    },
    remove: function({ idfornecedor, idtagcustomizada }) {
      return httpDelete(
        mount('tag-customizada', idfornecedor, idtagcustomizada)
      )
    }
  }
  root.logsAudit = {
    obter: function(idFornecedor, logSessionId) {
      return get(mount('log-audit', idFornecedor, logSessionId))
    },
    listaTags: function() {
      return get(mount('log-audit', 'list-tags'))
    }
  }

  root.subscription = {
    cancel: function(id) {
      return httpDelete(mount('subscription/cancel', id))
    },
    get: function(id) {
      return get(mount('subscription/get', id))
    },
    type: {
      save: function(idfornecedor, data) {
        return post(mount('subscription/type/save', idfornecedor), data)
      },
      remove: function(id) {
        return httpDelete(mount('subscription/type/remove', id))
      }
    }
  }

  root.manutencao = {
    softDeletePedido: function({ idfornecedor, idusuario, numpedido }) {
      return post(mount('manutencao-avancada/soft-delete-pedido', numpedido), {
        idfornecedor: idfornecedor,
        idusuario: idusuario
      })
    },
    removeProducts: function(idfornecedor, idusuario) {
      return httpDelete(mount('manutencao-avancada/products', idfornecedor, idusuario))
    }
  }

  root.admin = {
    getPassosImplantacao: (idfornecedor, executeQuery, step) => {
      const params = `?executeQuery=${executeQuery}&step=${step}`
      return get(mount(`admin/supplier/wirzard-steps/${idfornecedor}${params}`))
    },
    updatePublishStatus: (idfornecedor, dados) => {
      return put(mount(`/admin/supplier/publish/${idfornecedor}`), dados)
    },
    listScriptsPWC: function(dados) {
      return post(mount('admin/list-scripts-pwc'), dados)
    },
    listScriptsPWCS: function(dados) {
      return post(mount('admin/list-scripts-pwcs'), dados)
    }
  }

  root.novidades = {
    getNew: function() {
      return get(mount('novidades', 'nao-lidas'))
    },
    list: function() {
      return get(mount('novidades', 'list'))
    },
    inserir: function(data) {
      return post(mount('novidades'), data)
    },
    remover: function(idnovidade) {
      return httpDelete(mount('novidades', idnovidade))
    },
    obter: function(idnovidade) {
      return get(mount('novidades', idnovidade))
    },
    adiciona: function(data) {
      return post(mount('novidades'), data)
    },
    atualiza: function(idnovidade, data) {
      return put(mount('novidades', idnovidade), data)
    }
  }

  root.tagPesquisa = {
    obterTag: function(idfornecedor, idtagpesquisa) {
      return get(mount('tag-pesquisa', idfornecedor, idtagpesquisa))
    },
    obterAgrupador: function(idfornecedor, idagrupadortagpesquisa) {
      return get(
        mount('tag-pesquisa', idfornecedor, 'agrupador', idagrupadortagpesquisa)
      )
    },
    inserirTag: function(idfornecedor, data) {
      return post(mount('tag-pesquisa', idfornecedor), data)
    },
    inserirAgrupador: function(idfornecedor, data) {
      return post(mount('tag-pesquisa', idfornecedor, 'agrupador'), data)
    },
    atualizaTag: function(idfornecedor, idtagpesquisa, data) {
      return put(mount('tag-pesquisa', idfornecedor, idtagpesquisa), data)
    },
    atualizaAgrupador: function(idfornecedor, idagrupadortagpesquisa, data) {
      return put(
        mount(
          'tag-pesquisa',
          idfornecedor,
          'agrupador',
          idagrupadortagpesquisa
        ),
        data
      )
    },
    deletaTag: function(idfornecedor, idtagpesquisa) {
      return httpDelete(mount('tag-pesquisa', idfornecedor, idtagpesquisa))
    },
    deletaAgrupador: function(idfornecedor, idagrupadortagpesquisa) {
      return httpDelete(
        mount('tag-pesquisa', idfornecedor, 'agrupador', idagrupadortagpesquisa)
      )
    }
  }

  root.billing = {
    cost: function(idempresa, newDate) {
      return get(mount('billing/cost', idempresa, `?month=${newDate}`))
    },
    estimativaGasto: function(newDate) {
      return get(mount('billing-budget', `?month=${newDate}`))
    },
    atualizaEstimativa: function(idfornecedor, dados) {
      return put(
        mount('billing-budget', `?idfornecedor=${idfornecedor}`),
        dados
      )
    }
  }

  root.address = {
    getCoords: payload => post(`/get-address-coords`, payload),
    get: idendereco => get(`/address/${idendereco}`),
    del: (idfornecedor, idendereco) =>
      httpDelete(`/address/${idendereco}?idfornecedor=${idfornecedor}`),
    forceCreateAddress: (idfornecedor, idcliente) =>
      get(
        `/client/gerar-endereco-primario-await?idfornecedor=${idfornecedor}&idcliente=${idcliente}`
      )
  }

  root.staticText = {
    get: idsupplier => get(`/static-text/${idsupplier}?nocache=true`),
    getAll: idsupplier => get(`/static-text/${idsupplier}/all?nocache=true`),
    save: (idsupplier, texts) => post(`/static-text/${idsupplier}`, texts),
    restore: idsupplier => post(`/static-text/${idsupplier}/restore`)
  }
  root.payment = {
    refundManual: (supplier, payload) =>
      post(`/payment/refund-manual/${supplier}`, payload),
    getDetails: (supplier, numorder) =>
      get(`/payment/order/${supplier}/${numorder}`),
    getrefunds: payload => post(`/orders/refunds`, payload)
  }

  root.oauth = {
    callback: (idsupplier, service, params) =>
      post(`/oauth/${idsupplier}/callback/${service}`, params)
  }
  root.linkAcao = {
    create: (idsupplier, payload) => post(`/link-acao/${idsupplier}`, payload),
    update: (idsupplier, idlinkacao, payload) =>
      put(`/link-acao/${idsupplier}/${idlinkacao}`, payload),
    remve: (idsupplier, idlinkacao) =>
      httpDelete(`/link-acao/${idsupplier}/${idlinkacao}`),
    busca: (idsupplier, idlinkacao) =>
      get(`/link-acao/${idsupplier}/${idlinkacao}`)
  }
  root.usuario = {
    pesquisa: (idsupplier, filtro) =>
      get(`/usuario/${idsupplier}/pesquisa?q=${filtro}`)
  }

  root.eidcaoPlanilha = {
    importar: (idsupplier, form) => {
      const formData = new FormData()
      Object.keys(form).map(key => {
        if (form[key] != null) {
          formData.append(key, form[key])
        }
      })
      return post(`/edicao-planilha/${idsupplier}`, formData, {
        'Content-Type': 'multipart/form-data'
      })
    },
    exportar: (idfornecedor, modelo) =>
      get(`/edicao-planilha/${idfornecedor}/${modelo}`, {
        responseType: 'blob'
      }),
    configuracao: modelo => get(`/edicao-planilha/config/${modelo}`)
  }

  root.vendamais = {
    cliente: (idsupplier, filtro) =>
      get(`/client/list/vendamais?filtro=${filtro}&idfornecedor=${idsupplier}`),
    acesso: () => get('venda-mais/acesso')
  }

  root.chat = {
    getUrl: () => get('/chat/url')
  }

  return root
}
const headers = {
  'x-accept': 'application/json',
  'Access-Control-Allow-Origin': '*',
  authorization: Cookies.get('authorization') || '',
  authorization1: Cookies.get('authorization1') || '',
  authorization2: Cookies.get('authorization2') || '',
  authorization3: Cookies.get('authorization3') || '',
  ID_FORNECEDOR: Cookies.get('ID_FORNECEDOR') || ''
}

export default {
  v1: v1(),
  v2: v2(),
  v4: v4(),
  login: payload => {
    return api.post('/login', payload)
  },
  logout: () => {
    return api.get('/logout')
  },
  api,
  headers: useHeader ? headers : {},
  Axios,
  baseApiURL
}
