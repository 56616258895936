export default {
  bind: function(el, binding) {
    el.addEventListener('click', () => {
      let input = document.getElementById('clipboard-input')
      if (!input) {
        input = document.createElement('input')
        input.setAttribute('type', 'text')
        input.setAttribute('type', 'clipboard-input')
        input.style.top = '0'
        input.style.left = '0'
        input.style.position = 'fixed'
        document.body.appendChild(input)
      }
      input.value = binding.value
      input.select()
      document.execCommand('copy')
      window.getSelection().removeAllRanges()
    })
  }
}
