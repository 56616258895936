import { OAUTH_CALLBACK } from '../actions/oauth'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}
const actions = {
  [OAUTH_CALLBACK]: ({ dispatch, getters }, { service, params }) => {
    return new Promise((resolve, reject) => {
      Api.v4.oauth
        .callback(getters.getFornecedorId, service, params)
        .then(response => {
          resolve(response.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default { state, getters, actions, mutations }
