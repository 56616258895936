export const ASSINATURA_CREATE = 'ASSINATURA_CREATE'
export const ASSINATURA_REMOVE = 'ASSINATURA_REMOVE'
export const ASSINATURA_TYPE_REMOVE = 'ASSINATURA_TYPE_REMOVE'
export const ASSINATURA_LOAD = 'ASSINATURA_LOAD'
export const RECORRENCIA = {
  COMPRA_UNICA: {
    name: 'Sem periodicidade',
    value: 'COMPRA_UNICA'
  },
  DIARIO: {
    name: 'Diária',
    value: 'DIARIO'
  },
  SEMANAL: {
    name: 'Semanal',
    value: 'SEMANAL'
  },
  QUINZENAL: {
    name: 'Quinzenal',
    value: 'QUINZENAL'
  },
  MENSAL: {
    name: 'Mensal',
    value: 'MENSAL'
  }
}
