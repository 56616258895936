import {
  LISTA_COMPRAS_CREATE,
  LISTA_COMPRAS_LOAD,
  LISTA_COMPRAS_REMOVE
} from '../actions/listaCompras'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [LISTA_COMPRAS_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.listaCompras
        .adicionar(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [LISTA_COMPRAS_REMOVE]: ({ dispatch, getters }, id) => {
    return new Promise((resolve, reject) => {
      Api.v4.listaCompras
        .remove(getters.getFornecedorId, id)
        .then(() => {
          resolve()
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [LISTA_COMPRAS_LOAD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.listaCompras
        .obter(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {}
export default {
  state,
  getters,
  actions,
  mutations
}
