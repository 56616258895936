import {
  DIAGNOSTICO_LOAD,
  KPI_LOAD,
  RUPTURA_LOAD,
  RUPTURA_DETALHE,
  AVALIACAO_LOAD,
  TEMPO_MEDIO_LOAD,
  TICKET_MEDIO_LOAD
} from '../actions/diagnostico'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {
  active: null
}

const getters = {}

const actions = {
  [DIAGNOSTICO_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.diagnostico
        .obter(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [KPI_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v4.kpi
        .obter(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [RUPTURA_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.kpi.ruptura
        .indice(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [RUPTURA_DETALHE]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.kpi.ruptura
        .detalhes(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [AVALIACAO_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.kpi
        .avaliacaoMedia(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TEMPO_MEDIO_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.kpi.atendimento
        .media(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TICKET_MEDIO_LOAD]: ({ dispatch, getters }) => {
    return new Promise((resolve, reject) => {
      Api.v2.kpi.ticket
        .medio(getters.getFornecedorId)
        .then(resp => resolve(resp.data))
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}
const mutations = {}
export default {
  state,
  getters,
  actions,
  mutations
}
