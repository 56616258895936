const getIdFornecedor = (idempresa, idusuario) => {
  const key = `${idempresa}::${idusuario}::ID_FORNECEDOR`
  return localStorage.getItem(key)
}

const setIdFornecedor = (idempresa, idusuario, idfornecedor) => {
  return localStorage.setItem(
    `${idempresa}::${idusuario}::ID_FORNECEDOR`,
    idfornecedor
  )
}

const clear = (idempresa, idusuario) => {
  return localStorage.removeItem(`${idempresa}::${idusuario}::ID_FORNECEDOR`)
}

export default {
  getIdFornecedor,
  setIdFornecedor,
  clear
}
