import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './config/toast'
import './config/sweetalert'
import './config/vuelidate'
import './config/geocoder'
import './config/globalMixins'
import './config/globalDirectives'
import './config/globalFilters'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC6pVhPeT8AmD4IZoyHh7aWoHfSOq9eBIQ'
  },
  installComponents: true
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
