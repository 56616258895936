import {
  LOADING_HIDE,
  LOADING_SHOW,
  LOADING_CONTENT_SHOW,
  LOADING_CONTENT_HIDE
} from '../actions/loading'

const state = { loadingPage: false, loadingContent: false }

const getters = {
  isLoadingPage: state => state.loadingPage,
  isLoadingContent: state => state.loadingContent
}

const actions = {
  [LOADING_SHOW]: ({ commit }) => {
    commit(LOADING_SHOW)
  },
  [LOADING_HIDE]: ({ commit }) => {
    commit(LOADING_HIDE)
  },
  [LOADING_CONTENT_SHOW]: ({ commit }) => {
    commit(LOADING_CONTENT_SHOW)
  },
  [LOADING_CONTENT_HIDE]: ({ commit }) => {
    commit(LOADING_CONTENT_HIDE)
  }
}

const mutations = {
  [LOADING_SHOW]: state => {
    state.loadingPage = true
  },
  [LOADING_HIDE]: state => {
    state.loadingPage = false
  },
  [LOADING_CONTENT_SHOW]: state => {
    state.loadingContent = true
  },
  [LOADING_CONTENT_HIDE]: state => {
    state.loadingContent = false
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
