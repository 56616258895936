export const FORMA_ENTREGA_CREATE = 'FORMA_ENTREGA_CREATE'
export const FORMA_ENTREGA_UPDATE = 'FORMA_ENTREGA_UPDATE'
export const FORMA_ENTREGA_LOAD = 'FORMA_ENTREGA_LOAD'
export const FORMA_ENTREGA_REMOVE = 'FORMA_ENTREGA_REMOVE'
export const FORMA_ENTREGA_CALENDARIO_LIST = 'FORMA_ENTREGA_CALENDARIO_LIST'
export const FORMA_ENTREGA_FORNECEDOR = 'FORMA_ENTREGA_FORNECEDOR'
export const FORMA_ENTREGA_FORMAS_LIST = 'FORMA_ENTREGA_FORMAS_LIST'
export const FORMA_ENTREGA_SIMULAR = 'FORMA_ENTREGA_SIMULAR'
export const FORMA_ENTREGA_LIST = 'FORMA_ENTREGA_LIST'

export const FORMASENTREGAS = {
  DELIVERY: 'Delivery',
  RETIRADA: 'Retirada na loja',
  TRANSPORTE: 'Frete/Transportadora',
  CORREIOS: 'Correios',
  FRENET: 'Frenet',
  BASEADA_CLIENTE: 'Baseada em clientes'
}

export const OPCOESCORREIOS = [
  { value: '03050', text: '03050 - SEDEX Contrato' },
  { value: '04170', text: '04170 - SEDEX Reverso' },
  { value: '03077', text: '03077 - SEDEX Reverso' },
  { value: '04510', text: '04510 - PAC à vista' },
  { value: '03085', text: '03085 - PAC Contrato' },
  { value: '04553', text: '04553 - SEDEX' },
  { value: '03115', text: '03115 - PAC Reverso' },
  { value: '04596', text: '04596 - PAC' },
  { value: '03220', text: '03220 - SEDEX Contrato' },
  { value: '04669', text: '04669 - PAC Contrato' },
  { value: '03247', text: '03247 - SEDEX Reverso' },
  { value: '04677', text: '04677 - PAC Reverso' },
  { value: '03298', text: '03298 - PAC Contrato' },
  { value: '04707', text: '04707 - PAC à vista pagamento na entrega' },
  { value: '03301', text: '03301 - PAC Reverso' },
  { value: '40169', text: '40169 - SEDEX 12 ( à vista e a faturar)' },
  { value: '04014', text: '04014 - SEDEX à vista' },
  { value: '40215', text: '40215 - SEDEX 10 (à vista e a faturar)' },
  { value: '04162', text: '04162 - SEDEX Contrato' },
  { value: '40290', text: '40290 - SEDEX Hoje Varejo' }
]
