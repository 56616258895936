<template>
  <div class="page-full" v-show="loginVisible">
    <div class="image-login">
      <img src="@/assets/logo-lifeapps.png" alt="" />
    </div>
    <Card width="400px" shadown :loading="isLoading">
      <v-list-item-content>
        <form @submit.prevent="onSubmit">
          <FormField
            v-model="usuario"
            label="Usuário"
            placeholder="Usuário"
            :validation="$v.usuario"
          ></FormField>
          <FormPassword
            v-model="senha"
            type="password"
            placeholder="Senha"
            label="Senha"
            :validation="$v.senha"
          >
          </FormPassword>
          <FormButton
            submit
            type="submissao"
            full
            text="Entrar"
            @click="onSubmit"
          />
        </form>
      </v-list-item-content>
    </Card>
    <a
      :href="blogPosts.link"
      target="_blank"
      class="rss-footer"
      v-if="blogPosts"
    >
      <v-icon size="25" color="#558ED0">fas fa-rss-square</v-icon>
      <div style="margin-left:5px;text-align:justify;">
        <p class="title-rss">{{ blogPosts.title }}</p>
        <p>
          {{ blogPosts.description }}
        </p>
        <small style="float:right"
          >Blog lifeApps
          <v-icon size="10" style="margin-left:5px;"
            >fas fa-external-link-alt</v-icon
          ></small
        >
      </div>
    </a>
    <a
      href="https://lifeapps.com.br/politica-de-privacidade/"
      target="_blank"
      class="aux-link"
    >
      <p>
        Política de privacidade
        <small><v-icon size="10">fas fa-external-link-alt</v-icon></small>
      </p>
    </a>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { AUTH_LOGIN } from '../store/actions/auth'
import { mapGetters } from 'vuex'
import FormField from '@/components/form/FormField'
import FormPassword from '@/components/form/FormPassword'
import Card from '@/components/Card'
import FormButton from '@/components/form/FormButton'
import RSSParser from 'rss-parser'
import * as _ from 'lodash'

export default {
  name: 'Login',
  mixins: [validationMixin],
  components: { FormField, FormPassword, Card, FormButton },
  validations: {
    senha: { required },
    usuario: { required }
  },
  data: () => ({
    senha: '',
    usuario: '',
    isLoading: false,
    loginVisible: true,
    blogPosts: null
  }),
  computed: {
    ...mapGetters(['isAuthenticated']),
    usuarioErrors() {
      const errors = []
      if (!this.$v.usuario.$dirty) return errors
      !this.$v.usuario.required && errors.push('O usuário é obrigatório')
      return errors
    },
    senhaErrors() {
      const errors = []
      if (!this.$v.senha.$dirty) return errors
      !this.$v.senha.required && errors.push('A senha é obrigatória')
      return errors
    }
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.push('/')
    } else {
      this.loadFeed()
    }
  },
  methods: {
    async loadFeed() {
      try {
        const parser = new RSSParser()
        const feed = await parser.parseURL(
          'https://lifeapps.com.br/conteudo/blog/feed/'
        )
        const posts = feed.items || []
        const index = Math.floor(Math.random() * (posts.length - 1 + 1))
        const post = _.get(posts, index)
        this.blogPosts = {
          title: post.title,
          link: post.link,
          description:
            post.contentSnippet.length > 100
              ? post.contentSnippet.substr(0, 97) + '...'
              : post.contentSnippet
        }
      } catch (err) {
        // console.log(err)
      }
    },
    onSubmit() {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.isLoading = true
      this.$store
        .dispatch(AUTH_LOGIN, {
          username: this.usuario,
          password: this.senha
        })
        .then(resp => {
          this.loginVisible = false
          this.$vueOnToast.pop('success', 'Login efetuado com sucesso')
          if (resp.funcao == 'adm') {
            this.redirectUser('/admin')
          } else {
            this.redirectUser('/fornecedor/inicio')
          }
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    redirectUser(path) {
      setTimeout(() => {
        this.$router.push(path)
        this.isLoading = false
      }, 300)
    }
  }
}
</script>

<style lang="scss">
.page-full {
  min-height: calc(100vh - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .image-login {
    width: 250px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin: 10px;
    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  .rss-footer {
    width: 400px;
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    color: #558ed0;
    text-decoration: none;
    .title-rss {
      font-size: 15px;
      margin-bottom: 0px;
    }
    p {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }
  .aux-link {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 15px;
    text-decoration: none;
  }
}
</style>
