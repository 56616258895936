<template>
  <v-card
    class="front-header"
    width="100%"
    outlined
    v-if="hasHeader"
    :dark="darkEnabled"
  >
    <v-card-text class="text-header">
      <h3>
        <span v-if="altTitle">{{ altTitle }}</span>
        <span v-else>{{ title }}</span>
      </h3>
      <p>{{ subtitle }}</p>
      <p v-if="infoMessage" style="color:#3587d7;">{{ infoMessage }}</p>
      <a :href="help" target="_blank" class="help" v-if="help">Me ajuda</a>
    </v-card-text>
    <v-breadcrumbs :items="breadcrumb" class="breadcrumb"></v-breadcrumbs>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
export default {
  name: 'Header',
  data: () => ({
    breadcrumb: [],
    title: '',
    subtitle: '',
    infoMessage: '',
    help: '',
    hasHeader: false,
    linkDoc: ''
  }),
  computed: {
    ...mapGetters(['darkEnabled', 'getListaMenu', 'altTitle'])
  },
  watch: {
    $route: {
      handler() {
        this.loadMeta()
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.loadMeta()
  },
  methods: {
    getMeta(route) {
      this.hasHeader = _.get(route, 'meta.header', false)
      this.title = _.get(route, 'meta.header.title', '')
      this.subtitle = _.get(route, 'meta.header.subtitle', '')
      this.infoMessage = _.get(route, 'meta.header.infoMessage', '')
      this.breadcrumb = _.get(route, 'meta.header.breadcrumb', []).map(item => {
        return {
          text: item.text,
          disabled: false,
          href: item.route
        }
      })
      if (this.breadcrumb && this.breadcrumb.length > 0) {
        _.set(this.breadcrumb, `[${this.breadcrumb.length - 1}].disabled`, true)
      }
      const itemMenu = this.getListaMenu.find(item => {
        return item.link === _.get(route, 'path')
      })
      this.help = _.get(itemMenu, 'link_documentacao', '')
    },
    loadMeta() {
      let countMatched = _.get(this.$route, 'matched.length', 0)
      this.hasHeader = false
      while (countMatched > 0 && !this.hasHeader) {
        countMatched--
        this.getMeta(_.get(this.$route, `matched.[${countMatched}]`, {}))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.help {
  font-size: 12px;
  color: #337ab7;
  text-decoration: none;
}
.text-header {
  text-align: left;
  p {
    margin-bottom: 5px;
  }
  h3 {
    text-transform: uppercase;
  }
}
.breadcrumb {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}
</style>
