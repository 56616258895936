<template>
  <div class="menu-top">
    <v-toolbar height="64" :dark="darkEnabled">
      <v-app-bar-nav-icon class="hide-on-desktop" @click="changeMenuLeft">
        <v-icon size="20" color="rgba(0, 0, 0, 0.87)">fas fa-bars</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/fornecedor/inicio"
          ><img class="logo-menu" src="@/assets/logo-lifeapps.png"
        /></router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu bottom>
        <template v-slot:activator="{ on }">
          <v-btn class="btn-font-size menu-right" text v-on="on">
            <v-icon size="15">fas fa-university</v-icon>
            <span style="margin-left:5px;">{{ getFornecedorNome }}</span>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="fornecedor in getFornecedores"
            :key="fornecedor.idfornecedor"
            @click="alteraFornecedor(fornecedor.idfornecedor)"
          >
            <v-list-item-title>{{ fornecedor.nome }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-toolbar-items class="menu-right">
        <v-btn
          class="btn-font-size"
          text
          to="/fornecedor/configuracoes/usuarios/meu-usuario"
          ><v-icon size="15">far fa-user-circle</v-icon
          ><span style="margin-left:5px;">{{ getUsuarioNome }}</span></v-btn
        >
        <v-btn class="btn-font-size" text to="/fornecedor/novidades">
          <span class="icon-novidade">
            <v-icon size="15" v-if="getNovidadesContador > 0"
              >fas fa-bell</v-icon
            >
            <v-icon size="15" v-else>far fa-bell</v-icon>
            <span class="badge-novidade" v-if="getNovidadesContador > 0"></span>
          </span>
          <span style="margin-left:5px;">Novidades</span></v-btn
        >
        <v-btn class="btn-font-size" text @click="logout"
          ><v-icon size="15">fas fa-sign-out-alt</v-icon></v-btn
        >
      </v-toolbar-items>
    </v-toolbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { MENU_ALTERA_FORNECEDOR, MENU_TOGGLE } from '../store/actions/menu'
import { AUTH_LOGOUT } from '../store/actions/auth'
export default {
  name: 'NavBarTop',
  computed: {
    ...mapGetters([
      'getFornecedores',
      'getFornecedorNome',
      'getUsuarioNome',
      'getNovidadesContador',
      'darkEnabled'
    ])
  },
  methods: {
    alteraFornecedor(idfornecedor) {
      this.$store.dispatch(MENU_ALTERA_FORNECEDOR, idfornecedor)
      location.reload()
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT)
    },
    changeMenuLeft() {
      this.$store.dispatch(MENU_TOGGLE)
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-top {
  position: fixed;
  width: 100%;
  .logo-menu {
    max-width: 140px;
    max-height: 100%;
    width: auto;
    height: auto;
  }
  z-index: 800;
  .icon-novidade {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 5px;
  }
  .badge-novidade {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #d93534;
    position: absolute;
    top: 1px;
    right: -2px;
  }
  .fixed-heigth {
    height: 64px !important;
  }
}
.v-toolbar__content {
  height: 64px !important;
}
@media (min-width: 768px) {
  .hide-on-desktop {
    display: none;
  }
}

@media (max-width: 768px) {
  .menu-right {
    display: none;
  }
}
</style>
