export const IMAGEM_PRODUTO_UPLOAD = 'IMAGEM_PRODUTO_UPLOAD'
export const TIPO_IMPORTACAO = [
  { label: 'Código barras', value: 'CODIGO_BARRAS' },
  { label: 'Código embalagem', value: 'CODIGO_EMBALAGEM' },
  { label: 'Código interno', value: 'CODIGO_INTERNO' }
]
export const TYPES_ACCEPTED = [
  'application/zip',
  'application/rar',
  'application/7z',
  'application/tar',
  'application/gz',
  'application/x-7z-compressed',
  'application/x-xz',
  'application/x-zip-compressed',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/webp'
]
