import {
  PRODUTO_CREATE,
  PRODUTO_LOAD,
  PRODUTO_UPDATE,
  PRODUTO_LOAD_BY_COD_BARRAS,
  PRODUTO_REMOVE,
  PRODUTO_ASSOCIAR,
  PRODUTO_DESASSOCIAR,
  PRODUTO_DESASSOCIAR_TAGS,
  PRODUTOS_LOAD_BY_COD_INTERNO,
  PRODUTOS_LOAD_BY_IDS_EXTRA,
  PRODUTO_ASSOCIAR_TAGS,
  PRODUTO_LOAD_IMAGES,
  PRODUTO_REMOVE_IMAGE,
  OBTER_PRODUTOS,
  PRODUTO_REMOVE_SUBITEM
} from '../actions/produto'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'
import VueOnToast from 'vue-on-toast'

const state = {}

const getters = {}

const actions = {
  [PRODUTO_CREATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.produto
        .inserirProduto(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PRODUTO_UPDATE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.produto
        .atualizarProduto(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.dara)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PRODUTO_REMOVE]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.produto
        .removeProdutoExtra(getters.getFornecedorId, payload)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PRODUTO_DESASSOCIAR]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .desassociarSetoresProdutos(getters.getFornecedorId, payload)
        .then(resp => {
          if (resp.data && resp.data.ok) {
            resolve()
          } else {
            VueOnToast.ToastService.pop(
              'error',
              'Erro ao desassociar categorias'
            )
            reject()
          }
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTO_ASSOCIAR]: (
    { dispatch, getters },
    { setores, alvos, disponibilidade = null }
  ) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .aplicarSetoresProdutos(
          getters.getFornecedorId,
          setores,
          alvos,
          disponibilidade
        )
        .then(resp => {
          if (resp.data && resp.data.error) {
            VueOnToast.ToastService.pop('error', 'Erro ao aassociar categorias')
            reject(resp.data.error)
          } else {
            resolve(resp.data)
          }
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [PRODUTO_LOAD]: (
    { dispatch, getters },
    { idproduto, idcadastroextraproduto }
  ) => {
    return new Promise((resolve, reject) => {
      Api.v2.produto
        .findById(getters.getFornecedorId, idproduto, idcadastroextraproduto)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTO_LOAD_BY_COD_BARRAS]: ({ dispatch, getters }, { codigobarras }) => {
    return new Promise((resolve, reject) => {
      Api.v2.produto
        .findByCodBarras(getters.getFornecedorId, codigobarras)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTOS_LOAD_BY_COD_INTERNO]: ({ dispatch, getters }, { ids, page }) => {
    return new Promise((resolve, reject) => {
      // idfornecedor, ids, page
      Api.v4.produto
        .getProdutosByIdsErp(getters.getFornecedorId, ids, page)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTOS_LOAD_BY_IDS_EXTRA]: ({ dispatch, getters }, { ids, page }) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto
        .getProdutosByIdsExtra(getters.getFornecedorId, ids, page)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTO_DESASSOCIAR_TAGS]: ({ dispatch }, { idscadastroextra }) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto.tagPesquisa
        .desassocia({
          idscadastroextra
        })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTO_ASSOCIAR_TAGS]: (
    { dispatch },
    { idscadastroextra, idstagpesquisas, substituir }
  ) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto.tagPesquisa
        .associa({
          idscadastroextra,
          idstagpesquisas,
          substituir
        })
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTO_LOAD_IMAGES]: (
    { dispatch, getters },
    { idcadastroextraproduto }
  ) => {
    return new Promise((resolve, reject) => {
      Api.v2.produto
        .buscaImagens(getters.getFornecedorId, idcadastroextraproduto)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTO_REMOVE_IMAGE]: (
    { getters, dispatch },
    { idcadastroextraproduto, imagem }
  ) => {
    return new Promise((resolve, reject) => {
      Api.v2.produto
        .removeImagem(getters.getFornecedorId, idcadastroextraproduto, imagem)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [PRODUTO_REMOVE_SUBITEM]: ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.produto
        .removeSubItem(payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [OBTER_PRODUTOS]: ({ getters, dispatch }, search) => {
    return new Promise((resolve, reject) => {
      Api.v1.produto
        .obtemProdutos(getters.getFornecedorId, search)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
