<template>
  <div class="loading-page" :class="{ dark: darkEnabled }">
    <div class="img-loading">
      <img src="@/assets/img/loading_new.svg" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LoadingPage',
  computed: {
    ...mapGetters(['darkEnabled'])
  }
}
</script>

<style lang="scss" scoped>
.loading-page {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  &.dark {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }
  .img-loading img {
    max-width: 400px;
    max-height: 400px;
    width: auto;
    height: auto;
  }
}
</style>
