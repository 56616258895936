import {
  COMBO_PROMOCIONAL_SAVE_ITENS,
  COMBO_PROMOCIONAL_SAVE,
  COMBO_PROMOCIONAL_LOAD,
  COMBO_PROMOCIONAL_ITENS_LOAD,
  COMBO_PROMOCIONAL_REMOVE,
  COMBO_PROMOCIONAL_LOAD_IMAGES,
  COMBO_PROMOCIONAL_REMOVE_IMAGE
} from '../actions/comboPromocional'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}
const getters = {}

const actions = {
  [COMBO_PROMOCIONAL_SAVE]: ({ dispatch, getters }, payload) => {
    return new Promise(async (resolve, reject) => {
      try {
        const resp = await Api.v4.promotionalCombo.save(
          getters.getFornecedorId,
          getters.getEmpresaId,
          payload,
          'set'
        )
        resolve(resp.data)
      } catch (error) {
        dispatch(ERRORS_SHOW, error)
        reject(error)
      }
    })
  },
  [COMBO_PROMOCIONAL_SAVE_ITENS]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.promotionalCombo
        .save(
          getters.getFornecedorId,
          getters.getEmpresaId,
          payload,
          'increment'
        )
        .then(resp => {
          resolve(resp && resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [COMBO_PROMOCIONAL_REMOVE]: ({ dispatch, getters }, id) => {
    return new Promise((resolve, reject) => {
      Api.v4.promotionalCombo
        .remove(getters.getFornecedorId, getters.getEmpresaId, id)
        .then(resolve)
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [COMBO_PROMOCIONAL_LOAD]: ({ dispatch, getters }, id) => {
    return new Promise((resolve, reject) => {
      Api.v4.promotionalCombo
        .get(getters.getFornecedorId, getters.getEmpresaId, id)
        .then(resp => {
          resolve(resp && resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [COMBO_PROMOCIONAL_ITENS_LOAD]: ({ dispatch, getters }, id) => {
    return new Promise((resolve, reject) => {
      Api.v4.promotionalCombo
        .getItens(getters.getFornecedorId, getters.getEmpresaId, id)
        .then(resp => {
          resolve(resp && resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [COMBO_PROMOCIONAL_LOAD_IMAGES]: ({ dispatch, getters }, { id }) => {
    return new Promise((resolve, reject) => {
      Api.v4.promotionalCombo
        .buscaImagens(getters.getFornecedorId, id)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  },
  [COMBO_PROMOCIONAL_REMOVE_IMAGE]: ({ getters, dispatch }, { id, imagem }) => {
    return new Promise((resolve, reject) => {
      Api.v4.promotionalCombo
        .removeImagem(getters.getFornecedorId, id, imagem)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
          dispatch(ERRORS_SHOW, err)
        })
    })
  }
}
const mutations = {}
export default {
  state,
  getters,
  actions,
  mutations
}
