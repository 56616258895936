import Api from '../../service/api-web'
import fornecedorService from '../../service/fornecedorService'
import {
  MENU_TOGGLE,
  MENU_LOAD_ITEMS,
  MENU_ALTERA_FORNECEDOR,
  MENU_TOGGLE_DARK_MODE,
  MENU_POPUP,
  MENU_ALT_TITLE
} from '../actions/menu'
import { LOADING_SHOW, LOADING_HIDE } from '../actions/loading'
import { NOVIDADES_COUNT } from '../actions/novidades'
import AllTagsName from '../../utils/tags'
import router from '../../router'
import { ERRORS_SHOW } from '../actions/errors'
import * as _ from 'lodash'

const state = {
  isOpen: true,
  itemsMenu: [],
  listaMenu: [],
  fornecedores: [],
  fornecedor: null,
  empresa: null,
  idfornecedor: null,
  usuario: null,
  darkMode: false,
  popupOpen: false,
  popupHeight: 0,
  permissoes: null,
  loadingFornecedor: false,
  altTitle: ''
}

const getters = {
  menuIsOpen: state => state.isOpen,
  getArrayMenu: state => state.itemsMenu,
  getListaMenu: state => state.listaMenu,
  getFornecedores: state => state.fornecedores,
  getFornecedor: state => state.fornecedor,
  getFornecedorNome: state => (state.fornecedor ? state.fornecedor.nome : ''),
  getEmpresa: state => state.empresa,
  getEmpresaId: state => state.empresa && state.empresa.idempresa,
  getFornecedorId: state => state.idfornecedor,
  getFornecedorTags: state => (state.fornecedor ? state.fornecedor.tag : []),
  getFornecedorTag: state => {
    let tags = state.fornecedor ? state.fornecedor.tag : []
    return AllTagsName.reduce((obj, item) => {
      obj[item.tag] = tags.includes(item.tag)
      return obj
    }, {})
  },
  getUsuario: state => state.usuario,
  getUsuarioNome: state => (state.usuario ? state.usuario.nomeusuario : ''),
  getUsuarioId: state => (state.usuario ? state.usuario.idusuario : ''),
  darkEnabled: state => state.darkMode,
  altTitle: state => state.altTitle,
  popupIsOpen: state => state.popupOpen,
  getPopupHeight: state => state.popupHeight || 0,
  getPermissoes: state => state.permissoes || [],
  permissoesLoaded: state => !!state.permissoes,
  getTimeZone: state =>
    _.get(state.fornecedor, 'local_timezone', 'America/Sao_Paulo'),
  moduloFornecedorAtivo: state => (moduloVerificacao = []) => {
    const modulosFornecedor = _.get(state.fornecedor, 'modulos', [])

    if (
      modulosFornecedor &&
      modulosFornecedor.length > 0 &&
      moduloVerificacao.length > 0
    ) {
      if (moduloVerificacao[0] == 'Todos') {
        return true
      } else {
        return (modulosFornecedor || []).some(modulo =>
          moduloVerificacao.includes(modulo)
        )
      }
    } else {
      return undefined
    }
  },
  moduloFornecedorInativo: state => (moduloVerificacao = []) => {
    const modulosFornecedor = _.get(state.fornecedor, 'modulos', [])

    if (
      modulosFornecedor &&
      modulosFornecedor.length > 0 &&
      moduloVerificacao.length > 0
    ) {
      if (moduloVerificacao[0] == 'Todos') {
        return true
      } else {
        return !(modulosFornecedor || []).some(modulo =>
          moduloVerificacao.includes(modulo)
        )
      }
    } else {
      return undefined
    }
  }
}

const actions = {
  [MENU_TOGGLE]: ({ commit }) => {
    commit(MENU_TOGGLE)
  },
  [MENU_POPUP]: ({ commit }, { open, height }) => {
    commit(MENU_POPUP, { open, height })
  },
  [MENU_LOAD_ITEMS]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch(LOADING_SHOW)
      Api.v2.usuario
        .navegacao()
        .then(resp => {
          let funcaoUser = _.get(resp, 'data.usuario.funcao', 'for')
          if (funcaoUser && funcaoUser == 'adm') {
            return router.replace({ path: '/admin' })
          }
          commit(MENU_LOAD_ITEMS, resp.data)
          dispatch(NOVIDADES_COUNT)
          dispatch(LOADING_HIDE)
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          dispatch(LOADING_HIDE)
          reject(err)
        })
    })
  },
  [MENU_ALTERA_FORNECEDOR]: ({ commit }, payload) => {
    commit(MENU_ALTERA_FORNECEDOR, payload)
  },
  [MENU_TOGGLE_DARK_MODE]: ({ commit }) => {
    commit(MENU_TOGGLE_DARK_MODE)
  },
  [MENU_ALT_TITLE]: ({ commit }, payload) => {
    commit(MENU_ALT_TITLE, payload)
  }
}
const mutations = {
  [MENU_TOGGLE]: state => {
    state.isOpen = !state.isOpen
  },
  [MENU_LOAD_ITEMS]: (state, payload) => {
    let fornecedor = _.get(payload, 'fornecedores[0]', null)
    let fornecedorLogado = fornecedorService.getIdFornecedor(
      payload.empresa.idempresa,
      _.get(payload, 'usuario.idusuario', '')
    )
    if (fornecedorLogado) {
      let find = _.get(payload, 'fornecedores', []).find(
        item => item.idfornecedor == fornecedorLogado
      )
      fornecedor = find ? find : fornecedor
    }
    let permissoes = []
    state.listaMenu = _.cloneDeep(_.get(payload, 'itens_menu', []))
    let itemsMenu = _.get(payload, 'itens_menu', []).reduce((items, item) => {
      let hideDirectives = item.tag.filter(function(tag) {
        return tag.startsWith('hide-if')
          ? _.get(fornecedor, 'tag', []).includes(tag.replace('hide-if-', ''))
          : false
      })
      let showDirectives = item.tag.filter(function(tag) {
        return tag.startsWith('show-if')
          ? _.get(fornecedor, 'tag', []).includes(tag.replace('show-if-', ''))
          : false
      })
      if (!hideDirectives || showDirectives) {
        let find = items.find(i => i.grupo == item.grupo)
        if (!find) {
          items.push({
            ...item,
            subitens: [item]
          })
        } else {
          find.subitens.push(item)
        }
        permissoes.push(item.link)
      }
      return items
    }, [])
    state.fornecedores = payload.fornecedores || []
    state.fornecedor = fornecedor
    state.itemsMenu = itemsMenu
    state.usuario = payload.usuario || null
    state.idfornecedor = fornecedor.idfornecedor
    fornecedorService.setIdFornecedor(
      payload.empresa.idempresa,
      _.get(payload, 'usuario.idusuario', ''),
      fornecedor.idfornecedor
    )
    state.permissoes = permissoes
    state.empresa = payload.empresa
  },
  [MENU_ALTERA_FORNECEDOR]: (state, payload) => {
    let fornecedor = state.fornecedores.find(
      item => item.idfornecedor == payload
    )
    if (fornecedor) {
      state.fornecedor = fornecedor
      state.idfornecedor = fornecedor.idfornecedor
      fornecedorService.setIdFornecedor(
        state.empresa.idempresa,
        state.usuario.idusuario,
        fornecedor.idfornecedor
      )
    }
  },
  [MENU_TOGGLE_DARK_MODE]: state => {
    state.darkMode = !state.darkMode
  },
  [MENU_POPUP]: (state, { open, height }) => {
    state.popupOpen = open
    state.popupHeight = height || 0
  },
  [MENU_ALT_TITLE]: (state, payload) => {
    state.altTitle = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
