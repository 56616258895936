import {
  SETOR_FOLHA_LOAD,
  SETOR_RAIZ_LOAD,
  SETROR_FILHO_LOAD,
  TODOS_SETORES
} from '../actions/setor'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = { listFolhas: [] }

const getters = {
  getSetoresFolhas: state => state.listFolhas || []
}

const actions = {
  [SETOR_FOLHA_LOAD]: ({ dispatch, commit, getters }, payload = null) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .setoresFolha(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
          commit(SETOR_FOLHA_LOAD, resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [SETOR_RAIZ_LOAD]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .setoresRaiz(getters.getFornecedorId, payload)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [SETROR_FILHO_LOAD]: ({ dispatch }, { idsegmento, idsetor }) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .filhos(idsegmento, idsetor)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  },
  [TODOS_SETORES]: ({ dispatch, getters }, idsegmento) => {
    return new Promise((resolve, reject) => {
      Api.v2.setoresProduto
        .setoresTodos(getters.getFornecedorId, idsegmento)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {
  [SETOR_FOLHA_LOAD]: (state, payload) => {
    state.listFolhas = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
