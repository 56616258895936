import { ADMIN_GET_PASSOS_IMPLANTACAO } from '../actions/admin'
import { ERRORS_SHOW } from '../actions/errors'
import Api from '../../service/api-web'

const state = {}

const getters = {}

const actions = {
  [ADMIN_GET_PASSOS_IMPLANTACAO]: ({ dispatch, getters }, payload) => {
    return new Promise((resolve, reject) => {
      Api.v4.admin
        .getPassosImplantacao(
          getters.getFornecedorId,
          payload.execute,
          payload.step
        )
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          dispatch(ERRORS_SHOW, err)
          reject(err)
        })
    })
  }
}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
